import { App } from 'antd';
import { deleteConstraintTable } from 'entities/metadata/constraintTables';
import { useTransactions } from 'entities/transactions';
import { useDeleteRestrictionTableMutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useDeleteConstraintTable = () => {
	const [fetchDeleteConstraintTable, { isLoading: isDeleteLoading }] =
		useDeleteRestrictionTableMutation();

	const { getTransactions } = useTransactions();

	const { notification } = App.useApp();

	const dispatch = useAppDispatch();

	const handleDeleteConstraintTable = async (tableId: string) => {
		fetchDeleteConstraintTable({
			body: [{ id: tableId }],
		})
			.unwrap()
			.then(() => {
				getTransactions();
				dispatch(deleteConstraintTable({ id: tableId }));
			})
			.catch((err) =>
				errorHelper('Ошибка удаления ограничительной таблицы', err, notification)
			);
	};

	return {
		isLoading: isDeleteLoading,
		handleDeleteConstraintTable,
	};
};
