import { App } from 'antd';
import { useNavigate, useParams } from 'react-router';
import {
	addNewClassifierItem,
	updateClassifierChildTreeItem,
} from 'entities/classifiers/classifiersItems/classifierItems.store';
import { addNewClassifierTreeItem } from 'entities/classifiers/classifiersItemsTree/classifiersItemsTree.store';
import { ClassifierItemDto } from 'shared/api/generatedApi/newUdmiApi';
import { useAddCreateEvent3Mutation } from 'shared/api/generatedApi/transactionServiceApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useAddClassifierRecord = () => {
	const [fetchCreateClassifierRecord] = useAddCreateEvent3Mutation();

	const { notification } = App.useApp();

	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const { classifierGroupId } = useParams();

	const createClassifierRecord = (parentItemId?: string) => {
		fetchCreateClassifierRecord({
			body: [
				{
					classifierId: classifierGroupId,
					values: {},
					status: 'NON_NORMALIZED',
					...(parentItemId && { parentItemId }),
				},
			],
		})
			.unwrap()
			.then((res) => {
				if (res.persistedEvents.length > 0) {
					const newRecordId = res.persistedEvents[0].entityId;
					if (parentItemId) {
						dispatch(
							updateClassifierChildTreeItem({
								item: res.persistedEvents[0].data as unknown as ClassifierItemDto,
								parentItemId,
							})
						);
					} else {
						dispatch(
							addNewClassifierItem(
								res.persistedEvents[0].data as unknown as ClassifierItemDto
							)
						);
						dispatch(
							addNewClassifierTreeItem(
								res.persistedEvents[0].data as unknown as ClassifierItemDto
							)
						);
					}

					navigate(
						`/${routes.classifiers.main}/${classifierGroupId}/${routes.classifiers.records}/${newRecordId}/${routes.classifiers.edit}`
					);
				}
			})
			.catch((err) => {
				errorHelper(
					'Ошибка при добавлении новой записи в классификаторе!',
					err,
					notification
				);
			});
	};

	return {
		createClassifierRecord,
	};
};
