import { App } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router';
import {
	addNewCatalogGroup,
	updateCatalogGroupChildTreeItem,
} from 'entities/catalogs/catalogGroups/catalog.store';
import { useTransactions } from 'entities/transactions';
import { CatalogDto } from 'shared/api/generatedApi/newUdmiApi';
import { useAddCreateCatalogGroupEventMutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useAddNewCatalog = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const { notification } = App.useApp();
	const [fetchAddnewCatalog, { error }] = useAddCreateCatalogGroupEventMutation();
	const { getTransactions } = useTransactions();
	const dispatch = useAppDispatch();
	const { catalogGroupId } = useParams();

	const addNewCatalog = async (displayName: string, description?: string, parentId?: string) => {
		const parId = parentId ? parentId : catalogGroupId ? catalogGroupId : null;

		fetchAddnewCatalog({
			body: [
				{
					displayName,
					...(description && { description }),
					...(parId && { parentId: parId }),
				},
			],
		})
			.unwrap()
			.then((data) => {
				getTransactions();

				const newData = {
					...(data.persistedEvents[0].data as unknown as CatalogDto),
				};

				if (!parId) {
					dispatch(addNewCatalogGroup(newData));
				} else {
					dispatch(updateCatalogGroupChildTreeItem({ item: newData, parentId: parId }));
				}
			})
			.catch((err) => {
				errorHelper('Ошибка при добавлении новой группы атрибутов!', err, notification);
			});

		setLoading(false);
	};
	return { loading, error, addNewCatalog };
};
