import { RiMore2Line } from '@remixicon/react';
import { Card, Dropdown, Flex } from 'antd';
import { MenuProps } from 'antd/lib';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Hint } from '../Hint';
import { cardGridFirstStyles, cardGridLastItem, cardGridMiddleStyles } from './detailCard.styles';

interface IDetailCardProps {
	firstSection?: ReactNode;
	secondSection: ReactNode;
	dropdownButtons: MenuProps['items'];
	link?: string;
	tooltip?: string;
}

const DetailCardUi: React.FC<IDetailCardProps> = ({
	firstSection,
	secondSection,
	dropdownButtons,
	link,
	tooltip,
}) => {
	return (
		<Hint title={tooltip ? tooltip : ''}>
			<Card hoverable style={{ width: '325px', height: 'fit-content' }}>
				<Flex>
					{firstSection && (
						<Card.Grid hoverable={false} style={cardGridFirstStyles}>
							{link ? <Link to={link}>{firstSection}</Link> : <>{firstSection}</>}
						</Card.Grid>
					)}

					<Card.Grid hoverable={false} style={cardGridMiddleStyles}>
						{link ? <Link to={link}>{secondSection}</Link> : <>{secondSection}</>}
					</Card.Grid>
					<Card.Grid hoverable={false} style={cardGridLastItem}>
						<Dropdown
							trigger={['click']}
							menu={{
								items: dropdownButtons,
							}}
						>
							<RiMore2Line size={16} style={{ cursor: 'pointer' }} />
						</Dropdown>
					</Card.Grid>
				</Flex>
			</Card>
		</Hint>
	);
};

export const DetailCard = React.memo(DetailCardUi);
