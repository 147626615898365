import { integrationsApi as api } from './newCreateApiFile';
const injectedRtkApi = api.injectEndpoints({
	endpoints: (build) => ({
		listConnectionsMdmIntegrationsV1ConnectionsGet: build.query<
			ListConnectionsMdmIntegrationsV1ConnectionsGetApiResponse,
			ListConnectionsMdmIntegrationsV1ConnectionsGetApiArg
		>({
			query: (queryArg) => ({
				url: '/mdm-integrations/v1/connections',
				body: queryArg.listConnectionsRequest,
			}),
		}),
		createConnectionMdmIntegrationsV1ConnectionsPost: build.mutation<
			CreateConnectionMdmIntegrationsV1ConnectionsPostApiResponse,
			CreateConnectionMdmIntegrationsV1ConnectionsPostApiArg
		>({
			query: (queryArg) => ({
				url: '/mdm-integrations/v1/connections',
				method: 'POST',
				body: queryArg.connectionCreate,
			}),
		}),
		getConnectionMdmIntegrationsV1ConnectionsConnectionIdGet: build.query<
			GetConnectionMdmIntegrationsV1ConnectionsConnectionIdGetApiResponse,
			GetConnectionMdmIntegrationsV1ConnectionsConnectionIdGetApiArg
		>({
			query: (queryArg) => ({
				url: `/mdm-integrations/v1/connections/${queryArg.connectionId}`,
			}),
		}),
		deleteConnectionMdmIntegrationsV1ConnectionsConnectionIdDelete: build.mutation<
			DeleteConnectionMdmIntegrationsV1ConnectionsConnectionIdDeleteApiResponse,
			DeleteConnectionMdmIntegrationsV1ConnectionsConnectionIdDeleteApiArg
		>({
			query: (queryArg) => ({
				url: `/mdm-integrations/v1/connections/${queryArg.connectionId}`,
				method: 'DELETE',
			}),
		}),
		testConnectionMdmIntegrationsV1ConnectionsTestConnectionPost: build.mutation<
			TestConnectionMdmIntegrationsV1ConnectionsTestConnectionPostApiResponse,
			TestConnectionMdmIntegrationsV1ConnectionsTestConnectionPostApiArg
		>({
			query: (queryArg) => ({
				url: '/mdm-integrations/v1/connections/test-connection',
				method: 'POST',
				body: queryArg.connectionTest,
			}),
		}),
		getSupportedSystemsMdmIntegrationsV1ConnectionsSystemsSupportedSystemsGet: build.query<
			GetSupportedSystemsMdmIntegrationsV1ConnectionsSystemsSupportedSystemsGetApiResponse,
			GetSupportedSystemsMdmIntegrationsV1ConnectionsSystemsSupportedSystemsGetApiArg
		>({
			query: (queryArg) => ({
				url: '/mdm-integrations/v1/connections/systems/supported-systems',
				params: { page: queryArg.page, page_size: queryArg.pageSize },
			}),
		}),
		getConfigurationsActivationModeMdmIntegrationsV1ConnectionsConnectionIdConfigurationsActivationModeGet:
			build.query<
				GetConfigurationsActivationModeMdmIntegrationsV1ConnectionsConnectionIdConfigurationsActivationModeGetApiResponse,
				GetConfigurationsActivationModeMdmIntegrationsV1ConnectionsConnectionIdConfigurationsActivationModeGetApiArg
			>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations/activation_mode`,
				}),
			}),
		patchConnectionMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdPatch:
			build.mutation<
				PatchConnectionMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdPatchApiResponse,
				PatchConnectionMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdPatchApiArg
			>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations/${queryArg.configurationId}`,
					method: 'PATCH',
					body: queryArg.exchangeConfigurationInput,
				}),
			}),
		getConnectionByIdMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdGet:
			build.query<
				GetConnectionByIdMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdGetApiResponse,
				GetConnectionByIdMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdGetApiArg
			>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations/${queryArg.configurationId}`,
				}),
			}),
		postConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsPost:
			build.mutation<
				PostConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsPostApiResponse,
				PostConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsPostApiArg
			>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations`,
					method: 'POST',
					body: queryArg.exchangeConfigurationInput,
				}),
			}),
		getConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsGet: build.query<
			GetConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsGetApiResponse,
			GetConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsGetApiArg
		>({
			query: (queryArg) => ({
				url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations`,
			}),
		}),
		getConfigurationsStatusMdmIntegrationsV1ConnectionsConnectionIdConfigurationsStatusGet:
			build.query<
				GetConfigurationsStatusMdmIntegrationsV1ConnectionsConnectionIdConfigurationsStatusGetApiResponse,
				GetConfigurationsStatusMdmIntegrationsV1ConnectionsConnectionIdConfigurationsStatusGetApiArg
			>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations_status`,
				}),
			}),
	}),
	overrideExisting: false,
});
export { injectedRtkApi as integrationsApi };
export type ListConnectionsMdmIntegrationsV1ConnectionsGetApiResponse =
	/** status 200 Successful Response */ ListConnectionsResponse;
export type ListConnectionsMdmIntegrationsV1ConnectionsGetApiArg = {
	listConnectionsRequest: ListConnectionsRequest;
};
export type CreateConnectionMdmIntegrationsV1ConnectionsPostApiResponse =
	/** status 200 Successful Response */ ConnectionCreateResponse;
export type CreateConnectionMdmIntegrationsV1ConnectionsPostApiArg = {
	connectionCreate: ConnectionCreate;
};
export type GetConnectionMdmIntegrationsV1ConnectionsConnectionIdGetApiResponse =
	/** status 200 Successful Response */ GetConnectionResponse;
export type GetConnectionMdmIntegrationsV1ConnectionsConnectionIdGetApiArg = {
	connectionId: string;
};
export type DeleteConnectionMdmIntegrationsV1ConnectionsConnectionIdDeleteApiResponse =
	/** status 204 Successful Response */ void;
export type DeleteConnectionMdmIntegrationsV1ConnectionsConnectionIdDeleteApiArg = {
	connectionId: string;
};
export type TestConnectionMdmIntegrationsV1ConnectionsTestConnectionPostApiResponse =
	/** status 200 Successful Response */ ConnectionTestResponse;
export type TestConnectionMdmIntegrationsV1ConnectionsTestConnectionPostApiArg = {
	connectionTest: ConnectionTest;
};
export type GetSupportedSystemsMdmIntegrationsV1ConnectionsSystemsSupportedSystemsGetApiResponse =
	/** status 200 Successful Response */ SupportedSystemsResponse;
export type GetSupportedSystemsMdmIntegrationsV1ConnectionsSystemsSupportedSystemsGetApiArg = {
	page?: number;
	pageSize?: number;
};
export type GetConfigurationsActivationModeMdmIntegrationsV1ConnectionsConnectionIdConfigurationsActivationModeGetApiResponse =
	/** status 200 Successful Response */ ActivationMode[];
export type GetConfigurationsActivationModeMdmIntegrationsV1ConnectionsConnectionIdConfigurationsActivationModeGetApiArg =
	{
		/** The ID of the connection */
		connectionId: string;
	};
export type PatchConnectionMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdPatchApiResponse =
	/** status 200 Successful Response */ ExchangeConfiguration;
export type PatchConnectionMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdPatchApiArg =
	{
		connectionId: string;
		configurationId: string;
		exchangeConfigurationInput: ExchangeConfiguration2;
	};
export type GetConnectionByIdMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdGetApiResponse =
	/** status 200 Successful Response */ ConfigurationGetResponse;
export type GetConnectionByIdMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdGetApiArg =
	{
		connectionId: string;
		configurationId: string;
	};
export type PostConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsPostApiResponse =
	/** status 200 Successful Response */ ConfigurationCreateResponse;
export type PostConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsPostApiArg = {
	connectionId: string;
	exchangeConfigurationInput: ExchangeConfiguration2;
};
export type GetConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsGetApiResponse =
	/** status 200 Successful Response */ ConfigurationList;
export type GetConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsGetApiArg = {
	connectionId: string;
};
export type GetConfigurationsStatusMdmIntegrationsV1ConnectionsConnectionIdConfigurationsStatusGetApiResponse =
	/** status 200 Successful Response */ string[];
export type GetConfigurationsStatusMdmIntegrationsV1ConnectionsConnectionIdConfigurationsStatusGetApiArg =
	{
		connectionId: string;
	};
export type ConnectionInfo = {
	name: string;
	date_last_import?: string | null;
	count_configuration: number;
	count_successful_imports: number;
	count_failed_imports: number;
	is_pinned?: boolean;
	is_failed?: boolean;
};
export type Integration = {
	connection_id: string;
	source_type: string;
	connection_info: ConnectionInfo;
};
export type PaginationInfo = {
	total: number;
	page_size: number;
	current_page: number;
};
export type ListConnectionsResponse = {
	data: Integration[];
	pagination: PaginationInfo;
};
export type ValidationError = {
	loc: (string | number)[];
	msg: string;
	type: string;
};
export type HttpValidationError = {
	detail?: ValidationError[];
};
export type ListConnectionsRequest = {
	sort_by?: string[] | null;
	filters?: object | null;
	reverse?: boolean | null;
	page?: number | null;
	page_size?: number | null;
};
export type ConnectionCreateResponse = {
	connection_id: string;
};
export type ConnectionCreate = {
	/** Field contains id of certain system type */
	system_id: string;
	/** Human-readable name for connection */
	connection_name: string;
	/** Contains params for certain connection */
	connection_params: object;
};
export type ConnectionProperty = {
	return_parameter: string;
	title: string | null;
	type: string;
	mandatory: boolean;
	current_value: string | null;
};
export type GetConnectionResponse = {
	connection_properties: ConnectionProperty[];
	connection_info: ConnectionInfo;
};
export type ConnectionTestResponse = {
	status?: boolean;
	message?: string;
};
export type ConnectionTest = {
	connection_params: object;
};
export type ConnectionParameter = {
	return_parametr: string;
	title: string;
	type: string;
	mandatory: boolean;
};
export type SupportedSystem = {
	system_id: string;
	system_name: string;
	connection_parameters: ConnectionParameter[];
};
export type SupportedSystemsResponse = {
	data: SupportedSystem[];
	pagination: PaginationInfo;
};
export type ExecutionParam = {
	return_parametr: string;
	type: string;
	mandatory: boolean;
};
export type ActivationMode = {
	name: string;
	value: string;
	extra: ExecutionParam[] | null;
};
export type Schedule = {
	weekday: string;
	import_frequency?: number | null;
};
export type MatchingField = {
	name_guid_column: string;
	name_master_column: string;
};
export type ConfigurationWithImportChangeData = {
	name_configuration: string;
	description?: string | null;
	id_action_transition_key?: string | null;
	import_schedule?: Schedule | null;
	name_data_source: string;
	guid_id: string;
	matching_fields: MatchingField[];
	import_change_data?: boolean | null;
};
export type ExchangeConfiguration = {
	exchange_configuration: ConfigurationWithImportChangeData;
};
export type ExchangeConfiguration2 = {
	exchange_configuration: ConfigurationWithImportChangeData;
};
export type GetConfigurationSchedule = {
	weekday: string;
	import_frequency: number;
};
export type GetConfigurationMatchingField = {
	name_guid_column: string;
	name_master_column: string;
	flag_transaction_key?: boolean | null;
};
export type ExchangeConfigurationAllFieldsRequired = {
	name_configuration: string;
	description: string;
	id_action_transition_key: string;
	import_schedule: GetConfigurationSchedule;
	name_data_source: string;
	guid_id: string;
	matching_fields: GetConfigurationMatchingField;
};
export type GetConfigurationConfigurationListObject = {
	configuration_id: string;
	exchange_configuration: ExchangeConfigurationAllFieldsRequired;
};
export type ConfigurationGetResponse = {
	configuration_list: GetConfigurationConfigurationListObject[];
};
export type ConfigurationCreateResponse = {
	connection_id: string;
};
export type ConfigurationListObject = {
	configuration_id: string;
	configuration_name: string;
	date_last_import: string;
	date_next_import: string;
	status: string;
};
export type ConfigurationList = {
	configuration_list: ConfigurationListObject[];
};
export const {
	useListConnectionsMdmIntegrationsV1ConnectionsGetQuery,
	useLazyListConnectionsMdmIntegrationsV1ConnectionsGetQuery,
	useCreateConnectionMdmIntegrationsV1ConnectionsPostMutation,
	useGetConnectionMdmIntegrationsV1ConnectionsConnectionIdGetQuery,
	useLazyGetConnectionMdmIntegrationsV1ConnectionsConnectionIdGetQuery,
	useDeleteConnectionMdmIntegrationsV1ConnectionsConnectionIdDeleteMutation,
	useTestConnectionMdmIntegrationsV1ConnectionsTestConnectionPostMutation,
	useGetSupportedSystemsMdmIntegrationsV1ConnectionsSystemsSupportedSystemsGetQuery,
	useLazyGetSupportedSystemsMdmIntegrationsV1ConnectionsSystemsSupportedSystemsGetQuery,
	useGetConfigurationsActivationModeMdmIntegrationsV1ConnectionsConnectionIdConfigurationsActivationModeGetQuery,
	useLazyGetConfigurationsActivationModeMdmIntegrationsV1ConnectionsConnectionIdConfigurationsActivationModeGetQuery,
	usePatchConnectionMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdPatchMutation,
	useGetConnectionByIdMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdGetQuery,
	useLazyGetConnectionByIdMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdGetQuery,
	usePostConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsPostMutation,
	useGetConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsGetQuery,
	useLazyGetConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsGetQuery,
	useGetConfigurationsStatusMdmIntegrationsV1ConnectionsConnectionIdConfigurationsStatusGetQuery,
	useLazyGetConfigurationsStatusMdmIntegrationsV1ConnectionsConnectionIdConfigurationsStatusGetQuery,
} = injectedRtkApi;
