import { App } from 'antd';
import { useNavigate } from 'react-router';
import { setConstraintTableInfo } from 'entities/metadata/constraintTables';
import { useTransactions } from 'entities/transactions';
import { RestrictionTableDto } from 'shared/api/generatedApi/newUdmiApi';
import { useUpdateRestrictionTableMutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { Values } from 'shared/helpers/types';
import { useAppDispatch, useAppSelector } from 'shared/hooks';

export const useAddNewRow = () => {
	const [patchConstraintTable] = useUpdateRestrictionTableMutation();

	const { table, columns } = useAppSelector(
		(state) => state.entities.metadata.constraintTableDetails
	);

	const { notification } = App.useApp();

	const { getTransactions } = useTransactions();

	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const addNewRow = async () => {
		const newAttributeValue: Values = {};
		columns?.forEach((col) => {
			newAttributeValue[col.id] = {
				attributeName: col.displayName,
				value: null,
			};
		});
		patchConstraintTable({
			body: [
				{
					...table,
					attributeValues: [...table.attributeValues, newAttributeValue],
				},
			],
		})
			.unwrap()
			.then((res) => {
				if (res.persistedEvents.length > 0) {
					getTransactions();
					dispatch(
						setConstraintTableInfo(
							res.persistedEvents[0].data as unknown as RestrictionTableDto
						)
					);
					const newAttributeValues = res.persistedEvents[0].data
						.attributeValues as Values[];
					const newRowId = newAttributeValues.find(
						(newRow) => !table.attributeValues.find((oldRow) => oldRow.id === newRow.id)
					).id;
					navigate(`${newRowId}`);
				}
			})
			.catch((err) =>
				errorHelper('Ошибка создания строки ограничительной таблицы!', err, notification)
			);
	};

	return {
		addNewRow,
	};
};
