import { App } from 'antd';
import { useState } from 'react';
import { useCommitTransactionMutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';

export const useCommitTransaction = () => {
	const [commitTransactionLoading, setCommitTransactionLoading] = useState<boolean>(false);
	const [fetchCommitTransactin] = useCommitTransactionMutation();
	const { notification } = App.useApp();

	const commitTransaction = async () => {

		setCommitTransactionLoading(true);

		const result = await fetchCommitTransactin({});

		if ('error' in result)
			errorHelper('Ошибка публикации изменений!', result.error, notification);

		if ('data' in result) notification.success({ message: 'Изменения опубликованы' });

		setCommitTransactionLoading(false);
	};

	return { commitTransaction, commitTransactionLoading };
};
