import { App } from 'antd';
import { useParams } from 'react-router';
import { addCatalogRecord } from 'entities/catalogs/catalogRecords/catalogRecords.store';
import { ItemDto } from 'shared/api/generatedApi/newUdmiApi';
import { useAddItemImportEventMutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useImportCatalogRecord = () => {
	const { notification } = App.useApp();

	const [importRecord, { isLoading }] = useAddItemImportEventMutation();
	const { catalogGroupId } = useParams();
	const dispatch = useAppDispatch();

	const handleImport = async (base64: string): Promise<boolean> => {
		const data = await importRecord({
			id: catalogGroupId,
			importItemRequest: { content: base64 },
		});

		if ('error' in data) {
			errorHelper('Ошибка при импорте записи!', data.error, notification);
			return false;
		}

		if ('data' in data) {
			if (data.data.blockedEvents.length) {
				for (const err of data.data.blockedEvents) {
					if ('notification' in err) {
						errorHelper(
							'Ошибка при импорте записей!',
							new Error(err.notification),
							notification
						);
					}
				}
				return false;
			}
			const result = data.data.persistedEvents.map((item) => item.data);
			for (const i of result) dispatch(addCatalogRecord(i as unknown as ItemDto));
			return true;
		}
	};

	return {
		handleImport,
		isLoading,
	};
};
