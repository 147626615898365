import { RiMore2Line } from '@remixicon/react';
import { Dropdown } from 'antd';
import React from 'react';

interface IAppDropdownProps {
	items: {
		label: JSX.Element;
		key: string;
	}[];
	placement?:
		| 'bottomRight'
		| 'topLeft'
		| 'topCenter'
		| 'topRight'
		| 'bottomLeft'
		| 'bottomCenter'
		| 'top'
		| 'bottom';
}

const AppDropdown = ({ items, placement }: IAppDropdownProps) => {
	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<Dropdown
				trigger={['click']}
				menu={{
					items,
				}}
				placement={placement}
			>
				<RiMore2Line
					size={16}
					style={{ cursor: 'pointer', padding: '2px' }}
					onClick={(e) => {
						e.stopPropagation();
					}}
				/>
			</Dropdown>
		</div>
	);
};

export default AppDropdown;
