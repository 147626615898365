import { RiDeleteBin6Line } from '@remixicon/react';
import React from 'react';
import { DropdownLink } from 'shared/ui';
import { useDeleteDeduplicationOption } from './DeleteDeduplicationOption.model';

interface IDeleteDeduplicationOption {
	id: string;
}

export const DeleteDeduplicationOption = ({ id }: IDeleteDeduplicationOption) => {
	const { deleteDeduplicationOption } = useDeleteDeduplicationOption();

	return (
		<DropdownLink
			title="Удалить"
			icon={<RiDeleteBin6Line size={16} />}
			callback={() => deleteDeduplicationOption(id)}
		/>
	);
};
