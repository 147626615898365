import { Result } from 'antd';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'shared/styles/index.scss';
import AdminPage from 'pages/AdminPage';
import { CatalogGroupPage } from 'pages/CatalogPage/CatalogGroupPage';
import { DuplicatesPage } from 'pages/CatalogPage/DuplicatesPage';
import { CatalogPage } from 'pages/CatalogPage/MainPage';
import { RecordDetailPage } from 'pages/CatalogPage/RecordDetailPage';
import { ClassifierGroupPage } from 'pages/ClassifierPage/ClassifierGroupPage';
import { ClassifierListPage } from 'pages/ClassifierPage/ClassifierListPage';
import { ClassifierPage } from 'pages/ClassifierPage/MainPage';
import { ClassifierRecordDetailPage } from 'pages/ClassifierPage/RecordDetailPage';
import { CreateNewIntegrationPage } from 'pages/IntegrationsPages/CreateNewIntegrationUi';
import { IntegrationsPage } from 'pages/IntegrationsPages/MainPage';
import { AttributeDetailPage } from 'pages/MetadataPage/AttributeDetailPage';
import { ConstraintTableCardPage } from 'pages/MetadataPage/ConstraintTableCardPage';
import { ConstraintTableRowPage } from 'pages/MetadataPage/ConstraintTableRowPage';
import { DetailMeasureGroupPage } from 'pages/MetadataPage/DetailMeasureGroupPage';
import { DetailMeasureUnitPage } from 'pages/MetadataPage/DetailMeasureUnitPage';
import { MetadataPage } from 'pages/MetadataPage/MainPage';
import SettingPage from 'pages/SettingsPage';
import { routes } from 'shared/config/navigation/routes';
import PageLayout from '../layouts/PageLayout/PageLayout';

export const AppRouter = () => (
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<PageLayout />}>
				<Route index element={<Navigate to={`${routes.metadata.main}`} />} />
				<Route
					path={routes.main.main}
					element={<Navigate to={`${routes.metadata.main}`} />}
				/>
				<Route path={routes.catalogs.main} element={<CatalogPage />} />
				<Route path={`${routes.catalogs.main}/:catalogGroupId`} element={<CatalogPage />} />
				<Route
					path={`${routes.catalogs.main}/:catalogGroupId/edit`}
					element={<CatalogGroupPage />}
				/>
				<Route
					path={`${routes.catalogs.main}/:catalogGroupId/record/:recordId`}
					element={<RecordDetailPage />}
				/>
				<Route
					path={`${routes.catalogs.main}/:catalogGroupId/${routes.catalogs.deduplication}/:deduplicationOptionId`}
					element={<DuplicatesPage />}
				/>
				<Route path={routes.metadata.main} element={<MetadataPage />} />
				<Route
					path={`${routes.metadata.main}/${routes.metadata.group}`}
					element={<MetadataPage />}
				/>
				<Route
					path={`${routes.metadata.main}/${routes.metadata.group}/:metaAttributeGroupId`}
					element={<MetadataPage />}
				/>
				<Route
					path={`${routes.metadata.main}/${routes.metadata.group}/:metaAttributeGroupId/${routes.metadata.attribute}/:metaAttributeId`}
					element={<AttributeDetailPage />}
				/>
				<Route path={`${routes.metadata.main}/measures`} element={<MetadataPage />} />
				<Route
					path={`${routes.metadata.main}/measures/:metaMeasureGroupId`}
					element={<DetailMeasureGroupPage />}
				/>
				<Route
					path={`${routes.metadata.main}/${routes.metadata.measures}/:metaMeasureGroupId/unit/:metaMeasureUnitId`}
					element={<DetailMeasureUnitPage />}
				/>
				<Route
					path={`${routes.metadata.main}/${routes.metadata.constraintTables}`}
					element={<MetadataPage />}
				/>
				<Route
					path={`${routes.metadata.main}/${routes.metadata.constraintTables}/:constraintTableId`}
					element={<ConstraintTableCardPage />}
				/>
				<Route
					path={`${routes.metadata.main}/${routes.metadata.constraintTables}/:constraintTableId/:constraintTableRowId`}
					element={<ConstraintTableRowPage />}
				/>
				<Route path={`${routes.classifiers.main}`} element={<ClassifierPage />} />
				<Route
					path={`${routes.classifiers.main}/:classifierGroupId`}
					element={<ClassifierGroupPage />}
				/>
				<Route
					path={`${routes.classifiers.main}/:classifierGroupId/${routes.classifiers.records}`}
					element={<ClassifierListPage />}
				/>
				<Route
					path={`${routes.classifiers.main}/:classifierGroupId/${routes.classifiers.records}/:classifierItemId`}
					element={<ClassifierListPage />}
				/>
				<Route
					path={`${routes.classifiers.main}/:classifierGroupId/${routes.classifiers.records}/:classifierItemId/${routes.classifiers.edit}`}
					element={<ClassifierRecordDetailPage />}
				/>
				<Route path={routes.admin.main} element={<AdminPage />} />
				<Route
					path={`${routes.admin.main}/${routes.admin.groups}`}
					element={<AdminPage />}
				/>
				<Route
					path={`${routes.admin.main}/${routes.admin.users}`}
					element={<AdminPage />}
				/>
				<Route
					path={`${routes.admin.main}/${routes.admin.journal}`}
					element={<AdminPage />}
				/>
				<Route path={routes.integrations.main} element={<IntegrationsPage />} />
				<Route
					path={`${routes.integrations.main}/${routes.integrations.create}`}
					element={<CreateNewIntegrationPage />}
				/>
				{/* <Route
					path={`${routes.integrations.main}/${routes.integrations.detail}/:integrationId`}
					element={<IntegrationDetail />}
				/>
				<Route
					path={`${routes.integrations.main}/${routes.integrations.detail}/:integrationId/${routes.integrations.exchangeclass}/:exchangeId`}
					element={<ExchangeClassDetail />}
				/>
				<Route
					path={`${routes.integrations.main}/${routes.integrations.detail}/:integrationId/${routes.integrations.exportReplicaMessage}/:exportReplicaMessageId`}
					element={<ReplicaExportMessage />}
				/>
				<Route
					path={`${routes.integrations.main}/${routes.integrations.detail}/:integrationId/${routes.integrations.importReplicaMessage}/:importReplicaMessageId`}
					element={<ReplicaImportMessage />}
				/>
				<Route
					path={`${routes.integrations.main}/${routes.integrations.detail}/:integrationId/${routes.integrations.exchangeclass}/:exchangeId/${routes.integrations.importformula}`}
					element={<FormulaPage />}
				/>
				<Route
					path={`${routes.integrations.main}/${routes.integrations.detail}/:integrationId/${routes.integrations.exchangeclass}/:exchangeId/${routes.integrations.exportformula}`}
					element={<FormulaPage />}
				/> */}

				<Route path={routes.settings.main} element={<SettingPage />} />
				<Route
					path={`${routes.settings.main}/${routes.settings.basic}`}
					element={<SettingPage />}
				/>
				<Route
					path={`${routes.settings.main}/${routes.settings.integration}`}
					element={<SettingPage />}
				/>
				<Route
					path={`${routes.settings.main}/${routes.settings.debug}`}
					element={<SettingPage />}
				/>
				<Route
					path={`${routes.settings.main}/${routes.settings.plugins}`}
					element={<SettingPage />}
				/>
				<Route
					path={`${routes.settings.main}/${routes.settings.db}`}
					element={<SettingPage />}
				/>
				<Route
					path={`${routes.settings.main}/${routes.settings.utilities}`}
					element={<SettingPage />}
				/>
				<Route
					path={`${routes.settings.main}/${routes.settings.test}`}
					element={<SettingPage />}
				/>
				<Route
					path={`${routes.settings.main}/${routes.settings.gen}`}
					element={<SettingPage />}
				/>
				<Route
					path="*"
					element={<Result status="404" title="Error!" subTitle="Error texts" />}
				/>
			</Route>
		</Routes>
	</BrowserRouter>
);
