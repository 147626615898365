import { RiLayoutGrid2Line, RiLayoutGridLine } from '@remixicon/react';
import { Radio, RadioChangeEvent } from 'antd';
import React from 'react';
import styles from './ViewSwitcher.module.scss';
import { ButtonWrapperStyles } from './ViewSwitcherStyles';

export enum ViewsTypesEnum {
	CARDS = 'cards',
	TABLE = 'table',
}

interface IViewSwitcherProps {
	value: ViewsTypesEnum;
	onViewSwitch: (e: ViewsTypesEnum) => void;
}

const ViewSwitcherComp: React.FC<IViewSwitcherProps> = ({ onViewSwitch, value }) => {
	const onChange = (e: RadioChangeEvent) => {
		onViewSwitch(e.target.value);
	};

	return (
		<>
			<Radio.Group onChange={onChange} value={value} buttonStyle="solid">
				<Radio.Button value={ViewsTypesEnum.CARDS} style={ButtonWrapperStyles}>
					<div className={styles.iconWrapper}>
						<RiLayoutGridLine
							color={
								value === ViewsTypesEnum.CARDS
									? 'var(--svg-white)'
									: 'var(--disabled)'
							}
						/>
					</div>
				</Radio.Button>
				<Radio.Button value={ViewsTypesEnum.TABLE} style={ButtonWrapperStyles}>
					<div className={styles.iconWrapper}>
						<RiLayoutGrid2Line
							color={
								value === ViewsTypesEnum.TABLE
									? 'var(--svg-white)'
									: 'var(--disabled)'
							}
						/>
					</div>
				</Radio.Button>
			</Radio.Group>
		</>
	);
};

export const ViewSwitcher = React.memo(ViewSwitcherComp);
