import { RiLinkM } from '@remixicon/react';
import { App } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
	removeCurrentMetaAttribute,
	setCurrentMetaAttribute,
	updateMetaAttributeItem,
} from 'entities/metadata/metaAttributes/metaAttributes.store';
import { useTransactions } from 'entities/transactions';
import {
	AttributeDto,
	useLazyGetAttributeQuery,
	useLazyGetCatalogsByAttributeQuery,
} from 'shared/api/generatedApi/newUdmiApi';
import {
	useAddCreateAttributeGroupEventMutation,
	useAddUpdateAttributeGroupEventMutation,
} from 'shared/api/generatedApi/transactionServiceApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { NEW_ENTITY } from 'shared/helpers/Constants';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { colors } from 'shared/styles';
import { Chip } from 'shared/ui';

export const useEditSimpleAttribute = () => {
	const [formVals, setFormVals] = useState<AttributeDto>(null);
	const [usageList, setUsageList] = useState<Array<JSX.Element>>(null);

	const { currentAttribute } = useAppSelector((state) => state.entities.metadata.metaAttributes);

	const { notification } = App.useApp();

	const dispatch = useAppDispatch();

	const [getAttribute, { isLoading, error: getAttributeError }] = useLazyGetAttributeQuery();
	const [updateAttribute] = useAddUpdateAttributeGroupEventMutation();
	const [createAttribute] = useAddCreateAttributeGroupEventMutation();
	const [getUsage, { isLoading: usageLoading, error: usageError }] =
		useLazyGetCatalogsByAttributeQuery();

	const { metaAttributeId, metaAttributeGroupId } = useParams();
	const navigate = useNavigate();

	const { getTransactions } = useTransactions();

	const handleUpdateAttribute = async (attr) => {
		if (!attr.displayName || attr.displayName.length === 0 || !attr.type) {
			metaAttributeId !== NEW_ENTITY &&
				errorHelper(
					'Ошибка при обновлении атрибута!',
					new Error('Необходимо заполнить обязательные поля!'),
					notification
				);
			setFormVals({ ...currentAttribute });
			return;
		}

		if (metaAttributeId !== NEW_ENTITY) {
			const getData = await updateAttribute({
				body: [{ ...attr, id: metaAttributeId }],
			});

			if ('error' in getData) {
				errorHelper('Ошибка при обновлении атрибута!', getData.error, notification);
				setFormVals({ ...currentAttribute });
			}

			if ('data' in getData) {
				getTransactions();

				dispatch(
					updateMetaAttributeItem(
						getData.data.persistedEvents[0].data as unknown as AttributeDto
					)
				);
				dispatch(
					setCurrentMetaAttribute(
						getData.data.persistedEvents[0].data as unknown as AttributeDto
					)
				);
			}
		} else {
			createAttribute({
				body: [{ ...attr, list: !!attr.list, attributeGroupId: metaAttributeGroupId }],
			})
				.unwrap()
				.then((res) => {
					dispatch(
						updateMetaAttributeItem(
							res.persistedEvents[0].data as unknown as AttributeDto
						)
					);
					dispatch(
						setCurrentMetaAttribute(
							res.persistedEvents[0].data as unknown as AttributeDto
						)
					);
					navigate(
						`/${routes.metadata.main}/${routes.metadata.group}/${metaAttributeGroupId}/${routes.metadata.attribute}/${res.persistedEvents[0].entityId}`
					);
				})
				.then(() => getTransactions())
				.catch((err) => {
					errorHelper('Ошибка при обновлении атрибута!', err, notification);
				});
		}
	};

	useEffect(() => {
		if (!currentAttribute && metaAttributeId !== NEW_ENTITY) {
			const getData = async () => {
				const data = await getAttribute({
					id: metaAttributeId,
				});

				if ('data' in data) {
					dispatch(setCurrentMetaAttribute(data.data));
				}
			};

			getData();
		}

		return () => {
			dispatch(removeCurrentMetaAttribute());
			setFormVals(null);
		};
	}, []);

	useEffect(() => {
		if (currentAttribute) {
			getUsage({ id: currentAttribute?.id })
				.unwrap()
				.then((res) => {
					setUsageList(
						res.map((item) => (
							<Chip
								color="primary"
								icon={<RiLinkM size={16} />}
								key={item.id}
								onClick={() => navigate(`/${routes.catalogs.main}/${item?.id}`)}
								style={{ color: colors.primary }}
							>
								{item?.displayName}
							</Chip>
						))
					);
				})
				.catch((e) => {
					errorHelper(
						'Ошибка при загрузке применимости атрибута в справочниках!',
						e,
						notification
					);
				});
		}
	}, [currentAttribute]);

	useEffect(() => {
		if (currentAttribute) setFormVals(currentAttribute);
	}, [currentAttribute]);

	return {
		formVals,
		usageList,
		usageLoading,
		usageError,
		isLoading,
		getAttributeError,
		handleUpdateAttribute,
	};
};
