import { App } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router';
// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import { useTransactions } from 'entities/transactions';
import { useLazyGetRestrictionTableDeclarationsQuery } from 'shared/api/generatedApi/newUdmiApi';
import { useUpdateRestrictionTable1Mutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setCatalogRestrictionTablesDeclarations } from './catalogRestrictions.store';

export const useCatalogRestrictionTableDeclarations = () => {
	const [getRestrictions, { isLoading, error }] = useLazyGetRestrictionTableDeclarationsQuery();
	const [patchRestrictionTable, { isLoading: isPatchLoading }] =
		useUpdateRestrictionTable1Mutation();
	const { catalogRestrictionTablesDeclarations } = useAppSelector(
		(state) => state.entities.catalogs.catalogRestrictionTablesDeclarations
	);
	const dispatch = useAppDispatch();
	const { notification } = App.useApp();
	const { catalogGroupId } = useParams();
	const { getTransactions } = useTransactions();

	const onChangeRequired = async (declarationId: string, isRequired: boolean) => {
		patchRestrictionTable({
			body: [
				{
					id: declarationId,
					generationValues: true,
					required: isRequired,
				},
			],
		})
			.unwrap()
			.then((res) => {
				if (res.persistedEvents.length > 0) {
					getTransactions();
				}
			})
			.catch((err) =>
				errorHelper('Ошибка редактирования таблицы допустимых значений', err, notification)
			);
	};

	useEffect(() => {
		getRestrictions({ catalogId: catalogGroupId })
			.unwrap()
			.then((res) => dispatch(setCatalogRestrictionTablesDeclarations(res)))
			.catch((e) =>
				errorHelper(
					'Ошибка при получении деклараций ограничительных таблиц',
					e,
					notification
				)
			);
	}, [catalogGroupId]);

	return {
		catalogRestrictionTablesDeclarations,
		onChangeRequired,
		isPatchLoading,
		isLoading,
		error,
	};
};
