import { App } from 'antd';
import {
	editCurrentClassifier,
	setCurrentClassifier,
} from 'entities/classifiers/classifiersGroups/classifier.store';
import { ClassifierDtoWithAttributeName } from 'shared/api/generatedApi/newUdmiApi';
import { useAddUpdateEvent2Mutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';

type PartialExceptId<T extends { id: string; displayName: string }> = Partial<Omit<T, 'id'>> & {
	id: T['id'];
	displayName: T['displayName'];
};

export const useEditClassifierMainInfo = () => {
	const { currentClassifierGroup } = useAppSelector(
		(state) => state.entities.classifiers.classifierGroups
	);
	const dispatch = useAppDispatch();

	const [editClassifier] = useAddUpdateEvent2Mutation();

	const { notification } = App.useApp();

	const handleUpdate = async (arg: PartialExceptId<ClassifierDtoWithAttributeName>) => {
		await editClassifier({
			body: [
				{
					...arg,
					id: currentClassifierGroup.id,
					linkClassifierAttributes: arg.linkClassifierAttributes as unknown as string[],
				},
			],
		})
			.unwrap()
			.then((res) => {
				dispatch(editCurrentClassifier(res.persistedEvents[0].data));
			})
			.catch((e) => {
				dispatch(setCurrentClassifier(currentClassifierGroup));
				errorHelper('Ошибка при редактировании классификатора!', e, notification);
			});
	};

	return {
		handleUpdate,
	};
};
