import { App } from 'antd';
import { updateMetaAttributesGroup } from 'entities/metadata/attributesGroups/attributesGroups.store';
import { useTransactions } from 'entities/transactions';
import { AttributeGroupDto } from 'shared/api/generatedApi/newUdmiApi';
import { useAddUpdateAttributeGroupEvent1Mutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useEditAttributeGroup = () => {
	const { notification } = App.useApp();

	const [useEditAttributeGroup, { isLoading, error }] =
		useAddUpdateAttributeGroupEvent1Mutation();

	const dispatch = useAppDispatch();

	const { getTransactions } = useTransactions();

	const editNewGroup = async (
		displayName: string,
		description: string,
		id: string,
		parentId?: string
	) => {
		const data = await useEditAttributeGroup({
			body: [
				{
					displayName,
					description,
					id,
					...(parentId && { parentId }),
				},
			],
		});
		if ('error' in data) {
			errorHelper('Ошибка при добавлении новой группы атрибутов!', data.error, notification);
		}

		if ('data' in data) {
			getTransactions();
			dispatch(
				updateMetaAttributesGroup(
					data.data.persistedEvents[0].data as unknown as AttributeGroupDto
				)
			);
		}
	};

	return {
		loading: isLoading,
		error,
		editNewGroup,
	};
};
