import { measurementGetsApi as api } from './newCreateApiFile';
const injectedRtkApi = api.injectEndpoints({
	endpoints: (build) => ({
		getMeasurementGroup: build.query<GetMeasurementGroupApiResponse, GetMeasurementGroupApiArg>(
			{
				query: (queryArg) => ({ url: `/api/v1/measurements/${queryArg.id}` }),
			}
		),
		getAllMeasurementGroups: build.query<
			GetAllMeasurementGroupsApiResponse,
			GetAllMeasurementGroupsApiArg
		>({
			query: () => ({ url: '/api/v1/measurement-groups' }),
		}),
		getMeasurementGroup1: build.query<
			GetMeasurementGroup1ApiResponse,
			GetMeasurementGroup1ApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v1/measurement-groups/${queryArg.id}`,
				params: { includeChildren: queryArg.includeChildren },
			}),
		}),
	}),
	overrideExisting: false,
});
export { injectedRtkApi as measurementGetsApi };
export type GetMeasurementGroupApiResponse = /** status 200 OK */ MeasurementHttpResponse;
export type GetMeasurementGroupApiArg = {
	id: string;
};
export type GetAllMeasurementGroupsApiResponse =
	/** status 200 OK */ MeasurementGroupHttpResponse[];
export type GetAllMeasurementGroupsApiArg = void;
export type GetMeasurementGroup1ApiResponse =
	/** status 200 OK */ MeasurementGroupWithMeasurementsHttpResponse;
export type GetMeasurementGroup1ApiArg = {
	id: string;
	includeChildren?: boolean;
};
export type MeasurementHttpResponse = {
	id: string;
	displayName: string;
	shortName?: string;
	isDefault: boolean;
	measurementGroupId: string;
	formula?: string;
	inverseFormula?: string;
	coefficient?: number;
	createdAt: string;
	updatedAt: string;
};
export type MeasurementGroupHttpResponse = {
	id: string;
	displayName: string;
	description?: string;
	baseMeasurementId?: string;
	createdAt: string;
	updatedAt: string;
};
export type MeasurementGroupWithMeasurementsHttpResponse = {
	measurementGroup: MeasurementGroupHttpResponse;
	measurements?: MeasurementHttpResponse[];
};
export const {
	useGetMeasurementGroupQuery,
	useLazyGetMeasurementGroupQuery,
	useGetAllMeasurementGroupsQuery,
	useLazyGetAllMeasurementGroupsQuery,
	useGetMeasurementGroup1Query,
	useLazyGetMeasurementGroup1Query,
} = injectedRtkApi;
