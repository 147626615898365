import { Col, Flex, Result, Spin, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { ClassifierAttributesWidget } from 'widgets/Classifiers/ClassifierItems/ClassifierAttributesListWidget';
import { Transactions } from 'widgets/Transactions';
import { EditClassifierMainInfo } from 'features/classifiers/DetailClassifier/EditClassifierMainInfo';
import { clearBreadcrumbs, setBreadcrumbs } from 'entities/breadcrumbs';
import { useGetCurrentСlassifierGroup } from 'entities/classifiers/classifiersGroups/classifier.model';
import { removeCurrentClassifierGroup } from 'entities/classifiers/classifiersGroups/classifier.store';
import { routes } from 'shared/config';
import { useAppDispatch } from 'shared/hooks';
import { DetailFooter } from 'shared/ui';

const ClassifierGroupPageUi = () => {
	const { currentClassifierGroup, loading, error } = useGetCurrentСlassifierGroup();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const items: TabsProps['items'] = useMemo(
		() => [
			{
				key: 'mainInfo',
				label: 'Общая информация',
				children: <EditClassifierMainInfo classifier={currentClassifierGroup} />,
			},
			{
				key: 'relations',
				label: 'Настройка атрибутов',
				children: <ClassifierAttributesWidget />,
			},
		],
		[currentClassifierGroup]
	);

	useEffect(() => {
		if (currentClassifierGroup) {
			dispatch(
				setBreadcrumbs({
					displayName: 'Классификаторы',
					url: `${routes.classifiers.main}`,
					child: {
						displayName: 'Карточка классификатора',
						url: `${routes.classifiers.main}/${currentClassifierGroup.id}`,
					},
				})
			);
		}
	}, [currentClassifierGroup]);

	useEffect(() => {
		return () => {
			dispatch(clearBreadcrumbs());
			dispatch(removeCurrentClassifierGroup());
		};
	}, []);

	const handleBackClick = () => {
		navigate(`/${routes.classifiers.main}/`);
	};

	if (error)
		return (
			<Result
				title="Ошибка при получении классификатора!"
				subTitle={JSON.stringify(error)}
				status="error"
			/>
		);

	return (
		<>
			{loading ? (
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<Spin />
				</div>
			) : (
				<Flex vertical gap={12}>
					<Flex justify="space-between">
						<Typography.Title level={1}>
							{currentClassifierGroup?.displayName}
						</Typography.Title>

						<Transactions />
					</Flex>
					<Col xl={18} xs={24} sm={24} md={24} lg={18}>
						<Tabs items={items} destroyInactiveTabPane />
					</Col>
				</Flex>
			)}

			<DetailFooter customHandleBack={handleBackClick} />
		</>
	);
};

export const ClassifierGroupPage = React.memo(ClassifierGroupPageUi);
