import { App } from 'antd';
import { deleteAttributeMapping } from 'entities/catalogs/catalogDeduplication/catalogDeduplication.store';
import { useDeleteDeduplicationAttributeMappingMutation } from 'shared/api/generatedApi/deduplicationApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const deleteDeduplicationAttributeMapping = () => {
	const [fetchDeleteMapping, { isLoading: isDeletingMapping }] =
		useDeleteDeduplicationAttributeMappingMutation();

	const { notification } = App.useApp();

	const dispatch = useAppDispatch();

	const deleteMapping = (deduplicationOptionId: string, mappingId: string) => {
		fetchDeleteMapping({ id: mappingId })
			.unwrap()
			.then(() => {
				dispatch(deleteAttributeMapping({ deduplicationOptionId, mappingId }));
				notification.success({ message: 'Маппинг удален' });
			})
			.catch((err) =>
				errorHelper('Ошибка при удалении маппига атрибутов', err, notification)
			);
	};

	return { isDeletingMapping, deleteMapping };
};
