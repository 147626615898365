import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ClassifierItemDto } from 'shared/api/generatedApi/newUdmiApi';
import { useLazyGetSearchResultQuery } from 'shared/api/generatedApi/searchApi';
import { errorHelper } from 'shared/helpers';
import { useDebounce } from 'shared/hooks/useDebounce';

export const useSearchClassifiersItems = () => {
	const [searchedClassifierItems, setSearchedClassifierItems] = useState<Array<ClassifierItemDto>>(
		[]
	);
	const [searchParams, setSearchParams] = useSearchParams();

	const [getSearchResult, { isFetching: isSearchItemsLoading }] = useLazyGetSearchResultQuery();

	const searchHandler = useDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > 0) {
			searchParams.set('searchParentValue', e.target.value);
			setSearchParams(searchParams);
		} else {
			searchParams.delete('searchParentValue');
			setSearchParams(searchParams);
		}
	}, 1000);

	const fetchSearch = (searchValue: string) => {
		getSearchResult({ entityType: 'classifier_items', textRequest: searchValue })
			.unwrap()
			.then((res) => setSearchedClassifierItems(res as ClassifierItemDto[]))
			.catch((err) => {
				errorHelper(
					'Ошибка при получении списка ограничительных таблиц!',
					err,
					notification
				);
			});
	};

	useEffect(() => {
		const searchValue = searchParams.get('searchParentValue');
		if (searchValue) {
			fetchSearch(searchValue);
		}
	}, [searchParams]);

	return {
		searchHandler,
		searchValue: searchParams.get('searchParentValue'),
		isLoading: isSearchItemsLoading,
		searchedClassifierItems,
	};
};
