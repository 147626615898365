import { Button } from 'antd';
import React, { ReactElement } from 'react';
import { DropdownLink } from '../DropdownLink';

interface IButtonVsDropdownLink {
	type?: 'dropdownLink';
	icon?: ReactElement;
	title: string;
	callback: () => void;
	isDisabled?: boolean;
	isLoading?: boolean;
}

const ButtonVsDropdownLinkUi = ({
	type,
	icon,
	title,
	callback,
	isLoading,
	isDisabled,
}: IButtonVsDropdownLink) => {
	return type === 'dropdownLink' ? (
		<DropdownLink
			title={title}
			icon={icon}
			callback={callback}
			loading={isLoading}
			disabled={isDisabled}
		/>
	) : (
		<Button
			type="text"
			onClick={callback}
			disabled={isDisabled}
			icon={icon}
			loading={isLoading}
		>
			{title}
		</Button>
	);
};

export const ButtonVsDropdownLink = React.memo(ButtonVsDropdownLinkUi);
