import { App } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
	editCurrentMeasureGroup,
	setCurrentMeasureUnit,
	useGetCurrentMeausureGroup,
} from 'entities/metadata/measures';
import { useTransactions } from 'entities/transactions';
import { MeasurementHttpResponse } from 'shared/api/generatedApi/measurementGetsApi';
import { useAddCreateEventMutation } from 'shared/api/generatedApi/measurementPatchApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useCreateMeasureUnit = () => {
	const { metaMeasureGroupId } = useParams();
	const navigate = useNavigate();

	const [createUnit, { isLoading }] = useAddCreateEventMutation();
	const { currentMeasureGroup } = useGetCurrentMeausureGroup();

	const { getTransactions } = useTransactions();

	const { notification } = App.useApp();

	const dispatch = useAppDispatch();

	const createMeasureUnit = async () => {
		await createUnit({
			body: [
				{
					displayName: '',
					isDefault: false,
					measurementGroupId: metaMeasureGroupId,
				},
			],
		})
			.then((res) => {
				if ('data' in res) {
					const result = res.data.persistedEvents[0]
						.data as unknown as MeasurementHttpResponse;
					dispatch(setCurrentMeasureUnit(result));
					dispatch(
						editCurrentMeasureGroup({
							...currentMeasureGroup,
							childrenMeasures: [...currentMeasureGroup.childrenMeasures, result],
						})
					);
					getTransactions();
					navigate(`unit/${result.id}`);
				}
				if ('error' in res) errorHelper('Ошибка при создании ЕИ!', res.error, notification);
			})
			.catch((e) => errorHelper('Ошибка при создании ЕИ!', e, notification));
	};

	return {
		createMeasureUnit,
		isLoading,
	};
};
