import { RiCheckboxCircleLine, RiCircleFill, RiFileCopyFill } from '@remixicon/react';
import { Button, Flex, App } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDeleteDuplicates } from 'features/catalogs/deleteDuplicates';
import { useSetDuplicateItem } from 'features/catalogs/setDuplicateItem';
import { useSetOriginalItem } from 'features/catalogs/setOriginalItem';
import { setPotentialOriginalItemId } from 'entities/catalogs/catalogDeduplication/catalogDeduplication.store';
import {
	AttributeDeclarationDto,
	CatalogDto,
	ItemDto,
	useLazyGetAttributeDeclarations1Query,
	useLazyGetCatalogsQuery,
} from 'shared/api/generatedApi/newUdmiApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { colors } from 'shared/styles';
import { Hint } from 'shared/ui';
import {
	chipStyleHelper,
	ChipStatusRuEnum,
	ChipStatus,
} from 'shared/ui/components/Chip/chipStylehelper';

export const useSuspectedDuplicate = () => {
	const [suspectedDuplicateId, setSuspectedDuplicateId] = useState<string>('');
	const [suspectedOriginalItem, setSuspectedOriginalItem] = useState<ItemDto>(null);
	const [suspectedDuplicateItems, setSuspectedDuplicateItems] = useState<Array<ItemDto>>([]);
	const [possibleOriginalItemId, setPossibleOriginalItemId] = useState<string>('');
	const [declarations, setDeclarations] = useState<Array<AttributeDeclarationDto>>([]);
	const [catalogs, setCatalogs] = useState<Array<CatalogDto>>([]);

	const suspectedDuplicates = useAppSelector(
		(state) => state.entities?.catalogs?.catalogDeduplication?.suspectedDuplicates?.data
	);

	const potentialOriginalItemId = useAppSelector(
		(state) => state.entities?.catalogs?.catalogDeduplication?.potentialOriginalItemId
	);

	const passSuspectedDuplicateId = (id: string) => setSuspectedDuplicateId(id);

	const [fetchGetCatalogs] = useLazyGetCatalogsQuery();

	const [fetchGetAttributeDeclarations] = useLazyGetAttributeDeclarations1Query();

	const { deleteDuplicates, isLoading: isDuplicateDeleting } = useDeleteDuplicates();

	const { setDuplicateItem, isLoading: isDuplicateSetting } = useSetDuplicateItem();

	const { setOriginalItem, isOriginalItemSetting } = useSetOriginalItem();

	const { notification } = App.useApp();

	const dispatch = useAppDispatch();

	const titleButton = (id: string) => {
		return id === possibleOriginalItemId ? (
			<Flex
				gap={6}
				style={{ color: colors.success, fontWeight: 700, whiteSpace: 'nowrap' }}
				justify="center"
			>
				<RiCheckboxCircleLine size={16} color={colors.success} />
				<span>Является оригиналом</span>
			</Flex>
		) : (
			<Flex justify="center">
				<Button
					type="text"
					icon={<RiFileCopyFill size={16} />}
					onClick={() => setPossibleOriginalItemId(id)}
				>
					Принять за оригинал
				</Button>
			</Flex>
		);
	};

	const isColor = (id: string) => {
		return declarations?.find((d) => d.id === id)?.attribute?.type === 'COLOR';
	};

	const style = (id: string, declarationId?: string): React.CSSProperties => {
		return possibleOriginalItemId === id
			? {
					backgroundColor: colors.successBg,
					borderRadius: 6,
					paddingTop: isColor(declarationId) ? 6 : 10,
					paddingBottom: isColor(declarationId) ? 6 : 10,
					justifyContent: 'center',
					alignItems: 'center',
					minWidth: 198,
				}
			: {
					justifyContent: 'center',
					alignItems: 'center',
					minWidth: 198,
				};
	};

	const statusAndName = (status: ChipStatus, displayName: string, id: string): ReactElement => {
		return (
			<Flex gap={6} style={style(id)}>
				<Hint title={ChipStatusRuEnum[status]}>
					<RiCircleFill size={10} color={chipStyleHelper(status).main} />
				</Hint>
				{!displayName || displayName === 'null' ? 'без названия' : displayName}
			</Flex>
		);
	};

	const getCatalogs = (ids: string[]) => {
		fetchGetCatalogs({ ids })
			.unwrap()
			.then((res) => setCatalogs(res))
			.catch((err) =>
				errorHelper('Ошибка при получении названия каталога', err, notification)
			);
	};

	const getAttributeNames = (ids: string[]) => {
		fetchGetAttributeDeclarations({ ids })
			.unwrap()
			.then((res) => setDeclarations(res))
			.catch((err) => errorHelper('Ошибка при получении деклараций', err, notification));
	};

	const setNewOriginalItem = async () => {
		if (possibleOriginalItemId && possibleOriginalItemId !== suspectedOriginalItem?.id) {
			await setOriginalItem({
				suspectedDuplicateId,
				newOriginalItemId: possibleOriginalItemId,
			});
		}
	};

	const deleteDuplicatedItems = async (closeModal: () => void) => {
		await setNewOriginalItem();
		await deleteDuplicates([suspectedDuplicateId]);
		closeModal();
	};

	const setDuplicatedStatus = async (closeModal: () => void) => {
		await setNewOriginalItem();
		await setDuplicateItem([suspectedDuplicateId]);
		closeModal();
	};

	const columns: any = !suspectedOriginalItem
		? []
		: [
				{
					title: '',
					dataIndex: 'displayNames',
					key: 'displayNames',
					width: `${100 / (suspectedDuplicateItems?.length + 2)}%`,
				},
				{
					title: titleButton(suspectedOriginalItem?.id),
					dataIndex: suspectedOriginalItem?.id,
					key: suspectedOriginalItem?.id,
					align: 'center',
					width: `${100 / (suspectedDuplicateItems?.length + 2)}%`,
				},
				...suspectedDuplicateItems.map((item) => ({
					title: titleButton(item.id),
					dataIndex: item.id,
					key: item.id,
					align: 'center',
					width: `${100 / (suspectedDuplicateItems?.length + 2)}%`,
				})),
			];

	const dataSource =
		!suspectedOriginalItem?.values || !suspectedDuplicateItems
			? []
			: [
					{
						key: 'position',
						displayNames: 'Позиция',
						[suspectedOriginalItem?.id]: statusAndName(
							suspectedOriginalItem?.status.toLowerCase() as ChipStatus,
							suspectedOriginalItem?.displayName,
							suspectedOriginalItem?.id
						),
						...Object.fromEntries(
							suspectedDuplicateItems?.map((item) => [
								item.id,
								statusAndName(
									item.status.toLowerCase() as ChipStatus,
									item.displayName,
									item.id
								),
							])
						),
					},
					{
						key: 'source',
						displayNames: 'Источник',

						[suspectedOriginalItem?.id]: catalogs?.find(
							(cat) => cat.id === suspectedOriginalItem?.catalogId
						)?.displayName,

						...Object.fromEntries(
							suspectedDuplicateItems?.map((item) => [
								item.id,
								catalogs?.find((cat) => cat.id === item.catalogId)?.displayName,
							])
						),
					},
					...Object.keys(suspectedOriginalItem?.values).map((declarationId) => ({
						key: declarationId,

						displayNames: declarations?.find((d) => d.id === declarationId)?.attribute
							?.displayName,

						[suspectedOriginalItem?.id]: isColor(declarationId) ? (
							<Flex gap={6} style={style(suspectedOriginalItem?.id, declarationId)}>
								<RiCircleFill
									color={
										suspectedOriginalItem?.values[
											declarationId
										] as unknown as string
									}
									size={20}
								/>
								{suspectedOriginalItem?.values[declarationId] as unknown as string}
							</Flex>
						) : (
							<Flex gap={6} style={style(suspectedOriginalItem?.id)}>
								{suspectedOriginalItem?.values[declarationId] as unknown as string}
							</Flex>
						),

						...Object.fromEntries(
							suspectedDuplicateItems?.map((item) => [
								item.id,
								isColor(declarationId) ? (
									<Flex gap={6} style={style(item.id, declarationId)}>
										<RiCircleFill
											color={item.values[declarationId] as unknown as string}
											size={20}
										/>
										{item.values[declarationId] as unknown as string}
									</Flex>
								) : (
									<Flex gap={6} style={style(item.id)}>
										{item.values[declarationId] as unknown as string}
									</Flex>
								),
							])
						),
					})),
				];

	useEffect(() => {
		if (!potentialOriginalItemId) {
			const initialPossibleItemId = suspectedOriginalItem?.id;
			setPossibleOriginalItemId(initialPossibleItemId);
		}
	}, [potentialOriginalItemId]);

	useEffect(() => {
		if (!possibleOriginalItemId) return;

		const id =
			possibleOriginalItemId !== suspectedOriginalItem.id ? possibleOriginalItemId : null;
		dispatch(setPotentialOriginalItemId(id));
	}, [possibleOriginalItemId]);

	useEffect(() => {
		if (!suspectedDuplicateId || !suspectedDuplicates?.length) return;

		const suspectedOriginalItem = suspectedDuplicates?.find(
			(item) => item.id === suspectedDuplicateId
		)?.suspectedOriginalItem;

		const suspectedDuplicateItems = suspectedDuplicates?.find(
			(item) => item.id === suspectedDuplicateId
		)?.suspectedDuplicateItems;

		const catalogIds = suspectedDuplicateItems
			?.map((item) => item.catalogId)
			.concat(suspectedOriginalItem?.catalogId);

		const declarationIds = suspectedOriginalItem?.values
			? Object.keys(suspectedOriginalItem?.values)
			: [];

		setSuspectedOriginalItem(suspectedOriginalItem);
		setSuspectedDuplicateItems(suspectedDuplicateItems);
		setPossibleOriginalItemId(suspectedOriginalItem?.id);
		getCatalogs(catalogIds);
		getAttributeNames(declarationIds);
	}, [suspectedDuplicates, suspectedDuplicateId]);

	return {
		dataSource,
		columns,
		passSuspectedDuplicateId,
		deleteDuplicatedItems,
		setDuplicatedStatus,
		isDuplicateDeleting,
		isDuplicateSetting,
		isOriginalItemSetting,
	};
};
