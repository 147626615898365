import { App, TreeDataNode } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
	AttributeGroupDto,
	AttributeGroupTreeNode,
	useLazyGetAttributeGroupQuery,
	useLazyGetAttributeGroupTreeQuery,
} from 'shared/api/generatedApi/newUdmiApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import {
	removeCurrentAttributeGroup,
	setCurrentAttributeGroup,
	setMetaAttributesGroups,
	updateMetaAttributesGroupChildTreeItem,
} from './attributesGroups.store';

export type AttributeGroupItem = AttributeGroupTreeNode &
	TreeDataNode & {
		key: React.Key;
		title: string;
		children?: Array<AttributeGroupItem>;
	};

export type DisplayTreeNode = Omit<AttributeGroupItem, 'title'> & { title: React.ReactNode };

export const useMetaAttributesGroups = () => {
	const [attrList, setAttrList] = useState<Array<AttributeGroupDto>>([]);
	const [currAttrGroup, setCurrAttrGroup] = useState<AttributeGroupDto | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const [getAttributesGroups, { error: attributesGroupsError }] = useLazyGetAttributeGroupQuery();
	const [getAttributeGroupWithParentsById, { error: currentAttrubuteGroupError }] =
		useLazyGetAttributeGroupTreeQuery();

	const { notification } = App.useApp();

	const { metaAttributeGroupId } = useParams();

	const navigate = useNavigate();

	const dispatch = useAppDispatch();
	const { metaAttributeGroups, currentAttributeGroup } = useAppSelector(
		(state) => state.entities.metadata.attributesGroups
	);

	const [searchParams] = useSearchParams();

	useEffect(() => {
		const searchParentValue = searchParams.get('searchParentValue');
		!searchParentValue && getGroups();
	}, [searchParams.get('searchParentValue')]);

	const addNewItemToList = (
		arr: Array<AttributeGroupDto>,
		group: AttributeGroupDto
	): Array<AttributeGroupDto> =>
		arr.map((item) => {
			return item.id === group.id ? group : item;
		});

	const findChild = (
		arr: Array<AttributeGroupTreeNode>,
		id: string
	): AttributeGroupTreeNode | undefined => {
		if (arr) {
			for (const attr of arr) {
				if (attr.id === id) {
					return attr;
				}
				if (attr.children && attr.children.length) {
					const child = findChild(attr.children, id);
					if (child) {
						return child;
					}
				}
			}
		}

		return undefined;
	};

	const getAttributeWithParent = async () => {
		const data = await getAttributeGroupWithParentsById({
			id: metaAttributeGroupId,
			direction: 'up',
			includeChildren: true,
		});
		if ('error' in data) {
			if ('status' in data.error && data.error.status === 455) {
				navigate(`/${routes.metadata.main}`);
				return;
			}
			errorHelper('Ошибка при получении справочников!', data.error, notification);
		}
		if ('data' in data) {
			return data.data;
		}
	};

	const getAttributes = async (parentId?: string): Promise<Array<AttributeGroupDto> | null> => {
		const data = await getAttributesGroups({ ...(parentId ? { parentId } : { root: true }) });
		if ('error' in data) {
			errorHelper(
				'Ошибка при получении корневой группы справочников!',
				data.error,
				notification
			);
			return null;
		}
		if (parentId) {
			for (const item of data.data) {
				dispatch(updateMetaAttributesGroupChildTreeItem({ item, parentId }));
			}
		} else {
			if ('data' in data) {
				return data.data;
			}
		}
		return null;
	};

	const getGroups = async () => {
		setLoading(true);
		const metaAttributesGroupsArr = await getAttributes();
		if (metaAttributesGroupsArr) {
			if (metaAttributesGroupsArr.length) {
				if (metaAttributeGroupId) {
					const data = await getAttributeWithParent();

					if (data) {
						const completeData = addNewItemToList(
							metaAttributesGroupsArr,
							data as AttributeGroupDto
						);
						dispatch(setMetaAttributesGroups(completeData as AttributeGroupDto[]));

						dispatch(setCurrentAttributeGroup(findChild([data], metaAttributeGroupId)));
						setLoading(false);
						return;
					}
				}
				dispatch(setMetaAttributesGroups(metaAttributesGroupsArr));
			}
		}

		setLoading(false);
	};

	useEffect(() => {
		if (!metaAttributeGroups) {
			const searchParentValue = searchParams.get('searchParentValue');
			!searchParentValue && getGroups();
		}
	}, []);

	useEffect(() => {
		if (!metaAttributeGroupId) {
			dispatch(removeCurrentAttributeGroup());
		} else {
			dispatch(
				setCurrentAttributeGroup(findChild(metaAttributeGroups, metaAttributeGroupId))
			);
		}
	}, [metaAttributeGroupId]);

	useEffect(() => {
		if (metaAttributeGroups) setAttrList(metaAttributeGroups);
	}, [metaAttributeGroups]);

	useEffect(() => {
		currentAttributeGroup ? setCurrAttrGroup(currentAttributeGroup) : setCurrAttrGroup(null);
	}, [currentAttributeGroup]);

	return {
		attrList,
		currAttrGroup,
		getAttributes,
		attributesGroupsError,
		currentAttrubuteGroupError,
		loading,
		findChild,
	};
};
