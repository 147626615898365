import { createApi } from '@reduxjs/toolkit/query/react';
import {
	baseQueryWithAuthCheckForMeasurementServiceQueries,
	baseQueryWithAuthCheckForNewGeneratedQueries,
	baseQueryWithAuthCheckForNewGenerateTransactionsServiceQueries,
	baseQueryWithAuthCheckForSearchServiceQueries,
	baseQueryWithAuthCheckForDeduplicationServiceQueries,
	baseQueryWithAuthCheckForIntegrationsServiceQueries,
} from '../baseQuery';

export const newUdmiApi = createApi({
	baseQuery: baseQueryWithAuthCheckForNewGeneratedQueries,
	endpoints: () => ({}),
});

export const transactionServiceApi = createApi({
	baseQuery: baseQueryWithAuthCheckForNewGenerateTransactionsServiceQueries,
	endpoints: () => ({}),
	reducerPath: 'transactionsServiceApi',
});

export const measurementGetsApi = createApi({
	baseQuery: baseQueryWithAuthCheckForMeasurementServiceQueries,
	endpoints: () => ({}),
	reducerPath: 'measurementApi',
});

export const measurementPatchApi = createApi({
	baseQuery: baseQueryWithAuthCheckForNewGenerateTransactionsServiceQueries,
	endpoints: () => ({}),
	reducerPath: 'measurementPatchApi',
});

export const searchApi = createApi({
	baseQuery: baseQueryWithAuthCheckForSearchServiceQueries,
	endpoints: () => ({}),
	reducerPath: 'searchApi',
});

export const deduplicationApi = createApi({
	baseQuery: baseQueryWithAuthCheckForDeduplicationServiceQueries,
	endpoints: () => ({}),
	reducerPath: 'deduplicationApi',
});

export const integrationsApi = createApi({
	baseQuery: baseQueryWithAuthCheckForIntegrationsServiceQueries,
	endpoints: () => ({}),
	reducerPath: 'integrationsApi',
});
