import { App } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
	useLazyGetAttributesQuery,
	useLazyGetRestrictionTableQuery,
} from 'shared/api/generatedApi/newUdmiApi';
import { errorHelper } from 'shared/helpers';
import { Values } from 'shared/helpers/types';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import {
	setConstraintTableInfo,
	setConstraintTableColumns,
} from '../constraintTableDetails/constraintTableDetails.store';

export const useConstraintTableRow = () => {
	const { notification } = App.useApp();

	const { constraintTableId, constraintTableRowId } = useParams();

	const dispatch = useAppDispatch();

	const [fetchRestrictionTable, { isFetching: isTableInfoLoading }] =
		useLazyGetRestrictionTableQuery();

	const [getAttributes, { isFetching: isAttributesLoading }] = useLazyGetAttributesQuery();

	const [currentRow, setCurrentRow] = useState<Values | null>(null);

	const { columns, table } = useAppSelector(
		(state) => state.entities.metadata.constraintTableDetails
	);

	const getRestrictionTable = () => {
		fetchRestrictionTable({
			id: constraintTableId,
		})
			.unwrap()
			.then((res) => {
				dispatch(setConstraintTableInfo(res));
			})
			.catch((err) =>
				errorHelper('Ошибка получения данных ограничительной таблицы!', err, notification)
			);
	};

	const getTableColumns = () => {
		getAttributes({
			ids: table.attributeIds,
		})
			.unwrap()
			.then((res) => {
				dispatch(setConstraintTableColumns(res.data));
			})
			.catch((err) =>
				errorHelper('Ошибка получения столбцов ограничительной таблицы!', err, notification)
			);
	};

	useEffect(() => {
		if (!table || table.id !== constraintTableId) {
			getRestrictionTable();
		} else if (table && table.attributeIds.length > 0) {
			!columns && getTableColumns();
			table.attributeValues.forEach((row) => {
				if ((row.id as unknown as string) === constraintTableRowId) {
					setCurrentRow(row as Values);
				}
			});
		}
	}, [table]);

	return {
		isLoading: isTableInfoLoading || isAttributesLoading,
		tableInfo: table,
		tableColumns: columns,
		currentRow,
	};
};
