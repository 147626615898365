import { RiLoopRightLine } from '@remixicon/react';
import { App, CollapseProps, Flex } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { DeleteDeduplicationOption } from 'features/catalogs/DeleteDeduplicationOption';
import {
	addCatalogDeduplicationOption,
	setCatalogDeduplicationOption,
} from 'entities/catalogs/catalogDeduplication/catalogDeduplication.store';
import {
	DeduplicationOptionDto,
	useLazyGetDeduplicationOptionsQuery,
	useCreateDeduplicationOptionMutation,
} from 'shared/api/generatedApi/deduplicationApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { colors } from 'shared/styles';
import { DropdownLink } from 'shared/ui';
import AppDropdown from 'shared/ui/components/AppDropdown';
import {
	CatalogDeduplicationCondition,
	useCatalogDeduplicationCondition,
} from '../CatalogDeduplicationCondition';
import { CatalogDeduplicationSearchButton } from '../CatalogDeduplicationSearchButton';

export const useCatalogDeduplication = () => {
	const panelStyle: React.CSSProperties = {
		marginBottom: 24,
		borderRadius: 6,
		border: 'solid 1px #e0e0e0',
		alignItems: 'center',
	};

	const deduplicationOptions = useAppSelector(
		(state) => state.entities.catalogs.catalogDeduplication.catalogDeduplication
	);

	const [items, setItems] = useState<CollapseProps['items']>([]);
	const [addingDeduplicationOption, setAddingDeduplicationOption] = useState<boolean>(false);

	const { catalogGroupId } = useParams();
	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const { startDeduplicationSearch } = useCatalogDeduplicationCondition();
	const [getDeduplicationOption, { isFetching: loadingDuplicationOptions }] =
		useLazyGetDeduplicationOptionsQuery();
	const [createDeduplicationOptioin] = useCreateDeduplicationOptionMutation();

	useEffect(() => {
		getDeduplicationOption({ catalogId: catalogGroupId })
			.unwrap()
			.then((res) => {
				dispatch(setCatalogDeduplicationOption(res));
			})
			.catch((err) =>
				errorHelper('Ошибка получения вариантов поиска дубликатов', err, notification)
			);
	}, []);

	const addDeduplicationOption = async () => {
		setAddingDeduplicationOption(true);

		createDeduplicationOptioin({
			createDeduplicationOptionRequest: {
				displayName: `Вариант №${deduplicationOptions.length + 1}`,
				catalogId: catalogGroupId,
				startMode: '',
				searchDirection: '',
				autoConsolidation: true,
				useForImport: true,
			},
		})
			.unwrap()
			.then((res) => dispatch(addCatalogDeduplicationOption(res)))
			.catch((err) =>
				errorHelper(
					'Ошибка при создании нового вариантов поиска дубликатов',
					err,
					notification
				)
			)
			.finally(() => setAddingDeduplicationOption(false));
	};

	const isRestartDisabled = (id: string): boolean => {
		return !(deduplicationOptions?.filter((option) => option.id === id)[0]
			.deduplicationAttributeMappings?.length as unknown as boolean);
	};

	const dropDownItems = (item: DeduplicationOptionDto) => {
		return [
			item.isRestart
				? {
						label: (
							<DropdownLink
								title="Перезапустить поиск"
								icon={
									<RiLoopRightLine
										size={16}
										color={
											isRestartDisabled(item.id)
												? colors.disabled
												: colors.primary
										}
									/>
								}
								callback={() => startDeduplicationSearch(item.id)}
								disabled={isRestartDisabled(item.id)}
							/>
						),
						key: '0',
					}
				: null,
			{
				label: <DeleteDeduplicationOption id={item.id} />,
				key: '1',
			},
		];
	};

	const convertOptionsToCollapseItems = (options: DeduplicationOptionDto[]) => {
		return options.map((item) => ({
			key: item.id,
			label: <strong>{item.displayName}</strong>,
			children: <CatalogDeduplicationCondition deduplicationOption={item} />,
			style: panelStyle,
			extra: (
				<Flex align="center" gap={6}>
					<CatalogDeduplicationSearchButton id={item.id} />

					<div onClick={(e) => e.stopPropagation()}>
						<AppDropdown items={dropDownItems(item)} placement="bottomRight" />
					</div>
				</Flex>
			),
		}));
	};

	useEffect(() => {
		setItems(convertOptionsToCollapseItems(deduplicationOptions));
	}, [deduplicationOptions]);

	return {
		loadingDuplicationOptions,
		addingDeduplicationOption,
		items,
		addDeduplicationOption,
	};
};
