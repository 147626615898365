import { notification } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { setCurrentMeasureGroup } from 'entities/metadata/measures';
import {
	MeasurementHttpResponse,
	useLazyGetMeasurementGroup1Query,
} from 'shared/api/generatedApi/measurementGetsApi';
import { useLazyGetSearchResultQuery } from 'shared/api/generatedApi/searchApi';
import { errorHelper } from 'shared/helpers';
import { useAppSelector, useAppDispatch } from 'shared/hooks';
import { useDebounce } from 'shared/hooks/useDebounce';

export const useSearchMeasureUnit = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [getSearchResult, { isFetching: isSearchItemsLoading }] = useLazyGetSearchResultQuery();
	const [getGroup] = useLazyGetMeasurementGroup1Query();

	const searchHandler = useDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > 0) {
			searchParams.set('searchValue', e.target.value);
			setSearchParams(searchParams);
		} else {
			searchParams.delete('searchValue');
			setSearchParams(searchParams);
		}
	}, 1000);

	const { metaMeasureGroupId } = useParams();

	const { currentMeasureGroup } = useAppSelector((state) => state.entities.metadata.measures);

	const dispatch = useAppDispatch();

	const fetchSearch = (searchValue: string) => {
		getSearchResult({
			entityType: 'measurements',
			textRequest: searchValue,
			parentId: metaMeasureGroupId,
		})
			.unwrap()
			.then((res) => {
				const group = {
					...currentMeasureGroup,
					childrenMeasures: res as MeasurementHttpResponse[],
				};
				dispatch(setCurrentMeasureGroup(group));
			})
			.catch((err) => {
				errorHelper('Ошибка при получении списка записей!', err, notification);
			});
	};

	useEffect(() => {
		const searchValue = searchParams.get('searchValue');
		if (searchValue) {
			if (!currentMeasureGroup) {
				getGroup({ id: metaMeasureGroupId })
					.unwrap()
					.then((res) => {
						const group = {
							...res.measurementGroup,
							childrenMeasures: res.measurements,
						};
						dispatch(setCurrentMeasureGroup(group));
						fetchSearch(searchValue);
					})
					.catch((err) =>
						errorHelper('Ошибка при получении группы ЕИ!', err, notification)
					);
			} else {
				fetchSearch(searchValue);
			}
		}
	}, [searchParams.get('searchValue')]);

	return {
		searchHandler,
		searchValue: searchParams.get('searchValue'),
		isLoading: isSearchItemsLoading,
	};
};
