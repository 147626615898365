import { App } from 'antd';
import { deleteCatalogRestrictionTableDeclaration } from 'entities/catalogs/catalogRestrictions';
import { useTransactions } from 'entities/transactions';
import { useDeleteRestrictionTable1Mutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useDeleteCatalogRestrictionTable = () => {
	const [deleteTableDeclaration, { isLoading }] = useDeleteRestrictionTable1Mutation();

	const { notification } = App.useApp();

	const { getTransactions } = useTransactions();

	const dispatch = useAppDispatch();

	const handleDeleteDeclaration = async (declarationId: string) => {
		await deleteTableDeclaration({ body: [{ id: declarationId }] })
			.unwrap()
			.then((res) => {
				if (res.persistedEvents.length > 0) {
					dispatch(deleteCatalogRestrictionTableDeclaration({ id: declarationId }));
					getTransactions();
				}
			})
			.catch((err) =>
				errorHelper('Ошибка удаления таблицы допустимых значений', err, notification)
			);
	};

	return {
		handleDeleteDeclaration,
		isLoading,
	};
};
