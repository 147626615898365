import { App } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import {
	useLazyGetItemsQuery,
	useLazyGetRestrictionTableQuery,
} from 'shared/api/generatedApi/newUdmiApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setConstraintTableInfo } from './constraintTableDetails.store';

export const useConstraintTableDetails = () => {
	const { constraintTableId } = useParams();

	const { notification } = App.useApp();

	const dispatch = useAppDispatch();

	const [fetchRestrictionTable, { isFetching: isTableInfoLoading }] =
		useLazyGetRestrictionTableQuery();

	const [getItems, { isFetching: isItemsLoading }] = useLazyGetItemsQuery();

	const { table, columns } = useAppSelector(
		(state) => state.entities.metadata.constraintTableDetails
	);

	const getRestrictionTable = () => {
		fetchRestrictionTable({
			id: constraintTableId,
		})
			.unwrap()
			.then((res) => {
				dispatch(setConstraintTableInfo(res));
			})
			.catch((err) =>
				errorHelper('Ошибка получения данных ограничительной таблицы!', err, notification)
			);
	};

	const getRelationItems = async (ids: string[]) => {
		return await getItems({ ids })
			.unwrap()
			.then((res) => res.data)
			.catch((err) =>
				errorHelper(
					'Ошибка получения справочных записей атрибута типа "Связь"!',
					err,
					notification
				)
			);
	};

	useEffect(() => {
		if (!table || table.id !== constraintTableId) {
			getRestrictionTable();
		}
	}, [table]);

	useEffect(() => {
		getRestrictionTable();
	}, []);

	return {
		tableInfo: table,
		tableColumns: columns,
		isTableInfoLoading: isTableInfoLoading || isItemsLoading,
		getRelationItems,
	};
};
