import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IUi {
	subMenuCollapsed: boolean;
	hasSider: boolean;
}

const initialState: IUi = {
	subMenuCollapsed: true,
	hasSider: false,
};

const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		setSubMenuCollapsed(state, { payload }: PayloadAction<boolean>) {
			state.subMenuCollapsed = payload;
		},
		addSider(state) {
			state.hasSider = true;
		},
		removeSider(state) {
			state.hasSider = false;
		},
	},
});

export const uiReducer = uiSlice.reducer;
export const { setSubMenuCollapsed, addSider, removeSider } = uiSlice.actions;
