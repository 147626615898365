import { App } from 'antd';
import { useParams } from 'react-router';
import { addCatalogRestrictionTablesDeclarations } from 'entities/catalogs/catalogRestrictions';
import { useTransactions } from 'entities/transactions';
import { JoinRestrictionTableDeclarationDto } from 'shared/api/generatedApi/newUdmiApi';
import { useCreateRestrictionTable1Mutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useAddCatalogRestrictionTable = () => {
	const [addTableDeclaration, { isLoading }] = useCreateRestrictionTable1Mutation();

	const { catalogGroupId } = useParams();

	const { notification } = App.useApp();

	const { getTransactions } = useTransactions();

	const dispatch = useAppDispatch();

	const handleAddTablesDeclarations = async (tableIds: string[]) => {
		await addTableDeclaration({
			body: tableIds.map((id) => ({
				catalogId: catalogGroupId,
				generationValues: true,
				required: true,
				restrictionTableId: id,
			})),
		})
			.unwrap()
			.then((res) => {
				if (res.persistedEvents.length > 0) {
					dispatch(
						addCatalogRestrictionTablesDeclarations(
							res.persistedEvents
								.filter(
									(event) => event.entityType === 'RESTRICTION_TABLE_DECLARATION'
								)
								.map(
									(event) =>
										event.data as unknown as JoinRestrictionTableDeclarationDto
								)
						)
					);
					getTransactions();
				}
			})
			.catch((err) =>
				errorHelper('Ошибка добавления таблиц допустимых значений!', err, notification)
			);
	};

	return {
		handleAddTablesDeclarations,
		isLoading,
	};
};
