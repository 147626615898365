import { RiDeleteBinLine } from '@remixicon/react';
import { Button, Flex, Form, Skeleton, Typography } from 'antd';
import React, { Fragment, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useConstraintTableRow } from 'entities/metadata/constraintTables';
import { AttributeDto } from 'shared/api/generatedApi/newUdmiApi';
import { routes } from 'shared/config';
import { randomString } from 'shared/helpers';
import { CellInputParser } from 'shared/helpers/CellInputParser';
import { CellTypesEnum } from 'shared/helpers/CellValueParser';
import { ItemValuesType, ValueType } from 'shared/helpers/types';
import { DetailFooter } from 'shared/ui';
import { DeleteRowModal } from '../deleteRow';
import { EditRelationAttribute } from '../editRelationAttribute';
import { useEditConstaintTableRow } from './editConstaintTableRow.model';
import { flexStyle } from './EditConstraintTableRowStyles';

export const EditConstraintTableRow: React.FC = () => {
	const { isLoading, tableColumns, tableInfo, currentRow } = useConstraintTableRow();
	const { editInfo, attributeLoading } = useEditConstaintTableRow();

	const { constraintTableId, constraintTableRowId } = useParams();
	const navigate = useNavigate();

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

	const handleDeleteModalClose = () => setIsDeleteModalOpen(false);
	const handleDeleteModalOk = () => {
		setIsDeleteModalOpen(false);
		handleBackClick();
	};
	const handleDeleteModalOpen = () => setIsDeleteModalOpen(true);

	const handleBackClick = () => {
		navigate(
			`/${routes.metadata.main}/${routes.metadata.constraintTables}/${constraintTableId}`
		);
	};

	const findTableCell = useCallback(
		(columnId: string) => {
			if (tableInfo && currentRow) {
				const tableCell: ValueType[] = [];
				Object.keys(currentRow).forEach((attrId) => {
					if (attrId === columnId) tableCell.push(currentRow[attrId]);
				});
				return tableCell;
			}
		},
		[tableInfo, currentRow]
	);

	const handleRenderInput = (value: ItemValuesType, attributeDeclaration: AttributeDto) => {
		if (attributeDeclaration.type === CellTypesEnum.RELATION) {
			return (
				<EditRelationAttribute
					attributeDeclaration={attributeDeclaration}
					editInfo={
						editInfo as unknown as (
							attributeId: string,
							newValue: ItemValuesType[]
						) => Promise<void>
					}
					value={value as unknown as ItemValuesType[]}
				/>
			);
		}

		const valuesArray = Array.isArray(value) ? value.flat(Infinity).filter(Boolean) : [value];

		const valuesWithEmpty = valuesArray[0] !== null ? [...valuesArray, ''] : [...valuesArray];

		return CellInputParser(valuesWithEmpty as ItemValuesType, attributeDeclaration, editInfo);
	};

	return (
		<>
			<Flex vertical gap={12} style={flexStyle}>
				<Typography.Title level={2}>Общая информация</Typography.Title>

				{isLoading ? (
					<>
						<Skeleton.Input active block />
						<Skeleton.Input active block />
						<Skeleton.Input active block />
					</>
				) : (
					tableColumns &&
					tableColumns.length > 0 && (
						<Form layout="vertical">
							{tableColumns.map((col) => {
								const cell = findTableCell(col.id);
								return (
									<Fragment key={randomString(6)}>
										{cell &&
											cell.map((item) => (
												<div key={randomString(6)}>
													<Form.Item label={col.displayName}>
														{attributeLoading === col.id ? (
															<Skeleton.Input active block />
														) : (
															handleRenderInput(item?.value, col)
														)}
													</Form.Item>
												</div>
											))}
									</Fragment>
								);
							})}
						</Form>
					)
				)}
			</Flex>
			<DetailFooter customHandleBack={handleBackClick}>
				<Button
					type="text"
					onClick={handleDeleteModalOpen}
					icon={<RiDeleteBinLine size={18} />}
				>
					Удалить
				</Button>
			</DetailFooter>

			<DeleteRowModal
				onOk={handleDeleteModalOk}
				isOpen={isDeleteModalOpen}
				onClose={handleDeleteModalClose}
				rowsToDelete={[constraintTableRowId]}
			/>
		</>
	);
};
