import { App } from 'antd';
import { useParams } from 'react-router';
import { AttributeGroupItem } from 'entities/metadata/attributesGroups/attributesGroups.model';
import {
	addMetaAttributesGroup,
	updateMetaAttributesGroupChildTreeItem,
} from 'entities/metadata/attributesGroups/attributesGroups.store';
import { useTransactions } from 'entities/transactions';
import { useAddCreateAttributeGroupEvent1Mutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useCreateAttributeGroup = () => {
	const { notification } = App.useApp();

	const [createAttrGroup, { isLoading, error }] = useAddCreateAttributeGroupEvent1Mutation();

	const { metaAttributeGroupId } = useParams();

	const dispatch = useAppDispatch();

	const { getTransactions } = useTransactions();

	const createNewGroup = async (displayName: string, description: string, id?: string) => {
		const data = await createAttrGroup({
			body: [
				{
					displayName,
					description,
					...(id && { parentId: id }),
					...(metaAttributeGroupId && !id && { parentId: metaAttributeGroupId }),
				},
			],
		});
		if ('error' in data) {
			errorHelper('Ошибка при добавлении новой группы атрибутов!', data.error, notification);
		}

		if ('data' in data) {
			getTransactions();

			const newData = {
				...(data.data.persistedEvents[0].data as unknown as AttributeGroupItem),
				key: data.data.persistedEvents[0].data.id as unknown as string,
				isLeaf: !data.data.persistedEvents[0].data.parent,
			};

			if (!metaAttributeGroupId && !id) dispatch(addMetaAttributesGroup(newData));
			if (metaAttributeGroupId || id) {
				dispatch(
					updateMetaAttributesGroupChildTreeItem({
						item: newData,
						parentId: newData.parentId,
					})
				);
			}
		}
	};

	return {
		loading: isLoading,
		error,
		createNewGroup,
	};
};
