import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { App } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { setAttributeDeclarationsList } from 'entities/catalogs/attributeDeclarations/attributeDeclarations.store';
import { ItemStatusDto } from 'entities/catalogs/catalogRecords/catalogRecords.model';
import { ItemValues } from 'entities/catalogs/catalogRecords/catalogRecords.store';
import { useCatalogRestrictionTableDeclarations } from 'entities/catalogs/catalogRestrictions';
import { useTransactions } from 'entities/transactions';
import {
	ItemDto,
	useLazyGetAttributeDeclarations1Query,
	useLazyGetItemQuery,
	useLazyGetItemsQuery,
} from 'shared/api/generatedApi/newUdmiApi';
import { useAddUpdateAttributeDeclarationGroupEventMutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { CellInputParser } from 'shared/helpers/CellInputParser';
import { CellTypesEnum } from 'shared/helpers/CellValueParser';
import { ItemValuesType } from 'shared/helpers/types';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { ChipStatusEnum } from 'shared/ui/components/Chip/chipStylehelper';
import { EditRecordStatus } from '../EditRecordStatus/EditRecordStatus';

export type RecordType = {
	id: string;
	attributeName: string;
	value: string | JSX.Element | JSX.Element[];
	key: string;
};

export interface IRecordDeclarations extends ItemValues {
	status: string;
	[key: string]: ItemValuesType;
}

type Values = {
	[key: string]: {
		attributeName: string;
		value: string;
	};
};

export const useEditCatalogRecord = () => {
	const [recordsList, setRecordsList] = useState<RecordType[]>([]);
	const [record, setRecord] = useState<ItemDto | null>(null);
	const [error, setError] = useState<FetchBaseQueryError | SerializedError>(null);

	const [getRecords, { error: recordsError, isFetching: isRecordsLoading }] =
		useLazyGetItemQuery();

	const [getItems] = useLazyGetItemsQuery();

	const [fetchGetAttributeDeclarations, { isFetching: isDeclarationsLoading }] =
		useLazyGetAttributeDeclarations1Query();
	const [fetchUpdateItem] = useAddUpdateAttributeDeclarationGroupEventMutation();

	const { catalogRestrictionTablesDeclarations } = useCatalogRestrictionTableDeclarations();

	const { recordId, catalogGroupId } = useParams();

	const { notification } = App.useApp();

	const { attributeDeclarationsList } = useAppSelector(
		(state) => state.entities.catalogs.attributeDeclarationsList
	);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!recordsError) setError(null);
	}, [recordsError]);

	const { getTransactions } = useTransactions();

	const mapValues = async (data?: ItemDto) => {
		const flattenedRecords: RecordType[] = await Promise.all(
			Object.keys(data.values as Values)?.map(async (key) => {
				const record = (data.values as Values)[key];
				const attributeDeclaration = attributeDeclarationsList.find(
					(declaration) => declaration.id === key
				);

				const editItem = (declarationId: string, value: ItemValuesType) => {
					const updatedValuesObject = {};
					Object.keys(data.values as Values).forEach((key) => {
						updatedValuesObject[key] = (data.values as Values)[key].value;
					});
					updatedValuesObject[declarationId] = value;
					updateItem(updatedValuesObject, data.status).then(() => {
						setRecord((prev) => ({
							...prev,
							values: {
								...prev.values,
								[declarationId]: {
									...prev.values[declarationId],
									value,
								},
							},
						}));
					});
				};

				let relationOptions: DefaultOptionType[] = [];

				const restrictionDeclaration = catalogRestrictionTablesDeclarations?.find(
					(restriction) => {
						return restriction.restrictionTable.attributeIds.includes(
							attributeDeclaration.attribute.id
						);
					}
				);

				if (restrictionDeclaration && restrictionDeclaration.restrictionTable) {
					relationOptions = restrictionDeclaration.restrictionTable.attributeValues?.map(
						(valueObj) => {
							const attributeValue = valueObj[
								attributeDeclaration.attribute.id
							] as any;
							return {
								label: attributeValue?.value || '<Значение не задано>',
								value: attributeValue?.value || '',
							};
						}
					);
				}

				if (
					attributeDeclaration.attribute.type === CellTypesEnum.RELATION &&
					attributeDeclaration.restrictions.scopeId
				) {
					const itemsResult: ItemDto[] = [];
					const itemsPromises = (
						attributeDeclaration.restrictions.scopeId as string[]
					).map((id) => {
						return getItems({ catalogId: id })
							.unwrap()
							.then((res) => {
								itemsResult.push(...res.data);
							})
							.catch((err) => {
								errorHelper(
									'Ошибка получения записей справочников для атрибута с типом "Связь"!',
									err,
									notification
								);
								return new Error();
							});
					});
					await Promise.all(itemsPromises).then(() => {
						relationOptions = itemsResult.map((item) => ({
							label: item.displayName || '<Название не заполнено>',
							value: item.id,
						}));
					});
				}

				return {
					id: key,
					attributeName: record.attributeName,
					value: CellInputParser(
						record.value,
						attributeDeclaration,
						editItem,
						relationOptions
					),
					key: key,
				};
			})
		);

		const onStatusChange = (status: ChipStatusEnum) => {
			const updatedValuesObject = {};
			Object.keys(data.values as Values).forEach((key) => {
				updatedValuesObject[key] = (data.values as Values)[key].value;
			});
			updateItem(updatedValuesObject, status.toUpperCase() as ItemStatusDto).then(() => {
				setRecord((prev) => ({
					...prev,
					status: status.toUpperCase() as ItemStatusDto,
				}));
			});
		};

		flattenedRecords.unshift({
			attributeName: 'Статус позиции',
			id: 'status',
			key: 'status',
			value: <EditRecordStatus data={data} onChange={onStatusChange} />,
		});
		setRecordsList(flattenedRecords);
	};

	const updateItem = async (values: { [key: string]: object }, status: ItemStatusDto) => {
		fetchUpdateItem({
			body: [
				{
					catalogId: catalogGroupId,
					id: recordId,
					status,
					values,
				},
			],
		})
			.unwrap()
			.then(() => getTransactions())
			.catch((err) => {
				errorHelper('Ошибка при редактировании записи', err, notification);
			});
	};

	useEffect(() => {
		if (record) {
			mapValues(record);
		}
	}, [record]);

	useEffect(() => {
		if (!attributeDeclarationsList) {
			fetchGetAttributeDeclarations({ catalogId: catalogGroupId })
				.unwrap()
				.then((res) => {
					dispatch(setAttributeDeclarationsList(res));
				})
				.catch((err) => {
					errorHelper('Ошибка при получении списка атрибутов', err, notification);
				});
		} else {
			const getData = async () => {
				const data = await getRecords({ id: recordId });
				if ('error' in data) {
					errorHelper('Ошибка при получении списка атрибутов', data.error, notification);
				}
				if ('data' in data) {
					setRecord(data.data);
				}
			};

			getData();
		}
	}, [attributeDeclarationsList]);

	return {
		recordsList,
		attributeDeclarations: attributeDeclarationsList,
		loading: isRecordsLoading || isDeclarationsLoading,
		error,
	};
};
