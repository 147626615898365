import { App } from 'antd';
import { editClassifierAttributeDeclaration } from 'entities/classifiers/classifierAttributeDeclarations/attributeDeclarations.store';
import { ClassifierAttributeDeclarationDto } from 'shared/api/generatedApi/newUdmiApi';
import { useAddUpdateEvent4Mutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { ItemValues } from 'shared/helpers/types';
import { useAppDispatch } from 'shared/hooks';

export const useSetupClassifierAttribute = () => {
	const [addUpdateEvent4, { isLoading }] = useAddUpdateEvent4Mutation();
	const dispatch = useAppDispatch();
	const { notification } = App.useApp();

	const editData = async (classifier: ClassifierAttributeDeclarationDto, v: ItemValues) => {
		await addUpdateEvent4({
			body: [
				{
					id: classifier.id,
					classifierId: classifier.classifierId,
					attributeId: classifier.attribute.id,
					inherited: classifier.inherited,
					restrictions: { ...(v as unknown as object) },
				},
			],
		})
			.unwrap()
			.then((res) => {
				dispatch(
					editClassifierAttributeDeclaration({
						...classifier,
						restrictions: {
							...classifier.restrictions,
							...res.persistedEvents[0].data.restrictions,
						},
					})
				);
			})
			.catch((e) => {
				errorHelper(
					'Ошибка при редактировании декларации атрибута классификатора!',
					e,
					notification
				);
			});
	};

	return {
		editData,
		isLoading,
	};
};
