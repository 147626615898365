import { App } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router';
import { setConstraintTableInfo } from 'entities/metadata/constraintTables';
import { useTransactions } from 'entities/transactions';
import { RestrictionTableDto } from 'shared/api/generatedApi/newUdmiApi';
import {
	UpdateAttributeValueRequest,
	useUpdateRestrictionTableAttributeValueMutation,
} from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { ItemValuesType, Values } from 'shared/helpers/types';
import { useAppDispatch, useAppSelector } from 'shared/hooks';

export const useEditConstaintTableRow = () => {
	const { notification } = App.useApp();

	const [patchRowValues] = useUpdateRestrictionTableAttributeValueMutation();

	const [loading, setLoading] = useState<string | null>();

	const { constraintTableId, constraintTableRowId } = useParams();

	const { table } = useAppSelector((state) => state.entities.metadata.constraintTableDetails);

	const { getTransactions } = useTransactions();

	const dispatch = useAppDispatch();

	const editInfo = async (attributeId: string, newValue: ItemValuesType) => {
		setLoading(attributeId);
		const updateObject = {
			restrictionTableId: constraintTableId,
			value: {
				id: constraintTableRowId,
			},
		};
		table.attributeValues.forEach((row) => {
			if ((row.id as unknown as string) === constraintTableRowId) {
				Object.keys(row).forEach((key) => {
					if (key !== 'id') {
						updateObject.value[key] = {
							attributeName: (row as Values)[key].attributeName,
							value: (row as Values)[key].value,
							...(Array.isArray(row) && { value: (row as Values)[key].value }),
						};
					}
				});
			}
		});
		if (updateObject.value[attributeId].value === newValue) return;
		updateObject.value[attributeId].value = newValue;
		patchRowValues({
			body: [updateObject as unknown as UpdateAttributeValueRequest],
		})
			.unwrap()
			.then((res) => {
				if (res.persistedEvents.length > 0) {
					dispatch(
						setConstraintTableInfo(
							res.persistedEvents[0].data as unknown as RestrictionTableDto
						)
					);
					getTransactions();
				}
			})
			.catch((err) =>
				errorHelper(
					'Ошибка редактирования значения атрибута ограничительной таблицы!',
					err,
					notification
				)
			)
			.finally(() => setLoading(null));
	};

	return {
		editInfo,
		attributeLoading: loading,
	};
};
