import { App } from 'antd';
import { renameConstraintTable } from 'entities/metadata/constraintTables';
import { useTransactions } from 'entities/transactions';
import { useLazyGetRestrictionTableQuery } from 'shared/api/generatedApi/newUdmiApi';
import { useUpdateRestrictionTableMutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useEditConstraintTable = () => {
	const [patchTableInfo, { isLoading: isPatchTableInfoLoading }] =
		useUpdateRestrictionTableMutation();
	const [fetchConstraintTable, { isFetching: isGetConstraintTableLoading }] =
		useLazyGetRestrictionTableQuery();

	const { notification } = App.useApp();

	const { getTransactions } = useTransactions();

	const dispatch = useAppDispatch();

	const handleEditConstraintTable = async (
		tableId: string,
		tableName: string,
		tableDescr?: string
	) => {
		await fetchConstraintTable({
			id: tableId,
		})
			.unwrap()
			.then(async (res) => {
				await patchTableInfo({
					body: [
						{
							...res,
							displayName: tableName,
							description: tableDescr,
						},
					],
				})
					.unwrap()
					.then((res) => {
						if (res.persistedEvents.length > 0) {
							getTransactions();
							dispatch(
								renameConstraintTable({
									displayName: tableName,
									id: tableId,
									description: tableDescr,
								})
							);
						}
					})
					.catch((err) =>
						errorHelper(
							'Ошибка редактирования ограничительной таблицы!',
							err,
							notification
						)
					);
			})
			.catch((err) =>
				errorHelper('Ошибка получения данных ограничительной таблицы!', err, notification)
			);
	};

	return {
		isLoading: isGetConstraintTableLoading || isPatchTableInfoLoading,
		handleEditConstraintTable,
	};
};
