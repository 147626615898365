import { RiFolderSettingsLine } from '@remixicon/react';
import {
	Button,
	ColorPicker,
	DatePicker,
	Flex,
	Form,
	Input,
	Modal,
	Radio,
	TreeSelectProps,
	Typography,
} from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useCatalogGroups } from 'entities/catalogs/catalogGroups/catalog.model';
import { useCatalogRecords } from 'entities/catalogs/catalogRecords';
import { useGetClassifiersGroups } from 'entities/classifiers/classifiersGroups/classifier.model';
import { useGetCurrentMeausureGroup } from 'entities/metadata/measures';
import {
	AttributeDeclarationDto,
	ItemDto,
	useLazyGetClassifierItemsQuery,
} from 'shared/api/generatedApi/newUdmiApi';
import { isEmpty } from 'shared/helpers';
import { translateType } from 'shared/helpers/CellTypeParser';
import { CellTypesEnum } from 'shared/helpers/CellValueParser';
import { colors } from 'shared/styles';
import { DropdownLink, DropdownSelect } from 'shared/ui';
import { DropdownTreeSelect } from 'shared/ui/components/AppTreeSelect';
import { useSetupAttribute } from './SetupAttribute.model';

interface ISetupAttribute {
	attribute: AttributeDeclarationDto;
}

const boolOptions = [
	{ label: 'Правда', value: true },
	{ label: 'Ложь', value: false },
];

const SetupAttributeUi: React.FC<ISetupAttribute> = ({ attribute }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [linkData, setLinkData] = useState<Omit<DefaultOptionType, 'label'>[]>([]);
	const [scopeIdsList, setScopeIdsList] = useState<Omit<DefaultOptionType, 'label'>[]>([]);
	const [selectedScopeIds, setSelectedScopeIds] = useState<Array<string>>(null);

	const [form] = Form.useForm();

	const [getItemsList, { error: itemsListError, isLoading: itemsListLoading }] =
		useLazyGetClassifierItemsQuery();

	const {
		currentMeasureGroup,
		getData,
		loading: measuresLoading,
		error: measuresError,
	} = useGetCurrentMeausureGroup();

	const {
		catalogList,
		getCatalogs,
		error: catalogListErr,
		loading: catalogListLoading,
	} = useCatalogGroups();

	const { fetchItems, isGetItemsLoading } = useCatalogRecords();

	const {
		сlassifiersGroups,
		loading: classifierGroupsLoading,
		error: catalogError,
	} = useGetClassifiersGroups();

	const { editData, isLoading } = useSetupAttribute();

	const showModal = () => {
		setIsModalOpen(true);
	};

	const onFinish = async (values) => {
		const v = values.defaultValue;

		await editData(attribute, {
			...values,
			...(attribute.attribute.type === CellTypesEnum.COLOUR && {
				defaultValue: `#${v}`,
			}),
			...(attribute.attribute.type === CellTypesEnum.DATETIME && {
				defaultValue: dayjs(values.defaultValue).format(),
			}),
			...(attribute.attribute.type === CellTypesEnum.INT && {
				defaultValue: Number(v),
			}),
			...(attribute.attribute.type === CellTypesEnum.FLOAT && {
				defaultValue: parseFloat(v),
			}),
			...(attribute.attribute.type === CellTypesEnum.RELATION && {
				defaultValue: v?.map((item) => item.value),
				scopeId: selectedScopeIds,
			}),
		});
		setIsModalOpen(false);
	};

	const onLoadData: TreeSelectProps['loadData'] = ({ id }) => {
		return getItemsList({
			parentItemId: id,
		})
			.unwrap()
			.then((res) => {
				setLinkData((prev) => {
					return [
						...prev,
						...res.data.map((c) => ({
							id: c.id,
							pId: c.parentItemId,
							value: c.id,
							title: c.displayName || '<без названия>',
							isLeaf: !c.parent,
						})),
					];
				});
			});
	};

	const onLoadScopeIds: TreeSelectProps['loadData'] = ({ id }) => {
		return getCatalogs(id).then((res) => {
			setScopeIdsList((prev) => {
				return [
					...prev,
					...res.map((c) => ({
						id: c.id,
						pId: c.parentId,
						value: c.id,
						title: c.displayName || '<без названия>',
						isLeaf: !c.parent,
					})),
				];
			});
		});
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleChange = (value: Array<string>) => {
		setSelectedScopeIds(value);
	};

	useEffect(() => {
		form.setFieldsValue(attribute.restrictions);
		const v = attribute?.attribute?.restrictions?.defaultValue as unknown;
		if (attribute.attribute.type === CellTypesEnum.DATETIME && v !== null) {
			form.setFieldValue(
				'defaultValue',
				dayjs(
					new Date(attribute?.attribute?.restrictions?.defaultValue as unknown as string)
				)
			);
		}

		if (attribute.attribute.type === CellTypesEnum.COLOUR && v !== null) {
			form.setFieldValue('defaultValue', { v });
		}

		if (
			'scopeId' in attribute.attribute.restrictions &&
			(attribute.attribute.restrictions.scopeId as Array<string>)?.length
		) {
			if (сlassifiersGroups) {
				const classifier = сlassifiersGroups.find(
					(item) => item.id === attribute.attribute.restrictions.scopeId[0]
				);
				form.setFieldValue('scopeId', {
					label: classifier.displayName,
					value: classifier.id,
				});
			}
			getItemsList({
				classifierId: attribute.attribute.restrictions.scopeId[0],
				root: true,
			})
				.unwrap()
				.then((res) => {
					const options = res.data.map((c) => ({
						id: c.id,
						pId: c.parentItemId,
						value: c.id,
						title: c.displayName || '<без названия>',
						isLeaf: !c.parent,
					}));
					setLinkData(options);

					if (
						attribute.restrictions.defaultValue &&
						Array.isArray(attribute.restrictions.defaultValue)
					) {
						form.setFieldValue(
							'defaultValue',
							options.filter((opt) =>
								(attribute.restrictions.defaultValue as Array<string>).includes(
									opt.id
								)
							)
						);
					} else {
						form.setFieldValue('defaultValue', []);
					}
				});
		} else {
			if (isEmpty(scopeIdsList)) {
				setScopeIdsList(
					catalogList.map((item) => ({
						id: item.id,
						pId: item.parentId,
						value: item.id,
						title: item.displayName,
						isLeaf: !item.parent,
					}))
				);
				if (attribute.restrictions.scopeId && catalogList) {
					setSelectedScopeIds(attribute.restrictions.scopeId as Array<string>);
					form.setFieldValue(
						'scopeId',
						catalogList
							.filter(
								(item) =>
									(attribute.restrictions.scopeId as Array<string>).includes(
										item.id
									) && !!item
							)
							.map((item) => ({
								id: item.id,
								pId: item.parentId,
								value: item.id,
								title: item.displayName,
								isLeaf: !item.parent,
							}))
					);
				} else {
					form.setFieldValue('scopeId', []);
				}
			}
		}
	}, [attribute, сlassifiersGroups, catalogList]);

	useEffect(() => {
		if (selectedScopeIds) {
			if (attribute.attribute.restrictions.scopeId) {
				const promises = selectedScopeIds?.map((item) =>
					getItemsList({ classifierId: item, root: true })
				);
				Promise.all(promises).then((res) => {
					const data = res.flat(Infinity);

					setLinkData(
						data
							.map((v) =>
								v.data.data.map((c) => ({
									id: c.id,
									pId: c.parentItemId,
									value: c.id,
									title: c.displayName,
									isLeaf: !c.parent,
								}))
							)
							.flat()
					);
				});
			} else {
				const promises = selectedScopeIds?.map((item) => fetchItems(item));
				Promise.all(promises).then((res) => {
					const data = res.flat(Infinity);

					const validData = data.filter(
						(item): item is ItemDto => !(item instanceof Error)
					);

					setLinkData(
						validData.map((c) => ({
							id: c.id,
							value: c.id,
							title: c.displayName || '<без названия>',
							isLeaf: true,
						}))
					);
				});
			}
		}
	}, [selectedScopeIds]);

	useEffect(() => {
		if (
			attribute.attribute.type === CellTypesEnum.FLOAT &&
			!currentMeasureGroup &&
			attribute.attribute.measurementGroupId
		)
			getData(attribute.attribute.measurementGroupId);
	}, [currentMeasureGroup, attribute]);

	return (
		<>
			<DropdownLink
				icon={<RiFolderSettingsLine size={16} />}
				title="Настроить декларацию атрибута"
				callback={showModal}
			/>

			<Modal
				title={
					<Typography.Title level={1}>
						<Flex gap={4} justify="flex-start" vertical>
							Управление декларацией атрибута:
							<span style={{ color: colors.primary }}>
								{translateType(attribute?.attribute?.type, 'AttributeTransfer')}
							</span>
						</Flex>
					</Typography.Title>
				}
				open={isModalOpen}
				footer={null}
				closable={false}
			>
				<Form layout="vertical" form={form} onFinish={onFinish}>
					{attribute.attribute.type === CellTypesEnum.STRING && (
						<>
							<Form.Item label="Маска ввода" name="mask">
								<Input />
							</Form.Item>
							<Form.Item label="Максимальная длина" name="maxLength">
								<Input />
							</Form.Item>
						</>
					)}
					{(attribute.attribute.type === CellTypesEnum.STRING ||
						attribute.attribute.type === CellTypesEnum.TEXT) && (
						<Form.Item label="Значение по умолчанию" name="defaultValue">
							<Input />
						</Form.Item>
					)}
					{attribute.attribute.type === CellTypesEnum.RELATION && (
						<Form.Item label="Значение по умолчанию" name="defaultValue">
							<DropdownTreeSelect
								info={
									!(attribute.restrictions.scopeId as string[])?.length &&
									'Для выбора значения по умолчанию, пожалуйста, заполните поле "Область"'
								}
								treeCheckStrictly
								treeDataSimpleMode
								loading={itemsListLoading || isGetItemsLoading}
								loadData={onLoadData}
								errorText={
									itemsListError || itemsListError
										? 'Не удалось загрузить список записей классификаторов!'
										: ''
								}
								treeData={linkData}
								error={!!itemsListError || !!itemsListError}
							/>
						</Form.Item>
					)}
					{(attribute.attribute.type === CellTypesEnum.FLOAT ||
						attribute.attribute.type === CellTypesEnum.INT) && (
						<Form.Item label="Значение по умолчанию" name="defaultValue">
							<Input type="number" />
						</Form.Item>
					)}

					{attribute.attribute.type === CellTypesEnum.FLOAT && (
						<>
							<Form.Item label="Точность" name="accuracy">
								<Input />
							</Form.Item>
							<Form.Item label="Единица измерения" name="measurmentId">
								<DropdownSelect
									loading={measuresLoading}
									error={
										!!measuresError || !attribute?.attribute?.measurementGroupId
									}
									errorText={
										!attribute?.attribute?.measurementGroupId
											? 'У атрибута нет назначенной группы единиц измерения!'
											: 'Не удалось загрузить список единиц измерения!'
									}
									disabled={!!measuresError}
									options={currentMeasureGroup?.childrenMeasures?.map(
										(measure) => ({
											label: measure.displayName,
											value: measure.id,
										})
									)}
								/>
							</Form.Item>
						</>
					)}
					{attribute.attribute.type === CellTypesEnum.BOOLEAN && (
						<Form.Item
							label="Значение по умолчанию"
							name="defaultValue"
							initialValue={boolOptions[0]}
							valuePropName="checked"
						>
							<Radio.Group options={boolOptions} />
						</Form.Item>
					)}
					{attribute.attribute.type === CellTypesEnum.DATETIME && (
						<Form.Item label="Значение по умолчанию" name="defaultValue">
							<DatePicker />
						</Form.Item>
					)}
					{attribute.attribute.type === CellTypesEnum.RELATION &&
						(attribute.attribute.restrictions.scopeId as unknown as Array<string>)
							?.length && (
							<Form.Item label="Область" name="scopeId">
								<DropdownSelect
									mode="multiple"
									options={
										!catalogError && attribute.attribute.restrictions.scopeId
											? сlassifiersGroups?.map((item) => ({
													label: item.displayName,
													value: item.id,
												}))
											: catalogList.map((item) => ({
													label: item.displayName,
													value: item.id,
												}))
									}
									onChange={handleChange}
									loading={classifierGroupsLoading || catalogListLoading}
									error={!!catalogError}
									disabled={
										!!(
											attribute.attribute.restrictions
												.scopeId as unknown as Array<string>
										)?.length
									}
								/>
							</Form.Item>
						)}
					{attribute.attribute.type === CellTypesEnum.RELATION &&
						!(attribute.attribute.restrictions.scopeId as unknown as Array<string>) && (
							<Form.Item label="Область" name="scopeId">
								<DropdownTreeSelect
									treeDataSimpleMode
									treeCheckStrictly
									loading={catalogListLoading}
									loadData={onLoadScopeIds}
									onChange={(v) => {
										setSelectedScopeIds(v.map((item) => item.value));
									}}
									errorText={
										catalogListErr
											? 'Не удалось загрузить список справочников!'
											: ''
									}
									treeData={scopeIdsList}
									error={!!catalogListErr}
								/>
							</Form.Item>
						)}
					{attribute.attribute.type === CellTypesEnum.COLOUR && (
						<Form.Item
							label="Значение по умолчанию"
							name="defaultValue"
							getValueFromEvent={(color) => {
								return color.toHex();
							}}
						>
							<ColorPicker size="small" showText allowClear format="hex" />
						</Form.Item>
					)}

					<Flex justify="flex-end" gap={4}>
						<Button type="text" onClick={handleCancel}>
							Отменить
						</Button>
						<Button type="primary" htmlType="submit" loading={isLoading}>
							Сохранить
						</Button>
					</Flex>
				</Form>
			</Modal>
		</>
	);
};

export const SetupAttribute = React.memo(SetupAttributeUi);
