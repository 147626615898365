import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { App, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
	useLazyGetAllMeasurementGroupsQuery,
	useLazyGetMeasurementGroup1Query,
	useLazyGetMeasurementGroupQuery,
} from 'shared/api/generatedApi/measurementGetsApi';
import { errorHelper } from 'shared/helpers';
import { useAppSelector, useAppDispatch } from 'shared/hooks';
import { useDidUpdateEffect } from 'shared/hooks/useDidUpdateEffect';
import {
	editMeasuresList,
	setCurrentMeasureGroup,
	setCurrentMeasureUnit,
	setMeasuresList,
} from './measures.store';

export const useGetMeasuresGroup = () => {
	const [error, setError] = useState<FetchBaseQueryError | SerializedError>(null);

	const [getGroups, { isFetching: isLoading }] = useLazyGetAllMeasurementGroupsQuery();
	const { notification } = App.useApp();
	const { measuresList } = useAppSelector((state) => state.entities.metadata.measures);
	const dispatch = useAppDispatch();

	const [searchParams] = useSearchParams();

	const getData = async () => {
		const data = await getGroups();
		if ('error' in data) {
			errorHelper('Ошибка при получении списка групп ЕИ!', data.error, notification);
			setError(data.error);
		}
		if ('data' in data) {
			dispatch(setMeasuresList(data.data));
		}
	};

	useEffect(() => {
		if (!measuresList) {
			const searchValue = searchParams.get('searchValue');
			!searchValue && getData();
		}
	}, []);

	useDidUpdateEffect(() => {
		const searchValue = searchParams.get('searchValue');
		!searchValue && getData();
	}, [searchParams.get('searchValue')]);

	return {
		loading: isLoading,
		error,
		measuresList,
	};
};

export const useGetCurrentMeausureGroup = () => {
	const [error, setError] = useState<FetchBaseQueryError | SerializedError>(null);
	const { metaMeasureGroupId } = useParams();
	const { loading: groupLoading, error: groupError, measuresList } = useGetMeasuresGroup();
	const dispatch = useAppDispatch();
	const { currentMeasureGroup } = useAppSelector((state) => state.entities.metadata.measures);
	const [getGroup, { isLoading }] = useLazyGetMeasurementGroup1Query();

	const [searchParams] = useSearchParams();

	const getData = async (id?: string) => {
		const data = await getGroup({ id: id ? id : metaMeasureGroupId, includeChildren: true });
		if ('error' in data) {
			errorHelper('Ошибка при получении группы ЕИ!', data.error, notification);
			setError(data.error);
		}
		if ('data' in data) {
			const group = {
				...data.data.measurementGroup,
				childrenMeasures: data.data.measurements,
			};
			dispatch(setCurrentMeasureGroup(group));
			dispatch(editMeasuresList(group));
			return group;
		}
	};

	useEffect(() => {
		const searchValue = searchParams.get('searchValue');
		if (!currentMeasureGroup && !searchValue) {
			if (metaMeasureGroupId && measuresList) {
				const currGroup = measuresList.find((item) => item.id === metaMeasureGroupId);
				if (currGroup?.childrenMeasures) {
					dispatch(setCurrentMeasureGroup(currGroup));
				} else {
					getData();
				}
			}
		}
	}, [metaMeasureGroupId, measuresList, currentMeasureGroup]);

	useDidUpdateEffect(() => {
		const searchValue = searchParams.get('searchValue');
		!searchValue && getData();
	}, [searchParams.get('searchValue')]);

	return {
		currentMeasureGroup,
		getData,
		loading: groupLoading || isLoading,
		error: groupError || error,
	};
};

export const useGetCurrentMeasureUnit = () => {
	const { currentMeasureUnit } = useAppSelector((state) => state.entities.metadata.measures);
	const dispatch = useAppDispatch();
	const { metaMeasureUnitId } = useParams();
	const [getMeasure, { isLoading, error }] = useLazyGetMeasurementGroupQuery();

	useEffect(() => {
		getMeasure({ id: metaMeasureUnitId })
			.unwrap()
			.then((res) => {
				dispatch(setCurrentMeasureUnit(res));
			})
			.catch((e) => {
				errorHelper(
					'Ошибка при получении информации о единице измерения!',
					e,
					notification
				);
			});
	}, [metaMeasureUnitId]);

	return {
		currentMeasureUnit,
		loading: isLoading,
		error,
	};
};
