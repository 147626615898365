import { App, TreeDataNode } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
	ClassifierItemDto,
	ClassifierItemTreeNode,
	useLazyGetClassifierItemsQuery,
	useLazyGetClassifierItemTreeQuery,
} from 'shared/api/generatedApi/newUdmiApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import {
	removeCurrentClassifierTreeItem,
	setClassifiersItemsTree,
	setCurrentClassifierTreeItem,
	updateClassifierTreeItem,
} from './classifiersItemsTree.store';

export type ClassifierItem = ClassifierItemTreeNode &
	TreeDataNode & {
		key: React.Key;
		title: string;
		children?: Array<ClassifierItem>;
	};

export type DisplayTreeNode = Omit<ClassifierItem, 'title'> & { title: React.ReactNode };

export const useClassifierItemsTreeHook = () => {
	const [selectedClassifierTreeItem, setSelectedClassifierTreeItem] =
		useState<ClassifierItemDto>(null);
	const [classifiersTree, setClassifiersTree] = useState<Array<ClassifierItemDto>>([]);

	const [getItemsList, { isLoading: treeItemsLoading, error: itemsListError }] =
		useLazyGetClassifierItemsQuery();
	const [
		getClassifierWithParentsById,
		{ isLoading: treeItemWithParentLoading, error: classifierWithParentsError },
	] = useLazyGetClassifierItemTreeQuery();

	const { notification } = App.useApp();

	const { classifierItemId, classifierGroupId } = useParams();

	const dispatch = useAppDispatch();
	const { classifiersItemsTree, currentClassifierTreeItem } = useAppSelector(
		(state) => state.entities.classifiers.classifiersItemsTree
	);

	const addNewItemToList = (
		arr: Array<ClassifierItemDto>,
		group: ClassifierItemDto
	): Array<ClassifierItemDto> =>
		arr.map((item) => {
			return item.id === group.id ? group : item;
		});

	const findChild = (
		arr: Array<ClassifierItemTreeNode>,
		id: string
	): ClassifierItemTreeNode | undefined => {
		if (arr) {
			for (const attr of arr) {
				if (attr.id === id) {
					return attr;
				}
				if (attr.children && attr.children.length) {
					const child = findChild(attr.children, id);
					if (child) {
						return child;
					}
				}
			}
		}

		return undefined;
	};

	const getClassifiersTreeItem = async (
		parentItemId?: string
	): Promise<ClassifierItemTreeNode | null> => {
		return await getClassifierWithParentsById({
			id: parentItemId,
			includeChildren: true,
			direction: 'down',
		})
			.unwrap()
			.then((res) => {
				dispatch(updateClassifierTreeItem(res));
				return res;
			})
			.catch((e) => {
				errorHelper('Ошибка при получении вложженной записи!', e, notification);
				return null;
			});
	};

	const getClassifiersItemsTree = async () => {
		const list = await getItemsList({ root: true, classifierId: classifierGroupId });
		if ('error' in list) {
			errorHelper(
				'Ошибка при получении корневой группы справочников!',
				list.error,
				notification
			);
		}

		if ('data' in list) {
			if (list.data.data.length) {
				if (classifierItemId) {
					const classifier = await getClassifierWithParentsById({
						id: classifierItemId,
						direction: 'up',
						includeChildren: true,
					});
					if ('error' in classifier) {
						errorHelper(
							'Ошибка при получении справочников!',
							classifier.error,
							notification
						);
					}
					if ('data' in classifier) {
						const completeData = addNewItemToList(list.data.data, classifier.data);
						dispatch(setClassifiersItemsTree(completeData));
						dispatch(
							setCurrentClassifierTreeItem(
								findChild([classifier.data], classifierItemId)
							)
						);
						return;
					}
				}
				dispatch(setClassifiersItemsTree(list.data.data));
				dispatch(setCurrentClassifierTreeItem(findChild(list.data.data, classifierItemId)));
			}
		}
		dispatch(setClassifiersItemsTree(list.data.data));
	};

	useEffect(() => {
		if (!classifiersItemsTree) {
			getClassifiersItemsTree();
		}
	}, []);

	useEffect(() => {
		if (classifiersItemsTree) setClassifiersTree(classifiersItemsTree);
	}, [classifiersItemsTree]);

	useEffect(() => {
		setSelectedClassifierTreeItem(currentClassifierTreeItem);
	}, [currentClassifierTreeItem]);

	useEffect(() => {
		if (!classifierItemId && currentClassifierTreeItem)
			dispatch(removeCurrentClassifierTreeItem());
		if (classifierItemId)
			dispatch(
				setCurrentClassifierTreeItem(findChild(classifiersItemsTree, classifierItemId))
			);
	}, [classifierItemId]);

	return {
		selectedClassifierTreeItem,
		classifiersTree,
		getClassifiersTreeItem,
		error: itemsListError,
		classifierWithParentsError,
		loading: treeItemsLoading || treeItemWithParentLoading,
		findChild,
	};
};
