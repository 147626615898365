import { measurementPatchApi as api } from './newCreateApiFile';
const injectedRtkApi = api.injectEndpoints({
	endpoints: (build) => ({
		convert: build.mutation<ConvertApiResponse, ConvertApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/measurements/conversion',
				method: 'POST',
				body: queryArg.convertValueRequest,
			}),
		}),
		addCreateEvent: build.mutation<AddCreateEventApiResponse, AddCreateEventApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/measurements',
				method: 'POST',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addDeleteEvent: build.mutation<AddDeleteEventApiResponse, AddDeleteEventApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/measurements',
				method: 'DELETE',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addUpdateEvent: build.mutation<AddUpdateEventApiResponse, AddUpdateEventApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/measurements',
				method: 'PATCH',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addCreateEvent1: build.mutation<AddCreateEvent1ApiResponse, AddCreateEvent1ApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/measurement-groups',
				method: 'POST',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addDeleteEvent1: build.mutation<AddDeleteEvent1ApiResponse, AddDeleteEvent1ApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/measurement-groups',
				method: 'DELETE',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addUpdateEvent1: build.mutation<AddUpdateEvent1ApiResponse, AddUpdateEvent1ApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/measurement-groups',
				method: 'PATCH',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
	}),
	overrideExisting: false,
});
export { injectedRtkApi as measurementPatchApi };
export type ConvertApiResponse = /** status 200 OK */ ConvertValueResponse;
export type ConvertApiArg = {
	convertValueRequest: ConvertValueRequest;
};
export type AddCreateEventApiResponse = /** status 200 OK */ AddEventToTransactionResponse;
export type AddCreateEventApiArg = {
	authorization?: string;
	body: CreateMeasurementRequest[];
};
export type AddDeleteEventApiResponse =
	/** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type AddDeleteEventApiArg = {
	authorization?: string;
	body: DeleteEntityRequest[];
};
export type AddUpdateEventApiResponse = /** status 200 OK */ AddEventToTransactionResponse;
export type AddUpdateEventApiArg = {
	authorization?: string;
	body: UpdateMeasurementRequest[];
};
export type AddCreateEvent1ApiResponse = /** status 200 OK */ AddEventToTransactionResponse;
export type AddCreateEvent1ApiArg = {
	authorization?: string;
	body: CreateMeasurementGroupRequest[];
};
export type AddDeleteEvent1ApiResponse =
	/** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type AddDeleteEvent1ApiArg = {
	authorization?: string;
	body: DeleteEntityRequest[];
};
export type AddUpdateEvent1ApiResponse = /** status 200 OK */ AddEventToTransactionResponse;
export type AddUpdateEvent1ApiArg = {
	authorization?: string;
	body: UpdateMeasurementGroupRequest[];
};
export type ConvertValueResponse = {
	convertedValue?: object;
	message?: string;
};
export type ConvertValueRequest = {
	value: object;
	fromMeasurementId: string;
	toMeasurementId: string;
};
export type EventResponse = {
	id: string;
	entityId: string;
	entityType:
		| 'CATALOG'
		| 'ATTRIBUTE'
		| 'ATTRIBUTE_GROUP'
		| 'ATTRIBUTE_DECLARATION'
		| 'EXCHANGE_CLASS'
		| 'EXCHANGE_KEY'
		| 'ITEM'
		| 'MEASUREMENT'
		| 'MEASUREMENT_GROUP'
		| 'RESTRICTION_TABLE_DECLARATION'
		| 'RESTRICTION_TABLE'
		| 'FORMULA';
	eventType: 'CREATE' | 'UPDATE' | 'DELETE';
	userId: string;
	parentEventId?: string;
	transactionId: string;
	createdAt: string;
	oldData: {
		[key: string]: object;
	};
	data: {
		[key: string]: object;
	};
	message?: string;
};
export type BlockingEventResponse = {
	entityId: string;
	notification?: string;
};
export type AddEventToTransactionResponse = {
	persistedEvents: EventResponse[];
	blockedEvents: BlockingEventResponse[];
};
export type CreateMeasurementRequest = {
	displayName: string;
	shortName?: string;
	isDefault: boolean;
	measurementGroupId: string;
	formula?: string;
	inverseFormula?: string;
	coefficient?: number;
};
export type DeleteEntityRequest = {
	id: string;
};
export type UpdateMeasurementRequest = {
	id: string;
	displayName: string;
	shortName?: string;
	isDefault: boolean;
	measurementGroupId: string;
	formula?: string;
	inverseFormula?: string;
	coefficient?: number;
};
export type CreateMeasurementGroupRequest = {
	displayName: string;
	description?: string;
};
export type UpdateMeasurementGroupRequest = {
	id: string;
	displayName: string;
	description?: string;
};
export const {
	useConvertMutation,
	useAddCreateEventMutation,
	useAddDeleteEventMutation,
	useAddUpdateEventMutation,
	useAddCreateEvent1Mutation,
	useAddDeleteEvent1Mutation,
	useAddUpdateEvent1Mutation,
} = injectedRtkApi;
