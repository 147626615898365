import { App } from 'antd';

import { removeMeasuresItem } from 'entities/metadata/measures';
import { useTransactions } from 'entities/transactions';
import { MeasurementGroupHttpResponse } from 'shared/api/generatedApi/measurementGetsApi';
import { useAddDeleteEvent1Mutation } from 'shared/api/generatedApi/measurementPatchApi';
import { useAppDispatch } from 'shared/hooks';

export const useDeleteMeasureGroup = () => {
	const [deleteMeasureGroup, { isLoading }] = useAddDeleteEvent1Mutation();
	const { getTransactions } = useTransactions();

	const { notification } = App.useApp();

	const dispatch = useAppDispatch();

	const deleteMesasureGroup = async (measure: MeasurementGroupHttpResponse) => {
		const deleteMeasure = await deleteMeasureGroup({ body: [{ id: measure.id }] });
		if ('error' in deleteMeasure) {
			notification.error({ message: 'Ошибка!', description: `${deleteMeasure.error}` });
		}
		if ('data' in deleteMeasure) {
			dispatch(removeMeasuresItem(measure));
			getTransactions();
		}
	};

	return {
		deleteMesasureGroup,
		loading: isLoading,
	};
};
