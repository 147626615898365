import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JoinRestrictionTableDeclarationDto } from 'shared/api/generatedApi/newUdmiApi';

interface ICatalogRestrictionTablesDeclarationsState {
	catalogRestrictionTablesDeclarations: JoinRestrictionTableDeclarationDto[] | null;
}

const initialState: ICatalogRestrictionTablesDeclarationsState = {
	catalogRestrictionTablesDeclarations: null,
};

const catalogRestrictionsSlice = createSlice({
	initialState,
	name: 'catalogRestrictionTablesDeclarations',
	reducers: {
		setCatalogRestrictionTablesDeclarations(
			state,
			action: PayloadAction<JoinRestrictionTableDeclarationDto[]>
		) {
			state.catalogRestrictionTablesDeclarations = action.payload;
		},
		addCatalogRestrictionTablesDeclarations(
			state,
			action: PayloadAction<JoinRestrictionTableDeclarationDto[]>
		) {
			state.catalogRestrictionTablesDeclarations = [
				...state.catalogRestrictionTablesDeclarations,
				...action.payload,
			];
		},
		deleteCatalogRestrictionTableDeclaration(state, action: PayloadAction<{ id: string }>) {
			state.catalogRestrictionTablesDeclarations =
				state.catalogRestrictionTablesDeclarations.filter(
					(decl) => decl.id !== action.payload.id
				);
		},
		removeCatalogRestrictionTablesDeclarations(state) {
			state.catalogRestrictionTablesDeclarations = null;
		},
	},
});

export const {
	setCatalogRestrictionTablesDeclarations,
	removeCatalogRestrictionTablesDeclarations,
	addCatalogRestrictionTablesDeclarations,
	deleteCatalogRestrictionTableDeclaration,
} = catalogRestrictionsSlice.actions;

export const catalogRestrictionTablesDeclarationsReducer = catalogRestrictionsSlice.reducer;
