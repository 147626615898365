import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClassifierDtoWithAttributeName } from 'shared/api/generatedApi/newUdmiApi';

interface IClassifiersState {
	сlassifiersGroups: Array<ClassifierDtoWithAttributeName>;
	currentClassifierGroup: ClassifierDtoWithAttributeName;
}

const initialState: IClassifiersState = {
	сlassifiersGroups: null,
	currentClassifierGroup: null,
};

const classifiersSlice = createSlice({
	name: 'classifiersGroups',
	initialState,
	reducers: {
		setСlassifiersGroups(state, action: PayloadAction<ClassifierDtoWithAttributeName[]>) {
			state.сlassifiersGroups = action.payload;
		},
		setCurrentClassifier(state, action: PayloadAction<ClassifierDtoWithAttributeName>) {
			state.currentClassifierGroup = action.payload;
		},
		editCurrentClassifier(
			state,
			{ payload }: PayloadAction<Partial<ClassifierDtoWithAttributeName>>
		) {
			const classifier = { ...state.currentClassifierGroup, ...payload };
			state.currentClassifierGroup = classifier;
			if (state.сlassifiersGroups && state.сlassifiersGroups.length) {
				state.сlassifiersGroups = state.сlassifiersGroups.map((item) => {
					if (item.id === classifier.id) return classifier;
					return item;
				});
			}
		},
		addNewClassifierGroup(state, action: PayloadAction<ClassifierDtoWithAttributeName>) {
			state.сlassifiersGroups.push(action.payload);
		},
		deleteClassifierGroup(state, { payload }: PayloadAction<string>) {
			state.сlassifiersGroups = state.сlassifiersGroups.filter(
				(Classifier) => Classifier.id !== payload
			);
		},
		removeCurrentClassifierGroup(state) {
			state.currentClassifierGroup = null;
		},
	},
});

export const {
	setСlassifiersGroups,
	setCurrentClassifier,
	deleteClassifierGroup,
	removeCurrentClassifierGroup,
	addNewClassifierGroup,
	editCurrentClassifier,
} = classifiersSlice.actions;

export const classifiersReducer = classifiersSlice.reducer;
