import { RiEditLine } from '@remixicon/react';
import { App, TableProps } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDeleteDuplicates } from 'features/catalogs/deleteDuplicates';
import { useSetDuplicateItem } from 'features/catalogs/setDuplicateItem';
import { useCatalogDeduplication } from 'entities/catalogs/catalogDeduplication';
import { CatalogDto, useLazyGetCatalogsQuery } from 'shared/api/generatedApi/newUdmiApi';
import { errorHelper, sortStrings } from 'shared/helpers';
import { useAppSelector } from 'shared/hooks';
import { CancelCopyIcon, AddCopyIcon, DropdownLink } from 'shared/ui';
import AppDropdown from 'shared/ui/components/AppDropdown';
import { ButtonVsDropdownLink } from 'shared/ui/components/ButtonVsDropdownLink';

interface IDataSource {
	key: string;
	name: string;
	catalog: string;
	menu: ReactElement;
}

const columns: TableProps<IDataSource>['columns'] = [
	{
		title: 'Название',
		dataIndex: 'name',
		key: 'name',
		sorter: (a, b) => sortStrings(a.name, b.name),
		showSorterTooltip: false,
	},
	{
		title: 'Справочник',
		dataIndex: 'catalog',
		key: 'catalog',
		sorter: (a, b) => sortStrings(a.catalog, b.catalog),
		showSorterTooltip: false,
	},
	{
		title: '',
		dataIndex: 'menu',
		key: 'menu',
		width: 20,
	},
];

export const useSuspectedDuplicates = () => {
	const suspectedDuplicates = useAppSelector(
		(state) => state.entities.catalogs.catalogDeduplication.suspectedDuplicates
	);

	const [catalogs, setCatalogs] = useState<Array<CatalogDto>>([]);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const [suspectedDuplicateId, setSuspectedDuplicateId] = useState<string>('');

	const { getSuspected, isSuspectedLoading } = useCatalogDeduplication();

	const [fetchGetCatalogs] = useLazyGetCatalogsQuery();

	const { notification } = App.useApp();

	const { deleteDuplicates, isLoading: isDeleting } = useDeleteDuplicates();

	const { setDuplicateItem, isLoading: isSetting } = useSetDuplicateItem();

	const openModal = (id: string) => {
		setSuspectedDuplicateId(id);
		setIsModalOpen(true);
	};

	const closeModal = () => setIsModalOpen(false);

	const getCatalogs = (ids: string[]) => {
		fetchGetCatalogs({ ids })
			.unwrap()
			.then((res) => setCatalogs(res))
			.catch((err) =>
				errorHelper('Ошибка при получении названия каталога', err, notification)
			);
	};

	useEffect(() => {
		if (!suspectedDuplicates?.data?.length) return;

		const ids = suspectedDuplicates.data.map((item) => item.suspectedOriginalItem.catalogId);
		getCatalogs(ids);
	}, [suspectedDuplicates]);

	const dataSource = suspectedDuplicates?.data?.map((item) => ({
		key: item.id,
		name:
			!item.suspectedOriginalItem.displayName ||
			item.suspectedOriginalItem.displayName === 'null'
				? 'без названия'
				: item.suspectedOriginalItem.displayName,
		catalog: catalogs.length
			? catalogs.filter((cat) => cat.id === item.suspectedOriginalItem.catalogId)[0]
					.displayName
			: '',
		menu: (
			<AppDropdown
				items={[
					{
						label: (
							<DropdownLink
								title="Редактировать"
								icon={<RiEditLine size={16} />}
								callback={() => openModal(item.id)}
							/>
						),
						key: '0',
					},
					{
						label: (
							<ButtonVsDropdownLink
								type="dropdownLink"
								icon={<CancelCopyIcon size={16} />}
								title="Удалить дубликаты для позиции"
								callback={() => deleteDuplicates([item.id])}
								isLoading={isDeleting}
							/>
						),
						key: '1',
					},
					{
						label: (
							<ButtonVsDropdownLink
								type="dropdownLink"
								icon={<AddCopyIcon size={16} />}
								title="Установить статус «Дубликат» для позиции"
								callback={() => setDuplicateItem([item.id])}
								isLoading={isSetting}
							/>
						),
						key: '2',
					},
				]}
			/>
		),
	}));

	useEffect(() => {
		getSuspected();
	}, []);

	return {
		columns,
		dataSource,
		isSuspectedLoading,
		isModalOpen,
		openModal,
		closeModal,
		suspectedDuplicateId,
	};
};
