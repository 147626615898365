import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { RiEditLine, RiListSettingsLine } from '@remixicon/react';
import { App } from 'antd';
import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { DeleteCatalogRecordUi } from 'features/catalogs/CatalogRecords/DeleteCatalogRecord';
import {
	ItemDto,
	useLazyGetAttributeDeclarations1Query,
	useLazyGetItemQuery,
	useLazyGetItemsQuery,
} from 'shared/api/generatedApi/newUdmiApi';
import { useLazyGetSearchResultQuery } from 'shared/api/generatedApi/searchApi';
import { errorHelper } from 'shared/helpers';
import { CellTypesEnum, CellValueParser } from 'shared/helpers/CellValueParser';
import { ItemValuesType } from 'shared/helpers/types';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { useDebounce } from 'shared/hooks/useDebounce';
import { Chip, DropdownLink } from 'shared/ui';
import AppDropdown from 'shared/ui/components/AppDropdown';
import { ChipStatus } from 'shared/ui/components/Chip/chipStylehelper';
import { setAttributeDeclarationsList } from '../attributeDeclarations/attributeDeclarations.store';
import { removeCatalogRecords, setCatalogRecords } from './catalogRecords.store';

export type ICatalogRecord = {
	id: string;
	recStatus: JSX.Element;
	menu: JSX.Element;
	key: string;
};
export type ICatalogRecords = Array<ICatalogRecord>;
export type ICatalogRecordsColumns = Array<{
	title: string | ReactElement;
	dataIndex: string;
	key: string;
	width?: string;
}>;

export interface IRecordValue {
	[key: string]: {
		attributeName: string;
		value: ItemValuesType;
	};
}

export interface IMappedRecordValue {
	[key: string]: string | JSX.Element;
}

export type ItemStatusDto = 'NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE';

export const chipTitle = (str: string): string => {
	switch (str) {
		case 'NORMALIZED':
			return 'Нормализована';

		case 'NON_NORMALIZED':
			return 'Не нормализована';

		case 'BLOCKED':
			return 'Заблокирована';

		case 'DELETED':
			return 'Удалена';

		case 'DUPLICATE':
			return 'Дубликат';

		default:
			'';
	}
};

export const useRecordsDtoToTableMapper = () => {
	const navigate = useNavigate();

	const { attributeDeclarationsList } = useAppSelector(
		(state) => state.entities.catalogs.attributeDeclarationsList
	);

	const [getItem, { isFetching: isItemLoading }] = useLazyGetItemQuery();

	const { notification } = App.useApp();

	const convertValues = async (values: IRecordValue): Promise<IMappedRecordValue> => {
		const result = {};

		if (values) {
			await Promise.all(
				Object.keys(values).map(async (id) => {
					const attribute = attributeDeclarationsList?.find((attr) => attr.id === id);
					if (attribute) {
						if (
							attribute.attribute.type === CellTypesEnum.RELATION &&
							values[id]['value']
						) {
							await getItem({ id: values[id]['value'] as unknown as string })
								.unwrap()
								.then((res) => {
									result[id] = CellValueParser(
										res.displayName.length > 0
											? res.displayName
											: '<Название не заполнено>',
										attribute.attribute.type as CellTypesEnum
									);
								})
								.catch((err) =>
									errorHelper(
										'Ошибка получения записей для атрибута типа "Связь"!',
										err,
										notification
									)
								);
						} else {
							result[id] = CellValueParser(
								values[id]['value'],
								attribute.attribute.type as CellTypesEnum
							);
						}
					}
				})
			);
		}

		return result;
	};

	const mapRecordsDtoToTable = async (recordsDto: ItemDto[] | null): Promise<ICatalogRecords> => {
		if (!recordsDto) return null;
		return await Promise.all(
			recordsDto.map(async (item) => ({
				id: item.id,
				recStatus: (
					<Chip status={item.status.toLowerCase() as ChipStatus}>
						{chipTitle(item.status)}
					</Chip>
				),
				menu: (
					<AppDropdown
						items={[
							{
								key: '0',
								label: <DeleteCatalogRecordUi recordId={item.id} />,
							},
							{
								key: '1',
								label: (
									<DropdownLink
										icon={<RiEditLine size={16} />}
										title="Редактировать"
										callback={() =>
											navigate(
												`${window.location.pathname}/record/${item.id}`
											)
										}
									/>
								),
							},
						]}
					/>
				),
				key: item.id,
				...(await convertValues(item?.values as IRecordValue)),
			}))
		);
	};
	return {
		mapRecordsDtoToTable,
		isItemLoading,
	};
};

export const useCatalogRecords = () => {
	const [catalogRecordsColumns, setCatalogRecordsColumns] = useState<ICatalogRecordsColumns>([]);
	const [catalogRecordError, setCatalogRecordError] = useState<
		FetchBaseQueryError | SerializedError
	>(null);
	const [fetchGetItems, { error: fetchGetItemsError, isFetching: isGetItemsLoading }] =
		useLazyGetItemsQuery();
	const [
		fetchGetAttributeDeclarations,
		{ error: fetchGetAttributeDeclarationsError, isFetching: isDeclarationsLoading },
	] = useLazyGetAttributeDeclarations1Query();

	const [getSearchResult, { isFetching: isSearchItemsLoading }] = useLazyGetSearchResultQuery();

	const { notification } = App.useApp();
	const { catalogGroupId } = useParams();
	const dispatch = useAppDispatch();
	const { catalogRecords } = useAppSelector((state) => state.entities.catalogs.catalogRecords);

	const [searchParams, setSearchParams] = useSearchParams();

	const searchHandler = useDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
		searchParams.set('searchValue', e.target.value);
		setSearchParams(searchParams);
	}, 1000);

	const fetchSearch = (searchValue: string) => {
		getSearchResult({ entityType: 'items', textRequest: searchValue, parentId: catalogGroupId })
			.unwrap()
			.then((res) => {
				dispatch(setCatalogRecords(res as ItemDto[]));
			})
			.catch((err) => {
				errorHelper('Ошибка при получении списка записей!', err, notification);
				setCatalogRecordError(err);
			});
	};

	const fetchItems = (
		catalogId: string,
		ids?: Array<string>
	): Promise<Array<ItemDto> | Error> => {
		return fetchGetItems({ catalogId, ids })
			.unwrap()
			.then((res) => res.data)
			.catch((e) => {
				errorHelper('Ошибка при получении списка записей!', e, notification);
				setCatalogRecordError(e);
				return e;
			});
	};

	useEffect(() => {
		getItems(catalogGroupId);
	}, [searchParams.get('searchValue')]);

	const getAttributeDeclarations = async (catalogId: string) => {
		const attributeDeclarations = await fetchGetAttributeDeclarations({
			catalogId,
		});

		if ('error' in attributeDeclarations) {
			errorHelper('Ошибка получения деклараций!', attributeDeclarations.error, notification);
			setCatalogRecordError(attributeDeclarations.error);
		}

		if ('data' in attributeDeclarations) {
			const newColumns = !attributeDeclarations.data.length
				? [
						{
							title: 'Статус',
							dataIndex: 'recStatus',
							key: 'recStatus',
							width: '175px',
						},
					]
				: [
						{
							title: 'Статус',
							dataIndex: 'recStatus',
							key: 'recStatus',
							width: '175px',
						},
						...attributeDeclarations.data.map((decl) => ({
							title: decl.attribute.displayName,
							dataIndex: decl.id,
							key: decl.id,
						})),
						{
							title: <RiListSettingsLine size={20} />,
							dataIndex: 'menu',
							key: 'menu',
							width: '50px',
						},
					];

			setCatalogRecordsColumns(newColumns);
			dispatch(setAttributeDeclarationsList(attributeDeclarations.data));
		}
	};

	const getItems = async (catalogId: string) => {
		const searchValue = searchParams.get('searchValue');
		if (searchValue) {
			await fetchSearch(searchValue);
		} else if (catalogId) {
			await fetchItems(catalogId).then((res) => {
				if (Array.isArray(res)) {
					dispatch(setCatalogRecords(res));
				} else {
					dispatch(removeCatalogRecords());
				}
			});
		}
	};

	useEffect(() => {
		if (catalogGroupId) {
			getAttributeDeclarations(catalogGroupId);
			getItems(catalogGroupId);
		}
	}, [catalogGroupId]);

	useEffect(() => {
		const error = fetchGetItemsError || fetchGetAttributeDeclarationsError;
		if (!error) setCatalogRecordError(null);
	}, [fetchGetAttributeDeclarationsError, fetchGetItemsError]);

	return {
		catalogRecordsDataSource: catalogRecords,
		catalogRecordsColumns,
		catalogRecordLoading: isGetItemsLoading || isDeclarationsLoading || isSearchItemsLoading,
		catalogRecordError,
		fetchItems,
		isGetItemsLoading,
		searchHandler,
		searchValue: searchParams.get('searchValue'),
	};
};
