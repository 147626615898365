import { App } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useEffect, useState } from 'react';
import { setConstraintTableColumns } from 'entities/metadata/constraintTables';
import { useTransactions } from 'entities/transactions';
import {
	AttributeDto,
	useLazyGetAttributesQuery,
	useLazyGetAttributeGroupQuery,
} from 'shared/api/generatedApi/newUdmiApi';
import { useUpdateRestrictionTableMutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';

export const useAssignColumns = () => {
	const { notification } = App.useApp();

	const [getAttributesGroups] = useLazyGetAttributeGroupQuery();

	const [getAttributes, { isFetching: isAttributesLoading }] = useLazyGetAttributesQuery();

	const [patchConstraintTable, { isLoading: isPatchTableLoading }] =
		useUpdateRestrictionTableMutation();

	const [treeData, setTreeData] = useState<Omit<DefaultOptionType, 'label'>[]>([]);

	const [isTableColumnsLoading, setIsTableColumnsLoading] = useState(false);
	const [isRootGroupsLoading, setIsRootGroupsLoading] = useState(false);

	const { getTransactions } = useTransactions();

	const { table, columns } = useAppSelector(
		(state) => state.entities.metadata.constraintTableDetails
	);

	const dispatch = useAppDispatch();

	const getRootGroups = async () => {
		setIsRootGroupsLoading(true);
		getAttributesGroups({ root: true })
			.unwrap()
			.then((res) => {
				setTreeData(
					res.map((group) => ({
						id: group.id,
						title: group.displayName || '<Название не заполнено>',
						value: group.id,
						isLeaf: !group.parent,
					}))
				);
			})
			.catch((err) => errorHelper('Ошибка получения групп!', err, notification))
			.finally(() => setIsRootGroupsLoading(false));
	};

	const getSubGroups = async (parentId: string) => {
		await getAttributesGroups({
			parentId,
		})
			.unwrap()
			.then((res) => {
				setTreeData((prev) => {
					return [
						...prev,
						...res.map((subGroup) => ({
							id: subGroup.id,
							pId: subGroup.parentId,
							title: subGroup.displayName || '<Название не заполнено>',
							value: subGroup.id,
							isLeaf: !subGroup.parent,
						})),
					];
				});
			})
			.catch((err) => errorHelper('Ошибка получения групп!', err, notification));
	};

	const clearTreeData = () => {
		setTreeData([]);
	};

	const getProperties = (parentId: string) => {
		return getAttributes({
			attributeGroupId: parentId,
		})
			.unwrap()
			.then((res) => res.data)
			.catch((err) => errorHelper('Ошибка получения атрибутов!', err, notification));
	};

	const changeColumns = async (addedColumns: string[], deletedColumns: string[]) => {
		if (addedColumns.length > 0 || deletedColumns.length > 0) {
			await patchConstraintTable({
				body: [
					{
						id: table.id,
						displayName: table.displayName,
						description: table.description,
						attributeValues: table.attributeValues,
						deletedAttributeIds: deletedColumns,
						newAttributeIds: addedColumns,
					},
				],
			})
				.unwrap()
				.then(async (res) => {
					getTransactions();
					const attributeIds = res.persistedEvents[0].data.attributeIds as string[];
					let newAttributes: AttributeDto[] = [];
					if (attributeIds.length > 0) {
						await getAttributes({
							ids: attributeIds,
						})
							.unwrap()
							.then((res) => (newAttributes = res.data))
							.catch((err) =>
								errorHelper('Ошибка получения атрибутов!', err, notification)
							);
					}
					dispatch(setConstraintTableColumns(newAttributes));
				})
				.catch((err) =>
					errorHelper('Ошибка редактирования ограничительной таблицы!', err, notification)
				);
		}
	};

	useEffect(() => {
		if (table && table.attributeIds.length > 0) {
			setIsTableColumnsLoading(true);
			getAttributes({
				ids: table.attributeIds,
			})
				.unwrap()
				.then((res) => {
					dispatch(setConstraintTableColumns(res.data));
				})
				.catch((err) =>
					errorHelper(
						'Ошибка получения столбцов ограничительной таблицы!',
						err,
						notification
					)
				)
				.finally(() => setIsTableColumnsLoading(false));
		}
	}, [table]);

	return {
		getRootGroups,
		getSubGroups,
		getProperties,
		isRootGroupsLoading,
		isAttributesLoading,
		isPatchTableLoading,
		isTableColumnsLoading,
		treeData,
		tableColumns: columns,
		clearTreeData,
		changeColumns,
	};
};
