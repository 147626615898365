import { RiDeleteBinLine } from '@remixicon/react';
import React from 'react';
import { DropdownLink } from 'shared/ui';
import { useDeleteAttributeDeclaration } from './deleteAttributeDeclaration.model';

interface IDeleteAttributeDeclarationProps {
	id: string;
}

const DeleteAttributeDeclarationUi: React.FC<IDeleteAttributeDeclarationProps> = ({ id }) => {
	const { handleDelete } = useDeleteAttributeDeclaration();
	return (
		<DropdownLink
			title="Удалить"
			icon={<RiDeleteBinLine size={16} />}
			callback={() => handleDelete(id)}
		/>
	);
};

export const DeleteAttributeDeclaration = React.memo(DeleteAttributeDeclarationUi);
