import { App } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useClassifierAttributesDeclarations } from 'entities/classifiers/classifierAttributeDeclarations/classifierAttributeDeclarations.model';
import { setCurrentClassifier } from 'entities/classifiers/classifiersGroups/classifier.store';
import {
	CatalogDto,
	useLazyGetCatalogsQuery,
	useLazyGetClassifierQuery,
} from 'shared/api/generatedApi/newUdmiApi';
import { errorHelper } from 'shared/helpers';
import { translateType } from 'shared/helpers/CellTypeParser';
import { declarationRestrictionParser } from 'shared/helpers/declarationRestrictionParser';
import { useAppDispatch } from 'shared/hooks';
import { colors } from 'shared/styles';

export const useEditAttributeList = () => {
	const [descriptions, setDescriptions] = useState<{ [key: string]: React.ReactNode }>(null);
	const [catalogs, setCatalogs] = useState<Array<CatalogDto>>([]);

	const [fetchGetCatalogs] = useLazyGetCatalogsQuery();
	const { classifierAttributeDeclarations, loading, error } =
		useClassifierAttributesDeclarations();
	const [fetchCurrentClassifier] = useLazyGetClassifierQuery();

	const { notification } = App.useApp();

	const { classifierGroupId } = useParams();

	const dispatch = useAppDispatch();

	const getCatalogs = (ids: string[]) => {
		fetchGetCatalogs({ ids })
			.unwrap()
			.then((res) => setCatalogs(res))
			.catch((err) =>
				errorHelper('Ошибка при получении названия каталога', err, notification)
			);
	};

	useEffect(() => {
		if (!classifierAttributeDeclarations) return;
		const newDescriptions = Object.fromEntries(
			classifierAttributeDeclarations?.map((item) => [
				item.id,
				<>
					<span>
						Тип данных:
						<span style={{ color: colors.text }}>
							{` ${translateType(item?.attribute?.type)}`}
						</span>
					</span>
					{Object.keys(item?.restrictions)
						.filter((r) => item?.restrictions[r] !== null)
						.map((r) =>
							declarationRestrictionParser(
								item?.attribute?.type,
								r,
								item?.restrictions[r],
								catalogs
							)
						)}
				</>,
			])
		);
		setDescriptions(newDescriptions);
	}, [catalogs, classifierAttributeDeclarations]);

	useEffect(() => {
		if (!classifierAttributeDeclarations || classifierAttributeDeclarations === null) return;
		let ids = [];
		classifierAttributeDeclarations?.forEach((d) => {
			if ('scopeId' in d.restrictions && d?.restrictions?.scopeId !== null) {
				ids = [...ids, ...(d?.restrictions?.scopeId as string[])];
			}
		});

		if (!ids.length) return;
		getCatalogs(ids);
	}, [classifierAttributeDeclarations]);

	useEffect(() => {
		fetchCurrentClassifier({ id: classifierGroupId })
			.unwrap()
			.then((res) => {
				dispatch(setCurrentClassifier(res));
			})
			.catch((err) => errorHelper('Ошибка при получении классификатора', err, notification));
	}, []);

	return { classifierAttributeDeclarations, loading, error, descriptions };
};
