import { Badge } from 'antd';
import React, { ReactElement } from 'react';
import { CatalogDto } from '../api/generatedApi/newUdmiApi';
import { colors } from '../styles';
import { AttributeRestrictionEnum } from './CellValueParser';
import { randomString } from '.';

export const declarationRestrictionParser = (
	type: string,
	key: string,
	value: string | object | null | undefined | string[],
	catalogs: Array<CatalogDto>
) => {
	const keyRu = AttributeRestrictionEnum[key];

	const descriptionItemUi = (keyRu: string, value: string | ReactElement) => {
		return (
			<span key={randomString(4)}>
				<span
					style={{
						margin: '0 6px',
						display: 'inline-block',
					}}
				>
					•
				</span>
				{keyRu}: <span style={{ color: colors.text }}>{value as string}</span>
			</span>
		);
	};

	switch (key) {
		case 'formulaId':
			return descriptionItemUi(keyRu, value as string);

		case 'required': {
			return descriptionItemUi(keyRu, value ? 'Да' : 'Нет');
		}

		case 'unique': {
			return descriptionItemUi(keyRu, value ? 'Да' : 'Нет');
		}

		case 'defaultValue':
			if (!value) return;

			if (type === 'BOOLEAN') return descriptionItemUi(keyRu, value ? 'Правда' : 'Ложь');

			if (type === 'DATE_TIME')
				return descriptionItemUi(keyRu, new Date(value as string).toLocaleDateString());

			if (type === 'COLOR')
				return descriptionItemUi(
					keyRu,
					<>
						<Badge
							color={`${value}`}
							style={{ display: 'inline', marginLeft: 4 }}
							styles={{ indicator: { width: 15, height: 15 } }}
						/>
						<span style={{ color: colors.text }}> {value as string}</span>
					</>
				);

			return descriptionItemUi(keyRu, value as string);

		case 'mask':
			if (!value) return;
			return descriptionItemUi(keyRu, value as string);

		case 'maxLength':
			return descriptionItemUi(keyRu, value as string);

		case 'accuracy':
			return descriptionItemUi(keyRu, value as string);

		case 'measurementId':
			return descriptionItemUi(keyRu, value as string);

		case 'removalChildren':
			return descriptionItemUi(keyRu, '');

		case 'scopeId': {
			return descriptionItemUi(
				keyRu,
				catalogs
					.filter((c) => (value as string[]).includes(c.id))
					.map((c) => (c.displayName ? `${c.displayName}` : 'без названия'))
					.join(', ')
					.toString()
			);
		}

		default:
			break;
	}
};
