import { RiAddCircleLine } from '@remixicon/react';
import { Button } from 'antd';
import React, { useState } from 'react';
import { useAddNewRow } from './addNewRow.model';

export const AddNewRow = () => {
	const { addNewRow } = useAddNewRow();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleAddNewRowClick = () => {
		setIsLoading(true);
		addNewRow();
		setIsLoading(false);
	};
	return (
		<Button
			onClick={handleAddNewRowClick}
			loading={isLoading}
			icon={<RiAddCircleLine size={16} />}
		>
			Добавить новую строку
		</Button>
	);
};
