import { App } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { deleteCatalogById } from 'entities/catalogs/catalogGroups/catalog.store';
import { useTransactions } from 'entities/transactions';
import { useAddDeleteCatalogGroupEventMutation } from 'shared/api/generatedApi/transactionServiceApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useDeleteCatalog = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const { notification } = App.useApp();
	const { getTransactions } = useTransactions();
	const [fetchDeleteCatalog, { error }] = useAddDeleteCatalogGroupEventMutation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const deleteCatalog = (id: string) => {
		setLoading(true);

		fetchDeleteCatalog({ body: [{ id }] })
			.unwrap()
			.then(() => {
				dispatch(deleteCatalogById(id));
				navigate(`${routes.catalogs.main}`);
				getTransactions();
			})
			.catch((err) => {
				errorHelper('Ошибка удаления справочника', err, notification);
			})
			.finally(() => setLoading(false));
	};
	return { loading, error, deleteCatalog };
};
