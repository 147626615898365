import { RiDeleteBin6Line } from '@remixicon/react';
import { Button, Checkbox, Flex, Modal, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useMemo, useState } from 'react';
import { MeasurementGroupHttpResponse } from 'shared/api/generatedApi/measurementGetsApi';
import { randomString } from 'shared/helpers';
import { DropdownLink } from 'shared/ui';
import { useDeleteMeasureGroup } from './deleteMeasureGroup.model';

interface IDeleteMeasuresGroupProps {
	measure: MeasurementGroupHttpResponse;
	isDropdown?: boolean;
}

const DeleteMeasuresGroupUi: React.FC<IDeleteMeasuresGroupProps> = ({ measure, isDropdown }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [confirm, setConfirm] = useState<boolean>(false);

	const { deleteMesasureGroup, loading } = useDeleteMeasureGroup();

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = async () => {
		setIsModalOpen(false);
		await deleteMesasureGroup(measure);
		setConfirm(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		setConfirm(false);
	};

	const onChange = (e: CheckboxChangeEvent) => setConfirm(e.target.checked);

	const footerButtons = useMemo(
		() => (
			<Flex key={randomString(4)} justify="flex-end" gap={4}>
				<Button key="back" onClick={handleCancel} type="text">
					Отмена
				</Button>

				<Button
					key="submit"
					type="primary"
					onClick={handleOk}
					disabled={!confirm}
					loading={loading}
				>
					Удалить
				</Button>
			</Flex>
		),
		[confirm, loading, measure]
	);

	return (
		<>
			{isDropdown ? (
				<DropdownLink
					title="Удалить"
					icon={<RiDeleteBin6Line size={16} />}
					callback={showModal}
				/>
			) : (
				<Button type="text" icon={<RiDeleteBin6Line />} onClick={showModal}>
					Удалить
				</Button>
			)}

			<Modal
				title="Удаление группы единиц измерения"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[footerButtons]}
				closeIcon={null}
				width={600}
			>
				<Flex vertical gap={24}>
					<Typography.Text>
						Вы действительно хотите удалить данную группу единиц измерения? Группа и
						входящие в нее единицы измерения будут удалены без возможности
						восстановления.
					</Typography.Text>
					<Checkbox onChange={onChange} checked={confirm} style={{ marginBottom: 38 }}>
						Я согласен удалить группу единиц измерения и входящие в нее единицы
						измерения
					</Checkbox>
				</Flex>
			</Modal>
		</>
	);
};

export const DeleteMeasuresGroup = React.memo(DeleteMeasuresGroupUi);
