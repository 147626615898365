import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { App } from 'antd';
import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useLazyGetClassifierQuery } from 'shared/api/generatedApi/newUdmiApi';
import { useLazyGetClassifiersQuery } from 'shared/api/generatedApi/newUdmiApi';
import { errorHelper } from 'shared/helpers';
import { useAppSelector, useAppDispatch } from 'shared/hooks';
import { useDidUpdateEffect } from 'shared/hooks/useDidUpdateEffect';
import { setCurrentClassifier, setСlassifiersGroups } from './classifier.store';

export const useGetClassifiersGroups = () => {
	const [error, setError] = useState<FetchBaseQueryError | SerializedError>(null);

	const [getGroups, { isLoading }] = useLazyGetClassifiersQuery();
	const { notification } = App.useApp();
	const { сlassifiersGroups } = useAppSelector(
		(state) => state.entities.classifiers.classifierGroups
	);
	const dispatch = useAppDispatch();

	const [searchParams] = useSearchParams();

	const getData = async () => {
		const data = await getGroups({});
		if ('error' in data) {
			errorHelper('Ошибка при получении списка классификаторов!', data.error, notification);
			setError(data.error);
		}
		if ('data' in data) {
			dispatch(setСlassifiersGroups(data.data));
		}
	};

	useEffect(() => {
		const searchValue = searchParams.get('searchValue');
		if (!сlassifiersGroups && !searchValue) {
			getData();
		}
	}, []);

	useDidUpdateEffect(() => {
		const searchValue = searchParams.get('searchValue');
		if (!searchValue) {
			getData();
		}
	}, [searchParams.get('searchValue')]);

	return {
		loading: isLoading,
		error,
		сlassifiersGroups,
	};
};

export const useGetCurrentСlassifierGroup = () => {
	const [error, setError] = useState<FetchBaseQueryError | SerializedError>(null);
	const { classifierGroupId } = useParams();
	const {
		loading: groupLoading,
		error: groupError,
		сlassifiersGroups,
	} = useGetClassifiersGroups();
	const dispatch = useAppDispatch();
	const { currentClassifierGroup } = useAppSelector(
		(state) => state.entities.classifiers.classifierGroups
	);
	const [getGroup, { isLoading }] = useLazyGetClassifierQuery();
	const { notification } = App.useApp();

	useEffect(() => {
		if (!currentClassifierGroup) {
			if (classifierGroupId && сlassifiersGroups) {
				const currGroup = сlassifiersGroups.find((item) => item.id === classifierGroupId);
				if (currGroup) {
					dispatch(setCurrentClassifier(currGroup));
				} else {
					getGroup({ id: classifierGroupId })
						.unwrap()
						.then((res) => {
							dispatch(setCurrentClassifier(res));
						})
						.catch((e) => {
							errorHelper('Ошибка при получении классификатора!', e, notification);
							setError(e);
						});
				}
			}
		}
	}, [classifierGroupId, сlassifiersGroups, currentClassifierGroup]);

	return {
		currentClassifierGroup,
		loading: groupLoading || isLoading,
		error: groupError || error,
	};
};
