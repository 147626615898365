import { ColorPicker, DatePicker, Checkbox, Input, Flex } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import dayjs from 'dayjs';
import React from 'react';
import {
	AttributeDeclarationDto,
	AttributeDto,
	ClassifierAttributeDeclarationDto,
} from '../api/generatedApi/newUdmiApi';
import { DropdownSelect } from '../ui';
import { CellTypesEnum } from './CellValueParser';
import { ItemValuesType } from './types';
import { randomString } from '.';

const isAttributeDtoType = (
	attribute: AttributeDeclarationDto | ClassifierAttributeDeclarationDto | AttributeDto
): attribute is AttributeDto => {
	return (attribute as AttributeDto)?.type !== undefined;
};

export const CellInputParser = (
	value: ItemValuesType,
	attributeDeclaration:
		| AttributeDeclarationDto
		| ClassifierAttributeDeclarationDto
		| AttributeDto,
	editInfo: (declarationId: string, value: ItemValuesType) => void,
	selectOptions?: DefaultOptionType[]
) => {
	const attributeType = isAttributeDtoType(attributeDeclaration)
		? attributeDeclaration?.type
		: attributeDeclaration?.attribute?.type;
	if (attributeType) {
		if (selectOptions && selectOptions.length > 0) {
			return (
				<DropdownSelect
					options={selectOptions}
					defaultValue={value}
					onChange={(e) => editInfo(attributeDeclaration.id, e)}
				/>
			);
		}
		switch (attributeType as CellTypesEnum) {
			case CellTypesEnum.COLOUR:
				return Array.isArray(value) ? (
					<Flex vertical gap={4}>
						{value.map((item) => (
							<ColorPicker
								key={randomString(5)}
								size="small"
								disabledAlpha
								defaultValue={item as string}
								showText
								style={{ maxWidth: 'fit-content' }}
								allowClear
								onChangeComplete={(color) => {
									return color
										? editInfo(attributeDeclaration.id, [
												...value,
												color.toHexString(),
											])
										: editInfo(attributeDeclaration.id, [
												...value.filter((v) => v !== item),
											]);
								}}
							/>
						))}
					</Flex>
				) : (
					<ColorPicker
						size="small"
						disabledAlpha
						defaultValue={value as string}
						showText
						allowClear
						onChangeComplete={(color) => {
							editInfo(attributeDeclaration.id, color.toHexString());
						}}
					/>
				);
			case CellTypesEnum.DATETIME:
				return Array.isArray(value) ? (
					<Flex gap={4} vertical>
						{value.map((v) => (
							<DatePicker
								key={randomString(5)}
								{...(v !== '' ? { value: dayjs(new Date(v as string)) } : {})}
								style={{ maxWidth: 'fit-content' }}
								onChange={(_, dateString) => {
									dateString
										? editInfo(attributeDeclaration.id, [
												...value,
												new Date(`${dateString}`).getTime(),
											])
										: editInfo(attributeDeclaration.id, [
												...value.filter((item) => item !== v),
											]);
								}}
							/>
						))}
					</Flex>
				) : (
					<DatePicker
						{...(value ? { value: dayjs(new Date(value as string)) } : {})}
						onChange={(_, dateString) => {
							dateString
								? editInfo(
										attributeDeclaration.id,
										new Date(`${dateString}`).getTime()
									)
								: editInfo(attributeDeclaration.id, '');
						}}
					/>
				);

			case CellTypesEnum.BOOL:
			case CellTypesEnum.BOOLEAN:
				return Array.isArray(value) ? (
					<Flex gap={4} vertical>
						{value.map((v) => (
							<Checkbox
								style={{ maxWidth: 'fit-content' }}
								checked={Boolean(value)}
								key={randomString(5)}
								onChange={(e) =>
									e.target.checked !== v &&
									editInfo(attributeDeclaration.id, [...value, e.target.checked])
								}
							/>
						))}
					</Flex>
				) : (
					<Checkbox
						checked={Boolean(value)}
						onChange={(e) =>
							e.target.checked !== value &&
							editInfo(attributeDeclaration.id, e.target.checked)
						}
					/>
				);
			case CellTypesEnum.INT:
			case CellTypesEnum.DOUBLE:
			case CellTypesEnum.FLOAT:
				return Array.isArray(value) ? (
					<Flex gap={4} vertical>
						{value.map((v) => (
							<Input
								key={randomString(5)}
								defaultValue={v as string | number}
								onBlur={(e) =>
									e.target.value !== v &&
									editInfo(attributeDeclaration.id, [
										...value,
										Number(e.target.value),
									])
								}
								allowClear
								type="number"
							/>
						))}
					</Flex>
				) : (
					<Input
						defaultValue={value as string | number}
						onBlur={(e) =>
							e.target.value !== value &&
							editInfo(attributeDeclaration.id, Number(e.target.value))
						}
						allowClear
						type="number"
					/>
				);
			case CellTypesEnum.RELATION:
				return Array.isArray(value) ? (
					<Flex gap={4} vertical>
						{value.map((v) => (
							<DropdownSelect
								style={{ maxWidth: 'fit-content' }}
								key={randomString(5)}
								options={selectOptions}
								defaultValue={v}
								onChange={(e) => editInfo(attributeDeclaration.id, [...value, e])}
							/>
						))}
					</Flex>
				) : (
					<DropdownSelect
						options={selectOptions}
						defaultValue={value}
						onChange={(e) => editInfo(attributeDeclaration.id, e)}
					/>
				);
			default:
				return Array.isArray(value) ? (
					<Flex gap={4} vertical>
						{value.map((v) => (
							<Input.TextArea
								autoSize
								key={randomString(5)}
								defaultValue={v as string | number}
								allowClear
								onBlur={(e) =>
									e.target.value !== v &&
									editInfo(attributeDeclaration.id, [...value, e.target.value])
								}
							/>
						))}
					</Flex>
				) : (
					<Input.TextArea
						autoSize
						defaultValue={value as string | number}
						allowClear
						onBlur={(e) =>
							e.target.value !== value &&
							editInfo(attributeDeclaration.id, e.target.value)
						}
					/>
				);
		}
	}
};
