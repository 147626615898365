import { App } from 'antd';
import { useNavigate } from 'react-router';
import {
	addNewClassifierGroup,
	setCurrentClassifier,
} from 'entities/classifiers/classifiersGroups/classifier.store';
import { ClassifierDtoWithAttributeName } from 'shared/api/generatedApi/newUdmiApi';
import { useAddCreateEvent2Mutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useCreateClassifier = () => {
	const [createGroup, { isLoading }] = useAddCreateEvent2Mutation();
	const navigate = useNavigate();
	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const create = async () => {
		await createGroup({ body: [{ displayName: '' }] })
			.unwrap()
			.then((res) => {
				const group = res.persistedEvents[0]
					.data as unknown as ClassifierDtoWithAttributeName;
				dispatch(addNewClassifierGroup(group));
				dispatch(setCurrentClassifier(group));
				navigate(`${res.persistedEvents[0].entityId}`);
			})
			.catch((e) => {
				errorHelper('Ошибка при создании классификатора!', e, notification);
			});
	};

	return {
		create,
		isLoading,
	};
};
