import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClassifierAttributeDeclarationDto } from 'shared/api/generatedApi/newUdmiApi';

interface IAttributesState {
	classifierAttributeDeclarations: Array<ClassifierAttributeDeclarationDto>;
}

const initialState: IAttributesState = {
	classifierAttributeDeclarations: null,
};

const classifierAttributesSlice = createSlice({
	name: 'attributes',
	initialState,
	reducers: {
		setClassifierAttributeDeclarationsList(
			state,
			{ payload }: PayloadAction<Array<ClassifierAttributeDeclarationDto>>
		) {
			state.classifierAttributeDeclarations = payload;
		},

		addNewClassifierAttributeDeclaration(
			state,
			{ payload }: PayloadAction<ClassifierAttributeDeclarationDto>
		) {
			state.classifierAttributeDeclarations = [
				...(state.classifierAttributeDeclarations && state.classifierAttributeDeclarations),
				payload,
			];
		},
		editClassifierAttributeDeclaration(
			state,
			{ payload }: PayloadAction<ClassifierAttributeDeclarationDto>
		) {
			state.classifierAttributeDeclarations = state.classifierAttributeDeclarations.map(
				(item) => {
					if (item.id === payload.id) return payload;
					return item;
				}
			);
		},
		editNewClassifierAttributeDeclaration(
			state,
			{ payload }: PayloadAction<ClassifierAttributeDeclarationDto>
		) {
			state.classifierAttributeDeclarations = state.classifierAttributeDeclarations.map(
				(item) => {
					if (item.id === payload.id) return payload;
					return item;
				}
			);
		},
		deleteClassifierAttributeDeclaration(state, { payload }: PayloadAction<string>) {
			state.classifierAttributeDeclarations = state?.classifierAttributeDeclarations?.filter(
				(catalog) => catalog.id !== payload
			);
		},
		removeClassifierAttributeDeclarationList(state) {
			state.classifierAttributeDeclarations = null;
		},
	},
});

export const {
	setClassifierAttributeDeclarationsList,
	editNewClassifierAttributeDeclaration,
	editClassifierAttributeDeclaration,
	addNewClassifierAttributeDeclaration,
	deleteClassifierAttributeDeclaration,
	removeClassifierAttributeDeclarationList,
} = classifierAttributesSlice.actions;

export const classifierAttributesDeclarationsListReducer = classifierAttributesSlice.reducer;
