import { RiFilterLine, RiSettings5Line } from '@remixicon/react';
import { Button, Checkbox, DatePicker, Dropdown, Flex, Table, TableProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import cn from 'classnames';
import dayjs from 'dayjs';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { isValidDate, randomString, truncateString } from '../../../helpers';
import { colors } from '../../../styles';
import s from './workTable.module.scss';

interface IWorkTableProps extends TableProps {
	noFilters?: boolean;
	filters?: Array<IWorkTableFilter> | [];
	clearFilters?: boolean;
}

export interface IWorkTableFilter {
	label: string;
	rowKey: string;
	filterType: 'multi' | 'period';
	options?: Array<{ label: string | React.ReactElement | number; value: string }>;
}

interface IItem {
	label: ReactElement;
	key: string;
}

export type WorkTableFilters = Array<IWorkTableFilter>;

const escOnclickParams = {
	altKey: false,
	code: 'Escape',
	ctrlKey: false,
	isComposing: false,
	key: 'Escape',
	location: 0,
	metaKey: false,
	repeat: false,
	shiftKey: false,
	which: 27,
	charCode: 0,
	keyCode: 27,
};

const WorkTableUi: React.FC<IWorkTableProps> = ({
	dataSource,
	columns,
	filters,
	noFilters,
	clearFilters,
	...props
}) => {
	const [items, setItems] = useState<Array<IItem>>([]);
	const [rows, setRows] = useState<TableProps['dataSource']>([]);
	const [cols, setCols] = useState<TableProps['columns']>([]);
	const [filterContaiers, setFiltersContainers] = useState<Array<ReactElement>>([]);
	const [activeFilters, setActiveFilters] = useState<Record<string, Array<string>>>({});

	const handleSetActiveFilter = (
		e: CheckboxChangeEvent,
		str: IWorkTableFilter,
		option: { label: string | ReactElement | number; value: string }
	) => {
		if (e.target.checked) {
			setActiveFilters((prev) => ({
				...prev,
				[str.rowKey]: [...(prev[str.rowKey] || []), option.value as string],
			}));
		} else {
			setActiveFilters((prev) => {
				const updatedFilters = { ...prev };
				updatedFilters[str.rowKey] = updatedFilters[str.rowKey].filter(
					(item) => item !== option.value
				) as string[];
				return updatedFilters;
			});
		}
	};

	const handleSetActiveDate = (_, dateStrings: [string, string], item: IWorkTableFilter) => {
		if (dateStrings.find((item) => !!item)) {
			const concatenatedDateStrings = dateStrings.join(':');
			setActiveFilters((prev) => ({
				...prev,
				[item.rowKey]: [concatenatedDateStrings],
			}));
		} else {
			setActiveFilters((prev) => ({
				...prev,
				[item.rowKey]: [],
			}));
		}
	};

	const prepareMenuItems = useCallback(
		(items: IWorkTableFilter) => {
			let newItems;
			setActiveFilters((prev) => {
				items.filterType === 'multi'
					? (newItems = items.options.map((option) => ({
							label: (
								<Checkbox
									onChange={(e) => handleSetActiveFilter(e, items, option)}
									// тут останавливаем всплытие для того, чтобы дропдаун не закрывался
									onClick={(e) => e.stopPropagation()}
									className={s.checkboxStyles}
									key={randomString(5)}
								>
									{option.label}
								</Checkbox>
							),
							key: randomString(5),
						})))
					: (newItems = [
							{
								label: (
									<DatePicker.RangePicker
										key={randomString(10)}
										onChange={(dates, dateStrings) =>
											handleSetActiveDate(dates, dateStrings, items)
										}
										defaultValue={
											prev[items.rowKey]?.length && [
												dayjs(prev[items.rowKey][0].split(':')[0]),
												dayjs(prev[items.rowKey][0].split(':')[1]),
											]
										}
									/>
								),
								key: randomString(6),
							},
						]);
				return prev;
			});
			return newItems;
		},
		[activeFilters]
	);

	const handlePauseFilters = (key: string) => {
		setActiveFilters((prev) => {
			const newPrev = { ...prev, [key]: [] };
			const values = Object.values(newPrev).flat();
			if (values.length && dataSource.length) {
				setRows(() => {
					return dataSource.filter((row) => {
						let good: boolean = true;
						for (const key in newPrev) {
							if (key in row && newPrev[key].length > 0) {
								if (!newPrev[key].includes(row[key])) {
									good = false;
								}
							}
						}
						return good;
					});
				});
			} else {
				setRows(dataSource);
			}
			// для того чтобы дропдаун закрывался после нажатия кнопки
			window.dispatchEvent(new KeyboardEvent('keydown', escOnclickParams));
			return newPrev;
		});
	};

	const handleAddFilters = useCallback(() => {
		// Напрямую использовать activeFilters нельзя, потому что объект activeFilter замыкается
		setActiveFilters((prevActiveFilters) => {
			const values = Object.values(prevActiveFilters).flat();
			if (values.length && dataSource.length) {
				setRows(() => {
					return dataSource.filter((row) => {
						let good: boolean = true;
						for (const key in prevActiveFilters) {
							if (key in row && prevActiveFilters[key].length > 0) {
								if (
									prevActiveFilters[key].length === 1 &&
									prevActiveFilters[key][0]
										.split(':')
										.filter((item) => isValidDate(item)).length > 1
								) {
									const dates = prevActiveFilters[key][0].split(':');
									const start = new Date(dates[0]).getTime();
									const end = new Date(dates[1]).getTime();
									const target = row[key];
									if (target <= start || target >= end) {
										good = false;
									}
								}
								if (
									!prevActiveFilters[key].includes(row[key]) &&
									prevActiveFilters[key][0]
										.split(':')
										.filter((item) => isValidDate(item)).length < 1
								) {
									good = false;
								}
							}
						}
						return good;
					});
				});
			} else {
				setRows(dataSource);
			}
			// для того чтобы дропдаун закрывался после нажатия кнопки
			window.dispatchEvent(new KeyboardEvent('keydown', escOnclickParams));
			return prevActiveFilters;
		});
	}, [dataSource]);

	const handleSetFilters = (e: CheckboxChangeEvent, filter: IWorkTableFilter) => {
		if (e.target.checked) {
			setFiltersContainers((prev) => {
				return [
					...prev,
					<Dropdown
						key={filter.rowKey}
						trigger={['click']}
						dropdownRender={() => (
							<div className={s.dropdownContainer}>
								{prepareMenuItems(filter).map(
									(item: IWorkTableFilter) => item.label
								)}
								<div className={s.buttonsContainer}>
									<Button
										type="text"
										onClick={() => handlePauseFilters(filter.rowKey)}
									>
										Очистить фильтр
									</Button>
									<Button type="primary" onClick={handleAddFilters}>
										Применить
									</Button>
								</div>
							</div>
						)}
					>
						<Button
							key={filter.label}
							type="default"
							id={filter.rowKey}
							icon={<RiSettings5Line color={colors.grayIcon} />}
							className={cn([s.filterButton, 'workTableFilterButton'])}
						>
							{filter.label}
						</Button>
					</Dropdown>,
				];
			});
		} else {
			setFiltersContainers((prev) => prev.filter((item) => item.key !== filter.rowKey));
		}
	};

	useEffect(() => {
		if (dataSource) {
			setRows([...dataSource]);
		}
	}, [dataSource]);

	useEffect(() => {
		if (columns && columns.length) {
			setCols([...columns]);
		}
	}, [columns]);

	useEffect(() => {
		if (!noFilters && filters) {
			setItems(
				filters.map((filter: IWorkTableFilter) => ({
					label: (
						<Checkbox
							onChange={(e) => {
								handleSetFilters(e, filter);
							}}
						>
							{filter.label}
						</Checkbox>
					),
					key: filter.label,
				}))
			);
			setActiveFilters(() => {
				return filters.reduce((acc: Record<string, string[]>, filter: IWorkTableFilter) => {
					acc[filter.rowKey.toString()] = [];
					return acc;
				}, {});
			});
		}
	}, [noFilters, filters]);
	useEffect(() => {
		// Добавляем цвет активному фильтру
		const keys = Object.keys(activeFilters);
		keys.forEach((key) => {
			const elem = document.getElementById(key);
			if (elem) {
				const spans = elem.getElementsByTagName('span');
				if (spans.length >= 2) {
					const secondSpan = spans[1];
					const filterText = activeFilters[key].join(',');
					if (activeFilters[key].length) {
						elem.setAttribute(
							'style',
							'border-color: var(--tab-underline) !important;'
						);
						if (!secondSpan.textContent.includes(filterText)) {
							if (!secondSpan.textContent.includes(':')) {
								secondSpan.innerHTML = `${secondSpan.textContent}:<span class="${s.filteredText}">${filterText}</span>`;
							} else {
								const originalText = secondSpan.textContent.split(':')[0];
								secondSpan.innerHTML = `${originalText}:<span class="${s.filteredText}">${truncateString(filterText, 20)}</span>`;
							}
						}
					} else {
						elem.removeAttribute('style');
						if (secondSpan.textContent.includes(':')) {
							const [originalText] = secondSpan.textContent.split(':');
							secondSpan.textContent = originalText;
						}
					}
				}
			}
		});
	}, [activeFilters]);

	useEffect(() => {
		if (clearFilters === true) {
			const activeButtons = document.querySelectorAll('.workTableFilterButton');
			if (activeButtons.length)
				activeButtons.forEach((elem) => elem.removeAttribute('style'));
			setRows(dataSource);
		}

		if (clearFilters === false) {
			handleAddFilters();
			const activeButtons = document.querySelectorAll('.workTableFilterButton');
			if (activeButtons.length)
				activeButtons.forEach((elem) =>
					elem.setAttribute(
						'style',
						`color: var(--text) !important;
	  border-color: var(--tab-underline) !important;`
					)
				);
		}
	}, [clearFilters]);

	return (
		<Flex vertical gap={12}>
			{!noFilters && rows.length > 0 && (
				<Flex gap={6}>
					{filterContaiers}
					<Dropdown menu={{ items }} className={s.addFilterButton} trigger={['click']}>
						<Button type="text" icon={<RiFilterLine />}>
							Управление фильтрацией
						</Button>
					</Dropdown>
				</Flex>
			)}

			<Table {...props} dataSource={rows} columns={cols} />
		</Flex>
	);
};

export const WorkTable = React.memo(WorkTableUi);
