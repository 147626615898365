import { Flex, Typography } from 'antd';
import React, { useEffect } from 'react';
import { ConstraintTableDetails } from 'widgets/Metadata/ConstraintTables/ConstraintTableDetails/ConstraintTableDetails.ui';
import { Transactions } from 'widgets/Transactions';
import { setBreadcrumbs } from 'entities/breadcrumbs';
import { removeConstraintTableDetails } from 'entities/metadata/constraintTables';
import { routes } from 'shared/config';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { paragraphStyle, titleStyle } from '../configPage.style';

export const ConstraintTableCardPage = () => {
	const dispatch = useAppDispatch();

	const tableInfo = useAppSelector(
		(state) => state.entities.metadata.constraintTableDetails.table
	);

	useEffect(() => {
		if (tableInfo) {
			dispatch(
				setBreadcrumbs({
					displayName: 'Метаданные',
					url: `${routes.metadata.main}/${routes.metadata.constraintTables}`,
					child: {
						displayName: tableInfo.displayName,
						url: window.location.pathname,
					},
				})
			);
		}
	}, [tableInfo]);

	useEffect(() => {
		return () => {
			dispatch(removeConstraintTableDetails());
		};
	}, []);

	return (
		<Flex align="start" gap={24} vertical style={{ height: '100%' }}>
			<Flex vertical style={{ width: '100%' }}>
				<Flex justify="space-between" style={{ width: '100%' }}>
					<Typography.Title level={1} style={titleStyle}>
						Метаданные
					</Typography.Title>
					<Transactions />
				</Flex>
				<Typography.Paragraph style={paragraphStyle}>
					Раздел для управления данными справочников
				</Typography.Paragraph>
			</Flex>

			<ConstraintTableDetails />
		</Flex>
	);
};
