import { App } from 'antd';
import { useGetCurrentMeausureGroup, editCurrentMeasureGroup } from 'entities/metadata/measures';
import { useTransactions } from 'entities/transactions';
import { useAddDeleteEventMutation } from 'shared/api/generatedApi/measurementPatchApi';
import { useAppDispatch } from 'shared/hooks';

export const useDeleteMeasureUnits = () => {
	const { currentMeasureGroup } = useGetCurrentMeausureGroup();

	const { notification } = App.useApp();
	const [deleteUnits, { isLoading }] = useAddDeleteEventMutation();
	const dispatch = useAppDispatch();

	const { getTransactions } = useTransactions();

	const deleteMeasureUnits = async (unitId: Array<string>) => {
		await deleteUnits({ body: unitId.map((item) => ({ id: item })) })
			.unwrap()
			.then(() => {
				dispatch(
					editCurrentMeasureGroup({
						...currentMeasureGroup,
						childrenMeasures: currentMeasureGroup.childrenMeasures.filter(
							(item) => !unitId.includes(item.id)
						),
					})
				);
				getTransactions();
			})
			.catch((e) => {
				notification.error({
					message: 'Ошибка при удалении ЕИ!',
					description: JSON.stringify(e),
				});
			});
	};

	return {
		deleteMeasureUnits,
		loading: isLoading,
	};
};
