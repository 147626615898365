import { App } from 'antd';
import { deleteClassifierAttributeDeclaration } from 'entities/classifiers/classifierAttributeDeclarations/attributeDeclarations.store';
import { useTransactions } from 'entities/transactions';
import { useAddDeleteEvent4Mutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useDeleteAttributeDeclaration = () => {
	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const [deleteDeclaration] = useAddDeleteEvent4Mutation();

	const { getTransactions } = useTransactions();

	const handleDelete = async (id: string) => {
		const data = await deleteDeclaration({ body: [{ id }] });
		if ('error' in data) {
			errorHelper('Ошибка при удалении атрибута!', data.error, notification);
		}
		if ('data' in data) {
			getTransactions();
			dispatch(deleteClassifierAttributeDeclaration(id));
		}
	};

	return {
		handleDelete,
	};
};
