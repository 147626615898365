import { App } from 'antd';
import { useCallback } from 'react';
import {
	editCurrentClassifier,
	setCurrentClassifier,
} from 'entities/classifiers/classifiersGroups/classifier.store';
import { useAddUpdateEvent2Mutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppSelector, useAppDispatch } from 'shared/hooks';

export const useSelectAttribute = () => {
	const { currentClassifierGroup } = useAppSelector(
		(state) => state.entities.classifiers.classifierGroups
	);
	const dispatch = useAppDispatch();

	const [editClassifier] = useAddUpdateEvent2Mutation();

	const { notification } = App.useApp();

	const handleUpdate = useCallback(
		async (linkClassifierAttributes: Array<string>) => {
			await editClassifier({
				body: [{ ...currentClassifierGroup, linkClassifierAttributes }],
			})
				.unwrap()
				.then((res) => {
					dispatch(editCurrentClassifier(res.persistedEvents[0].data));
				})
				.catch((e) => {
					dispatch(setCurrentClassifier(currentClassifierGroup));
					errorHelper('Ошибка при редактировании классификатора!', e, notification);
				});
		},
		[currentClassifierGroup]
	);

	return {
		handleUpdate,
	};
};
