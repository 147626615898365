import { Checkbox, Col, Flex, Form, Input, Result, Skeleton, Spin, Typography } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';

import { useGetCurrentMeausureGroup, useGetMeasuresGroup } from 'entities/metadata/measures';
import { routes } from 'shared/config';
import { CellInputParser } from 'shared/helpers/CellInputParser';
import { CellTypesEnum } from 'shared/helpers/CellValueParser';
import { NEW_ENTITY } from 'shared/helpers/Constants';
import { ItemValuesType } from 'shared/helpers/types';
import { DetailFooter, DropdownSelect } from 'shared/ui';

import { DropdownTreeSelect } from 'shared/ui/components/AppTreeSelect';
import { DeleteSimpleAttributeUi } from '../deleteSimpleAttribute';
import { useEditSimpleAttribute } from './editSimpleAttribute.model';

const selectOptions = [
	{ label: 'Строка', value: 'STRING' },
	{ label: 'Число с плавающей точкой', value: 'FLOAT' },
	{ label: 'Целое число', value: 'INTEGER' },
	{ label: 'Логический тип', value: 'BOOLEAN' },
	{ label: 'Многострочный текст', value: 'TEXT' },
	{ label: 'Дата', value: 'DATE_TIME' },
	{ label: 'Цвет', value: 'COLOR' },
	{ label: 'Формула', value: 'FORMULA' },
	{ label: 'Связь', value: 'RELATION' },
	{ label: 'Изображение', value: 'IMAGE' },
	{ label: 'Файл', value: 'FILE' },
];

const EditSimpleAttributeUi = () => {
	const {
		formVals,
		usageList,
		usageLoading,
		usageError,
		isLoading,
		getAttributeError,
		handleUpdateAttribute,
	} = useEditSimpleAttribute();

	const [form] = Form.useForm();
	const typeValue = Form.useWatch('type', form);
	const initialValuesRef = useRef(null);

	const { metaAttributeId, metaAttributeGroupId } = useParams();
	const { measuresList, loading, error } = useGetMeasuresGroup();
	const {
		currentMeasureGroup,
		getData,
		loading: measuresLoading,
		error: measuresError,
	} = useGetCurrentMeausureGroup();
	const navigate = useNavigate();

	const handleBlur = async () => {
		const rawValues = form.getFieldsValue([
			'displayName',
			'description',
			'list',
			'associatedAttributeIds',
			'attributeGroupId',
			'measurementGroupId',
			'type',
		]);

		let restrictions = {};

		if (typeValue === 'STRING') {
			restrictions = form.getFieldsValue(['mask', 'defaultValue', 'formulaId', 'maxLength']);
		} else if (typeValue === 'FLOAT') {
			restrictions = form.getFieldsValue([
				'accuracy',
				'measurementId',
				'defaultValue',
				'formulaId',
			]);
		} else if (typeValue === 'INTEGER') {
			restrictions = form.getFieldsValue(['defaultValue', 'formulaId']);
		} else if (['TEXT', 'DATE_TIME', 'COLOR'].includes(typeValue)) {
			restrictions = form.getFieldsValue(['formulaId', 'defaultValue']);
		} else if (typeValue === 'FORMULA') {
			restrictions = form.getFieldsValue(['formulaId']);
		} else if (typeValue === 'RELATION') {
			restrictions = form.getFieldsValue(['scopeId', 'defaultValue', 'formulaId']);
		}

		const combinedRawValues = {
			...rawValues,
			restrictions: {
				...restrictions,
			},
		};

		if (typeValue === CellTypesEnum.FLOAT) {
			if (!combinedRawValues?.measurementGroupId) {
				combinedRawValues.measurementGroupId = null;
				combinedRawValues.restrictions = {
					...combinedRawValues.restrictions,
					measurementId: null,
				};
			}

			if (
				combinedRawValues?.measurementGroupId &&
				combinedRawValues.restrictions.measurementId === undefined
			) {
				combinedRawValues.restrictions = {
					...combinedRawValues.restrictions,
					measurementId: null,
				};
			}
		}

		if (JSON.stringify(combinedRawValues) === JSON.stringify(initialValuesRef.current)) {
			return;
		}

		await handleUpdateAttribute(combinedRawValues);

		initialValuesRef.current = combinedRawValues;
	};

	const handleEditComplexDefaultValue = (_, value: ItemValuesType) => {
		form.setFieldValue('defaultValue', value);
		handleBlur();
	};

	const handleBackClick = () => {
		navigate(`/${routes.metadata.main}/${routes.metadata.group}/${metaAttributeGroupId}`);
	};

	useEffect(() => {
		if (formVals) {
			form.setFieldsValue(formVals);
			form.setFieldValue(
				'associatedAttributeIds',
				formVals?.associatedAttributeIds?.map((item) => ({ label: item, value: item }))
			);
			if ('restrictions' in formVals) form.setFieldsValue(formVals.restrictions);
			if (
				formVals.restrictions &&
				'measurementId' in formVals.restrictions &&
				formVals.restrictions.measurementId
			) {
				if (form.getFieldValue('measurementGroupId')) {
					getData(form.getFieldValue('measurementGroupId')).then((res) => {
						const data = res?.childrenMeasures?.find(
							(item) =>
								typeof formVals?.restrictions?.measurementId === 'string' &&
								item.id === formVals.restrictions.measurementId
						);
						if (data) {
							form.setFieldValue('mesurmentId', {
								title: data.displayName,
								key: data.id,
								value: data.id,
							});
						} else {
							form.setFieldValue('mesurmentId', null);
						}
					});
				}
			}
			if (typeof formVals.description === 'object') {
				const v = (formVals.description as { value: string })?.value;
				form.setFieldValue('description', v);
			}

			if (typeValue === CellTypesEnum.FLOAT && form.getFieldValue('measurementGroupId')) {
				getData(form.getFieldValue('measurementGroupId'));
			}
			initialValuesRef.current = form.getFieldsValue();
		}
	}, [formVals]);

	return (
		<>
			<Col sm={24} md={24} lg={20} xl={14} xxl={14} style={{ marginBottom: 65 }}>
				{isLoading && (
					<div
						style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
					>
						<Spin size="large" />
					</div>
				)}
				{!isLoading && getAttributeError && (
					<Result
						status="error"
						title="Ошибка при получении данных для редактирования атрибута!"
						subTitle={JSON.stringify(getAttributeError)}
					/>
				)}

				{!isLoading && !getAttributeError && (
					<Flex vertical gap={12} style={{ width: '100%' }}>
						<Typography.Title level={2}>Общая информация</Typography.Title>
						<Form layout="vertical" form={form}>
							<Form.Item label="Наименование" required name="displayName">
								<Input onBlur={handleBlur} allowClear />
							</Form.Item>
							<Form.Item label="Описание" name="description">
								<Input onBlur={handleBlur} allowClear />
							</Form.Item>

							<Form.Item name="list" valuePropName="checked">
								<Checkbox onChange={handleBlur}>Множественный</Checkbox>
							</Form.Item>
							{/* <Form.Item label="Группа атрибута" name="attributeGroupId">
								<EditAttributeGroupSelect
									value={formVals?.attributeGroupId}
									onChange={handleBlur}
								/>
							</Form.Item> */}
							<Form.Item label="Группа единиц измерения" name="measurementGroupId">
								{error ? (
									<Input
										value="Не удалось получить данные для выбора Единиц измерения!"
										disabled
									/>
								) : (
									<DropdownTreeSelect
										loading={loading}
										onChange={handleBlur}
										treeData={measuresList?.map((item) => ({
											title: item.displayName,
											key: item.id,
											value: item.id,
										}))}
										value={formVals?.measurementGroupId}
										allowClear
									/>
								)}
							</Form.Item>
							<Form.Item label="Тип данных" required name="type">
								<DropdownSelect
									options={selectOptions}
									allowClear
									onChange={handleBlur}
									disabled={usageList?.length > 0}
								/>
							</Form.Item>

							{typeValue === 'STRING' && (
								<>
									<Form.Item label="Маска ввода" name="mask">
										<Input onBlur={handleBlur} allowClear />
									</Form.Item>
									<Form.Item label="Значение по умолчанию" name="defaultValue">
										{CellInputParser(
											form.getFieldValue('defaultValue'),
											formVals,
											handleEditComplexDefaultValue
										)}
									</Form.Item>
									{/* <Form.Item label="Формула" name="formulaId">
										<Input onBlur={handleBlur} allowClear />
									</Form.Item> */}
									<Form.Item label="Максимальная длина" name="maxLength">
										<Input onBlur={handleBlur} allowClear />
									</Form.Item>
								</>
							)}
							{typeValue === 'FLOAT' && (
								<>
									<Form.Item label="Точность" name="accuracy">
										<Input onBlur={handleBlur} allowClear />
									</Form.Item>
									<Form.Item label="Единица измерения" name="measurementId">
										{measuresError && currentMeasureGroup ? (
											<Input
												value="Не удалось получить данные для выбора Единицы измерения!"
												disabled
											/>
										) : (
											<DropdownSelect
												loading={measuresLoading}
												onChange={handleBlur}
												options={currentMeasureGroup?.childrenMeasures?.map(
													(item) => ({
														label: item.displayName,
														key: item.id,
														value: item.id,
													})
												)}
												value={formVals?.restrictions?.measurementId}
												disabled={!formVals.measurementGroupId}
												allowClear
											/>
										)}
									</Form.Item>
									<Form.Item label="Значение по умолчанию" name="defaultValue">
										{CellInputParser(
											form.getFieldValue('defaultValue'),
											formVals,
											handleEditComplexDefaultValue
										)}
									</Form.Item>
									{/* <Form.Item label="Формула" name="formulaId">
										<Input onBlur={handleBlur} allowClear />
									</Form.Item> */}
								</>
							)}
							{typeValue === 'INTEGER' && (
								<>
									<Form.Item label="Значение по умолчанию" name="defaultValue">
										{CellInputParser(
											form.getFieldValue('defaultValue'),
											formVals,
											handleEditComplexDefaultValue
										)}
									</Form.Item>
									{/* <Form.Item label="Формула" name="formulaId">
										<Input onBlur={handleBlur} allowClear />
									</Form.Item> */}
								</>
							)}
							{(typeValue === 'TEXT' ||
								typeValue === 'DATE_TIME' ||
								typeValue === 'COLOR') && (
								<>
									{/* <Form.Item label="Формула" name="formulaId">
										<Input onBlur={handleBlur} allowClear />
									</Form.Item> */}
									<Form.Item label="Значение по умолчанию">
										{CellInputParser(
											form.getFieldValue('defaultValue'),
											formVals,
											handleEditComplexDefaultValue
										)}
									</Form.Item>
								</>
							)}
							{/* {typeValue === 'FORMULA' && (
								<>
									<Form.Item label="Формула" name="formulaId">
										<Input onBlur={handleBlur} allowClear />
									</Form.Item>
								</>
							)} */}
							{/* {typeValue === 'RELATION' && (
								<>
									<Form.Item label="Формула" name="formulaId">
										<Input onBlur={handleBlur} allowClear />
									</Form.Item>
								</>
							)} */}
						</Form>
						<Flex vertical gap={12}>
							<Typography.Title level={3}>
								Применямость в справочниках
							</Typography.Title>
							<Flex gap={4}>
								{usageList?.length > 0 ? (
									usageList
								) : (
									<Typography.Text>
										Атрибут в справочниках не применяется
									</Typography.Text>
								)}
								{usageLoading && <Skeleton />}
								{usageError && (
									<Typography.Text>
										Ошибка при получении списка применимости в справочниках!
									</Typography.Text>
								)}
							</Flex>
						</Flex>
					</Flex>
				)}
			</Col>
			<DetailFooter customHandleBack={handleBackClick}>
				{metaAttributeId !== NEW_ENTITY && (
					<DeleteSimpleAttributeUi
						attributesId={[{ id: metaAttributeId }]}
						isButton={true}
					/>
				)}
			</DetailFooter>
		</>
	);
};

export const EditSimpleAttribute = React.memo(EditSimpleAttributeUi);
