import { DrawerProps } from 'antd';
import { DrawerStyles } from 'antd/es/drawer/DrawerPanel';
import { CSSProperties } from 'react';

export const RightDrawerStyles: DrawerStyles = {
	wrapper: { boxShadow: 'none' },
	content: { borderLeft: '1px solid rgba(21, 29, 52, 0.24)' },
};

export const drawerSize: DrawerProps['size'] = 'default';

export const containerStyle: CSSProperties = {
	position: 'absolute',
	right: '0',
	height: 'calc(100vh - 56px)',
	width: `${drawerSize === 'default' ? '378px' : '736px'}`,
	top: '0',
	overflow: 'hidden',
};

export const extraStyle: CSSProperties = {
	paddingRight: '20px',
	cursor: 'pointer',
};

export const ButtonStyle: CSSProperties = { zIndex: '1', cursor: 'pointer' };

export const DisabledButtonStyle: CSSProperties = { zIndex: '1', cursor: 'auto' };
