import { RiCloseLine, RiExpandUpDownLine, RiFolderCheckLine } from '@remixicon/react';
import { Button, Flex, Form, Modal, Skeleton, Spin, TableColumnsType, TreeSelectProps } from 'antd';
import { ChangeEventExtra } from 'rc-tree-select/lib/TreeSelect';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { translateType } from 'entities/metadata/metaAttributes/metaAttributes.model';
import { AttributeDto } from 'shared/api/generatedApi/newUdmiApi';
import { randomString, sortStrings } from 'shared/helpers';
import { colors } from 'shared/styles';
import { Chip, WorkTable } from 'shared/ui';
import { DropdownTreeSelect } from 'shared/ui/components/AppTreeSelect';
import { useAssignColumns } from './assignColumns.model';
import s from './assignColumns.module.scss';
import { formStyles } from './assignColumnStyles';

const columns: TableColumnsType<IRow> = [
	{
		title: 'Наименование',
		dataIndex: 'name',
		key: 'name',
		sorter: {
			compare: (a, b) => sortStrings(a.name, b.name),
			multiple: 1,
		},
		width: '30%',
		sortIcon: () => <RiExpandUpDownLine size={16} />,
		showSorterTooltip: false,
	},
	{
		title: 'Тип',
		dataIndex: 'type',
		key: 'type',
		sorter: {
			compare: (a, b) => sortStrings(a.type, b.type),
			multiple: 2,
		},
		width: '30%',
		sortIcon: () => <RiExpandUpDownLine size={16} />,
		showSorterTooltip: false,
	},
	{
		title: 'Множественный',
		dataIndex: 'multiple',
		key: 'multiple',
		sorter: {
			compare: (a, b) => sortStrings(a.multiple, b.multiple),
			multiple: 3,
		},
		width: '30%',
		sortIcon: () => <RiExpandUpDownLine size={16} />,
		showSorterTooltip: false,
	},
];

interface IRow {
	key: string;
	name: string;
	type: string;
	multiple: string;
	id: string;
	pid: string;
}

const AssignColumnUi = () => {
	const {
		getRootGroups,
		isRootGroupsLoading,
		getSubGroups,
		treeData,
		clearTreeData,
		getProperties,
		changeColumns,
		isAttributesLoading,
		isPatchTableLoading,
		isTableColumnsLoading,
		tableColumns,
	} = useAssignColumns();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const [selectedOptions, setSelectedOptions] = useState<React.Key[]>([]);

	const [dataSource, setDataSource] = useState<IRow[]>([]);
	const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);

	const [addedColumns, setAddedColumns] = useState<string[]>([]);
	const [deletedColumns, setDeletedColumns] = useState<string[]>([]);

	const showModal = () => {
		setIsModalOpen(true);
		getRootGroups();
	};

	const onLoadData: TreeSelectProps['loadData'] = ({ id }) => getSubGroups(id);

	const handleOk = () => {
		changeColumns(addedColumns, deletedColumns).finally(() => {
			setAddedColumns([]);
			setDeletedColumns([]);
			clearTreeData();
			setIsModalOpen(false);
		});
	};

	const handleCancel = useCallback(() => {
		setAddedColumns([]);
		setDeletedColumns([]);
		clearTreeData();
		setSelectedRows(tableColumns?.map((col) => col.id));
		setIsModalOpen(false);
	}, [tableColumns]);

	const propertiesToRowsMap = (properties: AttributeDto[]) =>
		properties.map((property) => {
			return {
				key: property.id,
				name: property.displayName,
				type: translateType(property.type),
				multiple: property.list ? 'Да' : 'Нет',
				id: property.id,
				pid: property.attributeGroupId,
			};
		});

	useEffect(() => {
		if (tableColumns) {
			setSelectedRows(tableColumns.map((col) => col.id));
		}
	}, [tableColumns]);

	const onSelectChange = (
		newSelected: React.Key[],
		_: React.ReactNode[],
		extra: ChangeEventExtra
	) => {
		setSelectedOptions(newSelected);
		if (extra.checked) {
			getProperties(extra.triggerValue.toString()).then((res) => {
				if (res) {
					setDataSource((prev) => [
						...(newSelected.length > 1 ? prev : []),
						...propertiesToRowsMap(res),
					]);
				}
			});
		} else if (newSelected.length > 0) {
			const newDataSource = dataSource.filter(
				(row) => row.pid !== extra.triggerValue.toString()
			);
			setDataSource(newDataSource);
		} else {
			setDataSource([]);
		}
	};

	const onDeleteColumn = (id: string) => {
		changeColumns([], [id]);
	};

	const footerButtons = useMemo(
		() => (
			<Flex key={randomString(4)} justify="flex-end" gap={4}>
				<Button key="back" onClick={handleCancel} type="text">
					Отменить
				</Button>

				<Button
					key="submit"
					type="primary"
					onClick={handleOk}
					disabled={addedColumns.length === 0 && deletedColumns.length === 0}
					loading={isPatchTableLoading}
				>
					Подтвердить
				</Button>
			</Flex>
		),
		[addedColumns, deletedColumns, isPatchTableLoading]
	);

	const rowSelection = {
		onSelect: (selectedRow: IRow, selected: boolean) => {
			const existingRow = tableColumns?.find((col) => col.id === selectedRow.id);
			if (existingRow) {
				selected
					? setDeletedColumns((prev) => prev.filter((id) => id !== existingRow.id))
					: setDeletedColumns((prev) => [...prev, existingRow.id]);
			} else {
				selected
					? setAddedColumns((prev) => [...prev, selectedRow.id])
					: setAddedColumns((prev) => prev.filter((id) => id !== selectedRow.id));
			}
			selected
				? setSelectedRows((prev) => [...prev, selectedRow.key])
				: setSelectedRows((prev) => prev.filter((id) => id !== selectedRow.key));
		},
		selectedRowKeys: selectedRows,
		getCheckboxProps: (record: { [key: string]: string }) => ({
			disabled: record.name === 'Disabled User',
			name: record.name,
		}),
	};

	return (
		<>
			<Form layout="vertical" style={formStyles}>
				<Form.Item label="Колонки таблицы">
					{isTableColumnsLoading || isPatchTableLoading ? (
						<Skeleton.Input active block />
					) : (
						<div className={s.container}>
							<div className={s.input}>
								{tableColumns?.map((item) => (
									<Chip
										selectChip
										cancel={
											<RiCloseLine
												size={10}
												color={colors.grayIcon}
												className={s.icon}
												onClick={() => onDeleteColumn(item.id)}
											/>
										}
										key={item.id}
									>
										{item.displayName}
									</Chip>
								))}
							</div>

							<Flex gap={4} align="center" className={s.controls}>
								<RiFolderCheckLine
									size={16}
									color={colors.grayIcon}
									onClick={showModal}
									className={s.icon}
								/>
							</Flex>
						</div>
					)}
				</Form.Item>
			</Form>
			<Modal
				title="Выбор наименования колонки"
				open={isModalOpen}
				closable={false}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1288}
				footer={footerButtons}
			>
				{isRootGroupsLoading ? (
					<Flex style={{ width: '100%', height: '100%' }} align="center" justify="center">
						<Spin />
					</Flex>
				) : (
					<Flex vertical gap={24}>
						<Form layout="vertical">
							<Form.Item label="Наименование группы">
								<DropdownTreeSelect
									treeDataSimpleMode
									treeData={treeData}
									treeCheckable
									treeCheckStrictly
									showCheckedStrategy="SHOW_PARENT"
									loadData={onLoadData}
									allowClear
									onChange={onSelectChange}
									value={selectedOptions}
									showSearch={false}
								/>
							</Form.Item>
						</Form>
						<WorkTable
							noFilters
							loading={isAttributesLoading}
							columns={columns}
							dataSource={dataSource}
							pagination={{
								showTotal: (total) => `Всего: ${total}`,
								pageSizeOptions: [10, 20, 30],
								showSizeChanger: true,
								size: 'small',
							}}
							rowSelection={rowSelection}
						/>
					</Flex>
				)}
			</Modal>
		</>
	);
};

export const AssignColumn = React.memo(AssignColumnUi);
