import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { addNewAttributeDeclaration } from 'entities/catalogs/attributeDeclarations/attributeDeclarations.store';
import { DisplayTreeNode } from 'entities/catalogs/catalogGroups/catalog.model';
import {
	useMetaAttributesGroups,
	AttributeGroupItem,
} from 'entities/metadata/attributesGroups/attributesGroups.model';
import { useTransactions } from 'entities/transactions';
import {
	AttributeDeclarationDto,
	AttributeDto,
	AttributeGroupDto,
	AttributeGroupTreeNode,
} from 'shared/api/generatedApi/newUdmiApi';
import { useAddCreateAttributeDeclarationGroupEvent1Mutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';

export const useAddAttributeTree = () => {
	const {
		attrList,
		currAttrGroup,
		getAttributes,
		loading,
		attributesGroupsError,
		currentAttrubuteGroupError,
	} = useMetaAttributesGroups();
	const [tree, setTree] = useState<Array<DisplayTreeNode>>([]);
	const [selectedTreeItem, setSelectedTreeItem] = useState<DisplayTreeNode>();

	const { catalogGroupId } = useParams();

	const dispatch = useAppDispatch();
	const { metaAttributes } = useAppSelector((state) => state.entities.metadata.metaAttributes);

	const [addAttr] = useAddCreateAttributeDeclarationGroupEvent1Mutation();

	const { getTransactions } = useTransactions();

	const formatToTree = (data: Array<AttributeGroupDto | AttributeGroupTreeNode>) =>
		data?.map((attributeGroup) => ({
			...attributeGroup,
			key: attributeGroup.id,
			title: attributeGroup.displayName,
			value: attributeGroup.id,
			isLeaf: !attributeGroup.parent,
			...(attributeGroup?.parent &&
				'children' in attributeGroup &&
				attributeGroup?.children?.length && {
					children: formatToTree(attributeGroup.children),
				}),
		}));

	const findAttrGroupById = (
		tree: DisplayTreeNode[],
		attributeGroupGroupId: string
	): DisplayTreeNode | null => {
		for (const item of tree) {
			if (item.id === attributeGroupGroupId) {
				return item;
			}
			if (item.children) {
				const found = findAttrGroupById(item.children, attributeGroupGroupId);
				if (found) {
					return found;
				}
			}
		}
		return null;
	};

	const selectCurrentAttributeGroup = (attributeGroup: DisplayTreeNode | null) => {
		if (attributeGroup) {
			const item = findAttrGroupById(tree, attributeGroup.id);
			setSelectedTreeItem(item);
		} else {
			setSelectedTreeItem(null);
		}
	};

	const addMenuToTreeNodeItems = (arr: Array<AttributeGroupItem>) => {
		return arr.map((item) => ({
			...item,
			title: item.displayName,
			...(item.children && { children: addMenuToTreeNodeItems(item.children) }),
		}));
	};

	const updateTreeData = (list, id, children) =>
		list.map((node) => {
			if (node.id === id) {
				return {
					...node,
					children,
				};
			}
			if ('children' in node && node.children) {
				return {
					...node,
					children: updateTreeData(node.children, id, children),
				};
			}
			return node;
		});

	const onExpandTree = async (parentId: string) => {
		const data = await getAttributes(parentId);

		if (data) {
			const newData = updateTreeData(tree, parentId, formatToTree([currAttrGroup])[0]);
			setTree(addMenuToTreeNodeItems(newData));
		}
	};

	const updateCatalogAttributes = async (arr) => {
		const data = await addAttr({
			body: arr.map((item) => ({
				catalogId: catalogGroupId,
				isCopy: false,
				attributeId: item.id,
			})),
		});

		if ('error' in data) {
			errorHelper('Ошибка при добавлении атрибута в справочник!', data.error, notification);
		}
		if ('data' in data) {
			getTransactions();
			data.data.persistedEvents.forEach((element) => {
				dispatch(
					addNewAttributeDeclaration({
						...(element.data as unknown as AttributeDeclarationDto),
						attribute: {
							...(metaAttributes.find(
								(item) =>
									item.id === (element.data.attributeId as unknown as string)
							) as unknown as AttributeDeclarationDto),
						} as unknown as AttributeDto,
					})
				);
			});
			return true;
		}
	};

	useEffect(() => {
		if (attrList.length) {
			const formattedTree = formatToTree(attrList);
			setTree(addMenuToTreeNodeItems(formattedTree));
		}
	}, [attrList, currAttrGroup]);

	return {
		tree,
		selectedTreeItem,
		selectCurrentAttributeGroup,
		onExpandTree,
		updateCatalogAttributes,
		loading,
		error: attributesGroupsError || currentAttrubuteGroupError,
	};
};
