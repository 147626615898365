import { App } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { deleteMetaAttributeItems } from 'entities/metadata/metaAttributes/metaAttributes.store';
import { useTransactions } from 'entities/transactions';
import {
	DeleteEntityRequest,
	useAddDeleteAttributeGroupEventMutation,
} from 'shared/api/generatedApi/transactionServiceApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useDeleteSimpleAttribute = () => {
	const { notification } = App.useApp();

	const [fetchDeleteAttribute, { isLoading, error }] = useAddDeleteAttributeGroupEventMutation();

	const dispatch = useAppDispatch();

	const navigate = useNavigate();
	const { metaAttributeGroupId, metaAttributeId } = useParams();

	const { getTransactions } = useTransactions();

	const deleteAttribute = async (idArr: Array<DeleteEntityRequest>) => {
		const data = await fetchDeleteAttribute({
			body: idArr,
		});
		if ('error' in data) {
			errorHelper('Ошибка при добавлении новой группы атрибутов!', data.error, notification);
		}

		if ('data' in data) {
			getTransactions();
			dispatch(deleteMetaAttributeItems(idArr));
			if (metaAttributeId)
				navigate(
					`/${routes.metadata.main}/${routes.metadata.group}/${metaAttributeGroupId}`
				);
		}
	};

	return {
		loading: isLoading,
		error,
		deleteAttribute,
	};
};
