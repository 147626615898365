import { CSSProperties } from 'react';
import { colors } from '../../../styles';

export const sidebarItemsStyles: CSSProperties = {
	marginBottom: 25,
	color: '#4760CB',
	fontWeight: 700,
	fontSize: 14,
};
export const siderStyles: CSSProperties = {
	backgroundColor: 'white',
	borderRight: `1px solid ${colors.gray}`,
};
export const menuStyles: CSSProperties = {
	...siderStyles,
	paddingTop: '10px',
	borderRight: 'none',
};
