import { newUdmiApi as api } from './newCreateApiFile';
const injectedRtkApi = api.injectEndpoints({
	endpoints: (build) => ({
		getRestrictionTables: build.query<
			GetRestrictionTablesApiResponse,
			GetRestrictionTablesApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/restriction-tables',
				headers: { Authorization: queryArg.authorization },
				params: { ids: queryArg.ids },
			}),
		}),
		getRestrictionTable: build.query<GetRestrictionTableApiResponse, GetRestrictionTableApiArg>(
			{
				query: (queryArg) => ({
					url: `/api/v1/restriction-tables/${queryArg.id}`,
					headers: { Authorization: queryArg.authorization },
				}),
			}
		),
		getRestrictionTableDeclarations: build.query<
			GetRestrictionTableDeclarationsApiResponse,
			GetRestrictionTableDeclarationsApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/restriction-table-declarations',
				headers: { Authorization: queryArg.authorization },
				params: { ids: queryArg.ids, catalogId: queryArg.catalogId },
			}),
		}),
		getRestrictionTableDeclaration: build.query<
			GetRestrictionTableDeclarationApiResponse,
			GetRestrictionTableDeclarationApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v1/restriction-table-declarations/${queryArg.id}`,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		getMeasurements: build.query<GetMeasurementsApiResponse, GetMeasurementsApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/measurements',
				headers: { Authorization: queryArg.authorization },
				params: { ids: queryArg.ids, measurementGroupId: queryArg.measurementGroupId },
			}),
		}),
		getMeasurement: build.query<GetMeasurementApiResponse, GetMeasurementApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/measurements/${queryArg.id}`,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		getMeasurementGroups: build.query<
			GetMeasurementGroupsApiResponse,
			GetMeasurementGroupsApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/measurement-groups',
				headers: { Authorization: queryArg.authorization },
				params: { ids: queryArg.ids },
			}),
		}),
		getMeasurementGroup: build.query<GetMeasurementGroupApiResponse, GetMeasurementGroupApiArg>(
			{
				query: (queryArg) => ({
					url: `/api/v1/measurement-groups/${queryArg.id}`,
					headers: { Authorization: queryArg.authorization },
					params: { includeChildren: queryArg.includeChildren },
				}),
			}
		),
		getItems: build.query<GetItemsApiResponse, GetItemsApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/items',
				headers: { Authorization: queryArg.authorization },
				params: {
					limit: queryArg.limit,
					page: queryArg.page,
					sortBy: queryArg.sortBy,
					sortType: queryArg.sortType,
					catalogId: queryArg.catalogId,
					includeSubCatalogs: queryArg.includeSubCatalogs,
					attributeDeclarationIds: queryArg.attributeDeclarationIds,
					ids: queryArg.ids,
				},
			}),
		}),
		getItem: build.query<GetItemApiResponse, GetItemApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/items/${queryArg.id}`,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		getClassifiers: build.query<GetClassifiersApiResponse, GetClassifiersApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/classifiers',
				headers: { Authorization: queryArg.authorization },
				params: { ids: queryArg.ids },
			}),
		}),
		getClassifier: build.query<GetClassifierApiResponse, GetClassifierApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/classifiers/${queryArg.id}`,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		getClassifierItems: build.query<GetClassifierItemsApiResponse, GetClassifierItemsApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/classifier-items',
				headers: { Authorization: queryArg.authorization },
				params: {
					limit: queryArg.limit,
					page: queryArg.page,
					sortBy: queryArg.sortBy,
					sortType: queryArg.sortType,
					classifierId: queryArg.classifierId,
					parentItemId: queryArg.parentItemId,
					root: queryArg.root,
					ids: queryArg.ids,
				},
			}),
		}),
		getClassifierItem: build.query<GetClassifierItemApiResponse, GetClassifierItemApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/classifier-items/${queryArg.id}`,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		getClassifierItemTree: build.query<
			GetClassifierItemTreeApiResponse,
			GetClassifierItemTreeApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v1/classifier-items/${queryArg.id}/tree`,
				headers: { Authorization: queryArg.authorization },
				params: {
					direction: queryArg.direction,
					includeChildren: queryArg.includeChildren,
				},
			}),
		}),
		getItemsByClassifierItemId: build.query<
			GetItemsByClassifierItemIdApiResponse,
			GetItemsByClassifierItemIdApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v1/classifier-items/${queryArg.id}/items`,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		getAttributeDeclarations: build.query<
			GetAttributeDeclarationsApiResponse,
			GetAttributeDeclarationsApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/classifier-attribute-declarations',
				headers: { Authorization: queryArg.authorization },
				params: { classifierId: queryArg.classifierId, ids: queryArg.ids },
			}),
		}),
		getAttributeDeclaration: build.query<
			GetAttributeDeclarationApiResponse,
			GetAttributeDeclarationApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v1/classifier-attribute-declarations/${queryArg.id}`,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		getCatalogs: build.query<GetCatalogsApiResponse, GetCatalogsApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/catalogs',
				headers: { Authorization: queryArg.authorization },
				params: { parentId: queryArg.parentId, root: queryArg.root, ids: queryArg.ids },
			}),
		}),
		getCatalog: build.query<GetCatalogApiResponse, GetCatalogApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/catalogs/${queryArg.id}`,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		getCatalogTree: build.query<GetCatalogTreeApiResponse, GetCatalogTreeApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/catalogs/${queryArg.id}/tree`,
				headers: { Authorization: queryArg.authorization },
				params: {
					direction: queryArg.direction,
					includeChildren: queryArg.includeChildren,
				},
			}),
		}),
		getAttributes: build.query<GetAttributesApiResponse, GetAttributesApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/attributes',
				headers: { Authorization: queryArg.authorization },
				params: {
					limit: queryArg.limit,
					page: queryArg.page,
					sortBy: queryArg.sortBy,
					sortType: queryArg.sortType,
					type: queryArg['type'],
					ids: queryArg.ids,
					attributeGroupId: queryArg.attributeGroupId,
					includeSubGroups: queryArg.includeSubGroups,
				},
			}),
		}),
		getAttribute: build.query<GetAttributeApiResponse, GetAttributeApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/attributes/${queryArg.id}`,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		getCatalogsByAttribute: build.query<
			GetCatalogsByAttributeApiResponse,
			GetCatalogsByAttributeApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v1/attributes/${queryArg.id}/usage`,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		getClassifierByItemId: build.query<
			GetClassifierByItemIdApiResponse,
			GetClassifierByItemIdApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v1/attributes/${queryArg.id}/items`,
				headers: { Authorization: queryArg.authorization },
				params: { classifierItemId: queryArg.classifierItemId },
			}),
		}),
		getAttributeGroup: build.query<GetAttributeGroupApiResponse, GetAttributeGroupApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/attribute-groups',
				headers: { Authorization: queryArg.authorization },
				params: { parentId: queryArg.parentId, root: queryArg.root, ids: queryArg.ids },
			}),
		}),
		getAttributeGroup1: build.query<GetAttributeGroup1ApiResponse, GetAttributeGroup1ApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/attribute-groups/${queryArg.id}`,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		getAttributeGroupTree: build.query<
			GetAttributeGroupTreeApiResponse,
			GetAttributeGroupTreeApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v1/attribute-groups/${queryArg.id}/tree`,
				headers: { Authorization: queryArg.authorization },
				params: {
					direction: queryArg.direction,
					includeChildren: queryArg.includeChildren,
				},
			}),
		}),
		getAttributeDeclarations1: build.query<
			GetAttributeDeclarations1ApiResponse,
			GetAttributeDeclarations1ApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/attribute-declarations',
				headers: { Authorization: queryArg.authorization },
				params: { catalogId: queryArg.catalogId, ids: queryArg.ids },
			}),
		}),
		getAttributeDeclaration1: build.query<
			GetAttributeDeclaration1ApiResponse,
			GetAttributeDeclaration1ApiArg
		>({
			query: (queryArg) => ({
				url: `/api/v1/attribute-declarations/${queryArg.id}`,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
	}),
	overrideExisting: false,
});
export { injectedRtkApi as newUdmiApi };
export type GetRestrictionTablesApiResponse = /** status 200 OK */ RestrictionTableWithCountDto[];
export type GetRestrictionTablesApiArg = {
	authorization?: string;
	ids?: string[];
};
export type GetRestrictionTableApiResponse = /** status 200 OK */ RestrictionTableDto;
export type GetRestrictionTableApiArg = {
	authorization?: string;
	id: string;
};
export type GetRestrictionTableDeclarationsApiResponse =
	/** status 200 OK */ JoinRestrictionTableDeclarationDto[];
export type GetRestrictionTableDeclarationsApiArg = {
	authorization?: string;
	ids?: string[];
	catalogId: string;
};
export type GetRestrictionTableDeclarationApiResponse =
	/** status 200 OK */ RestrictionTableDeclarationDto;
export type GetRestrictionTableDeclarationApiArg = {
	authorization?: string;
	id: string;
};
export type GetMeasurementsApiResponse = /** status 200 OK */ MeasurementDto[];
export type GetMeasurementsApiArg = {
	authorization?: string;
	ids?: string[];
	measurementGroupId?: string;
};
export type GetMeasurementApiResponse = /** status 200 OK */ MeasurementDto;
export type GetMeasurementApiArg = {
	authorization?: string;
	id: string;
};
export type GetMeasurementGroupsApiResponse = /** status 200 OK */ MeasurementGroupDto[];
export type GetMeasurementGroupsApiArg = {
	authorization?: string;
	ids?: string[];
};
export type GetMeasurementGroupApiResponse =
	/** status 200 OK */ MeasurementGroupWithMeasurementsDto;
export type GetMeasurementGroupApiArg = {
	authorization?: string;
	id: string;
	includeChildren?: boolean;
};
export type GetItemsApiResponse = /** status 200 OK */ SliceItemDto;
export type GetItemsApiArg = {
	authorization?: string;
	limit?: number;
	page?: number;
	sortBy?: string;
	sortType?: string;
	catalogId?: string;
	includeSubCatalogs?: boolean;
	attributeDeclarationIds?: string[];
	ids?: string[];
};
export type GetItemApiResponse = /** status 200 OK */ ItemDto;
export type GetItemApiArg = {
	authorization?: string;
	id: string;
};
export type GetClassifiersApiResponse = /** status 200 OK */ ClassifierDtoWithAttributeName[];
export type GetClassifiersApiArg = {
	authorization?: string;
	ids?: string[];
};
export type GetClassifierApiResponse = /** status 200 OK */ ClassifierDtoWithAttributeName;
export type GetClassifierApiArg = {
	authorization?: string;
	id: string;
};
export type GetClassifierItemsApiResponse = /** status 200 OK */ SliceClassifierItemDto;
export type GetClassifierItemsApiArg = {
	authorization?: string;
	limit?: number;
	page?: number;
	sortBy?: string;
	sortType?: string;
	classifierId?: string;
	parentItemId?: string;
	root?: boolean;
	ids?: string[];
};
export type GetClassifierItemApiResponse = /** status 200 OK */ ClassifierItemDto;
export type GetClassifierItemApiArg = {
	authorization?: string;
	id: string;
};
export type GetClassifierItemTreeApiResponse = /** status 200 OK */ ClassifierItemTreeNode;
export type GetClassifierItemTreeApiArg = {
	authorization?: string;
	id: string;
	direction: string;
	includeChildren?: boolean;
};
export type GetItemsByClassifierItemIdApiResponse = /** status 200 OK */ ItemDto[];
export type GetItemsByClassifierItemIdApiArg = {
	authorization?: string;
	id: string;
};
export type GetAttributeDeclarationsApiResponse =
	/** status 200 OK */ ClassifierAttributeDeclarationDto[];
export type GetAttributeDeclarationsApiArg = {
	authorization?: string;
	classifierId?: string;
	ids?: string[];
};
export type GetAttributeDeclarationApiResponse =
	/** status 200 OK */ ClassifierAttributeDeclarationDto;
export type GetAttributeDeclarationApiArg = {
	authorization?: string;
	id: string;
};
export type GetCatalogsApiResponse = /** status 200 OK */ CatalogDto[];
export type GetCatalogsApiArg = {
	authorization?: string;
	parentId?: string;
	root?: boolean;
	ids?: string[];
};
export type GetCatalogApiResponse = /** status 200 OK */ CatalogDto;
export type GetCatalogApiArg = {
	authorization?: string;
	id: string;
};
export type GetCatalogTreeApiResponse = /** status 200 OK */ CatalogTreeNode;
export type GetCatalogTreeApiArg = {
	authorization?: string;
	id: string;
	direction: string;
	includeChildren?: boolean;
};
export type GetAttributesApiResponse = /** status 200 OK */ SliceAttributeDto;
export type GetAttributesApiArg = {
	authorization?: string;
	limit?: number;
	page?: number;
	sortBy?: string;
	sortType?: string;
	type?: string;
	ids?: string[];
	attributeGroupId?: string;
	includeSubGroups?: boolean;
};
export type GetAttributeApiResponse = /** status 200 OK */ AttributeDto;
export type GetAttributeApiArg = {
	authorization?: string;
	id: string;
};
export type GetCatalogsByAttributeApiResponse = /** status 200 OK */ CatalogDto[];
export type GetCatalogsByAttributeApiArg = {
	authorization?: string;
	id: string;
};
export type GetClassifierByItemIdApiResponse = /** status 200 OK */ ItemDto[];
export type GetClassifierByItemIdApiArg = {
	authorization?: string;
	id: string;
	classifierItemId?: string;
};
export type GetAttributeGroupApiResponse = /** status 200 OK */ AttributeGroupDto[];
export type GetAttributeGroupApiArg = {
	authorization?: string;
	parentId?: string;
	root?: boolean;
	ids?: string[];
};
export type GetAttributeGroup1ApiResponse = /** status 200 OK */ AttributeGroupDto;
export type GetAttributeGroup1ApiArg = {
	authorization?: string;
	id: string;
};
export type GetAttributeGroupTreeApiResponse = /** status 200 OK */ AttributeGroupTreeNode;
export type GetAttributeGroupTreeApiArg = {
	authorization?: string;
	id: string;
	direction: string;
	includeChildren?: boolean;
};
export type GetAttributeDeclarations1ApiResponse = /** status 200 OK */ AttributeDeclarationDto[];
export type GetAttributeDeclarations1ApiArg = {
	authorization?: string;
	catalogId?: string;
	ids?: string[];
};
export type GetAttributeDeclaration1ApiResponse = /** status 200 OK */ AttributeDeclarationDto;
export type GetAttributeDeclaration1ApiArg = {
	authorization?: string;
	id: string;
};
export type RestrictionTableWithCountDto = {
	id: string;
	displayName: string;
	description?: string;
	count: number;
	createdAt: string;
	updatedAt: string;
};
export type RestrictionTableDto = {
	id: string;
	displayName: string;
	description?: string;
	attributeIds: string[];
	attributeValues: {
		[key: string]: object;
	}[];
	createdAt: string;
	updatedAt: string;
};
export type JoinRestrictionTableDeclarationDto = {
	id: string;
	required: boolean;
	generationValues: boolean;
	restrictionTable: RestrictionTableDto;
	catalogId: string;
	createdAt: string;
	updatedAt: string;
};
export type RestrictionTableDeclarationDto = {
	id: string;
	required: boolean;
	generationValues: boolean;
	restrictionTableId: string;
	catalogId: string;
	createdAt: string;
	updatedAt: string;
};
export type MeasurementDto = {
	id: string;
	displayName: string;
	shortName?: string;
	isDefault: boolean;
	measurementGroupId: string;
	formula?: string;
	inverseFormula?: string;
	coefficient?: number;
	createdAt: string;
	updatedAt: string;
};
export type MeasurementGroupDto = {
	id: string;
	displayName: string;
	description?: string;
	baseMeasurementId?: string;
	createdAt: string;
	updatedAt: string;
};
export type MeasurementGroupWithMeasurementsDto = {
	measurementGroup: MeasurementGroupDto;
	measurements?: MeasurementDto[];
};
export type ItemDto = {
	id: string;
	displayName?: string;
	catalogId: string;
	values: {
		[key: string]: object;
	};
	status: 'NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE';
	createdAt: string;
	updatedAt: string;
};
export type SliceMeta = {
	page: number;
	limit: number;
	total: number;
	sortBy: string;
	sortType: string;
};
export type SliceItemDto = {
	data: ItemDto[];
	meta: SliceMeta;
};
export type AttributeIdAndName = {
	id: string;
	displayName: string;
};
export type ClassifierDtoWithAttributeName = {
	id: string;
	displayName: string;
	description?: string;
	groupId?: string;
	linkClassifierAttributes?: AttributeIdAndName[];
	createFormulaId?: string;
	updateFormulaId?: string;
	createdAt: string;
	updatedAt: string;
	displayNameAttributeDeclarationId?: string,
};
export type ClassifierItemDto = {
	id: string;
	description?: string;
	displayName?: string;
	parent: boolean;
	status: 'NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE';
	parentItemId?: string;
	classifierId: string;
	values: {
		[key: string]: object;
	};
	createdAt: string;
	updatedAt: string;
};
export type SliceClassifierItemDto = {
	data: ClassifierItemDto[];
	meta: SliceMeta;
};
export type ClassifierItemTreeNode = {
	id: string;
	description?: string;
	displayName?: string;
	parent: boolean;
	status: 'NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE';
	parentItemId?: string;
	classifierId: string;
	values: {
		[key: string]: object;
	};
	createdAt: string;
	updatedAt: string;
	children?: ClassifierItemTreeNode[];
};
export type AttributeDto = {
	id: string;
	displayName: string;
	description?: string;
	type:
		| 'STRING'
		| 'FLOAT'
		| 'INTEGER'
		| 'BOOLEAN'
		| 'IMAGE'
		| 'TEXT'
		| 'DATE_TIME'
		| 'COLOR'
		| 'FILE'
		| 'FORMULA'
		| 'RELATION';
	list: boolean;
	associatedAttributeIds?: string[];
	attributeGroupId: string;
	measurementGroupId?: string;
	createdAt: string;
	updatedAt: string;
	restrictions: {
		[key: string]: object;
	};
};
export type ClassifierAttributeDeclarationDto = {
	id: string;
	classifierId: string;
	attribute: AttributeDto;
	inherited: boolean;
	measurementId?: string;
	createdAt: string;
	updatedAt: string;
	restrictions: {
		[key: string]: object;
	};
};
export type CatalogDto = {
	id: string;
	displayName: string;
	description?: string;
	parentId?: string;
	createFormulaId?: string;
	updateFormulaId?: string;
	parent: boolean;
	createdAt: string;
	updatedAt: string;
	displayNameAttributeDeclarationId?: string;
};
export type CatalogTreeNode = {
	id: string;
	displayName: string;
	description?: string;
	parentId?: string;
	createFormulaId?: string;
	updateFormulaId?: string;
	parent: boolean;
	createdAt: string;
	updatedAt: string;
	children?: CatalogTreeNode[];
};
export type SliceAttributeDto = {
	data: AttributeDto[];
	meta: SliceMeta;
};
export type AttributeGroupDto = {
	id: string;
	displayName: string;
	description?: string;
	parentId?: string;
	parent: boolean;
	createdAt: string;
	updatedAt: string;
};
export type AttributeGroupTreeNode = {
	id: string;
	displayName: string;
	description?: string;
	parentId?: string;
	parent: boolean;
	createdAt: string;
	updatedAt: string;
	children?: AttributeGroupTreeNode[];
};
export type AttributeDeclarationDto = {
	id: string;
	catalogId: string;
	attribute: AttributeDto;
	inherited: boolean;
	measurementId?: string;
	createdAt: string;
	updatedAt: string;
	restrictions: {
		[key: string]: object;
	};
};
export const {
	useGetRestrictionTablesQuery,
	useLazyGetRestrictionTablesQuery,
	useGetRestrictionTableQuery,
	useLazyGetRestrictionTableQuery,
	useGetRestrictionTableDeclarationsQuery,
	useLazyGetRestrictionTableDeclarationsQuery,
	useGetRestrictionTableDeclarationQuery,
	useLazyGetRestrictionTableDeclarationQuery,
	useGetMeasurementsQuery,
	useLazyGetMeasurementsQuery,
	useGetMeasurementQuery,
	useLazyGetMeasurementQuery,
	useGetMeasurementGroupsQuery,
	useLazyGetMeasurementGroupsQuery,
	useGetMeasurementGroupQuery,
	useLazyGetMeasurementGroupQuery,
	useGetItemsQuery,
	useLazyGetItemsQuery,
	useGetItemQuery,
	useLazyGetItemQuery,
	useGetClassifiersQuery,
	useLazyGetClassifiersQuery,
	useGetClassifierQuery,
	useLazyGetClassifierQuery,
	useGetClassifierItemsQuery,
	useLazyGetClassifierItemsQuery,
	useGetClassifierItemQuery,
	useLazyGetClassifierItemQuery,
	useGetClassifierItemTreeQuery,
	useLazyGetClassifierItemTreeQuery,
	useGetItemsByClassifierItemIdQuery,
	useLazyGetItemsByClassifierItemIdQuery,
	useGetAttributeDeclarationsQuery,
	useLazyGetAttributeDeclarationsQuery,
	useGetAttributeDeclarationQuery,
	useLazyGetAttributeDeclarationQuery,
	useGetCatalogsQuery,
	useLazyGetCatalogsQuery,
	useGetCatalogQuery,
	useLazyGetCatalogQuery,
	useGetCatalogTreeQuery,
	useLazyGetCatalogTreeQuery,
	useGetAttributesQuery,
	useLazyGetAttributesQuery,
	useGetAttributeQuery,
	useLazyGetAttributeQuery,
	useGetCatalogsByAttributeQuery,
	useLazyGetCatalogsByAttributeQuery,
	useGetClassifierByItemIdQuery,
	useLazyGetClassifierByItemIdQuery,
	useGetAttributeGroupQuery,
	useLazyGetAttributeGroupQuery,
	useGetAttributeGroup1Query,
	useLazyGetAttributeGroup1Query,
	useGetAttributeGroupTreeQuery,
	useLazyGetAttributeGroupTreeQuery,
	useGetAttributeDeclarations1Query,
	useLazyGetAttributeDeclarations1Query,
	useGetAttributeDeclaration1Query,
	useLazyGetAttributeDeclaration1Query,
} = injectedRtkApi;
