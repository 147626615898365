import { RiAddCircleLine, RiArrowDownSLine, RiArrowUpSLine } from '@remixicon/react';
import { Button, Collapse, Flex, Spin } from 'antd';
import React from 'react';
import { useLocation } from 'react-router';
import { colors } from 'shared/styles';
import { useCatalogDeduplication } from './CatalogDeduplicationWidget.model';
import s from './CatalogDeduplicationWidget.module.scss';

export const CatalogDeduplicationWidget = () => {
	const { loadingDuplicationOptions, addingDeduplicationOption, items, addDeduplicationOption } =
		useCatalogDeduplication();

	const location = useLocation();

	// TODO переделать на роут
	const defaultDeduplicationOption = location.state?.deduplicationOptionId
		? location.state?.deduplicationOptionId
		: null;

	return (
		<Flex vertical gap={24}>
			<div>
				<Button
					loading={addingDeduplicationOption}
					icon={<RiAddCircleLine size={16} />}
					onClick={addDeduplicationOption}
				>
					Добавить вариант поиска дубликатов
				</Button>
			</div>
			{loadingDuplicationOptions && <Spin />}
			{!!items.length && (
				<Collapse
					items={items}
					ghost
					size="small"
					className={s.collapse_deduplication}
					expandIcon={({ isActive }) =>
						isActive ? (
							<RiArrowUpSLine size={24} color={colors.grayIcon} />
						) : (
							<RiArrowDownSLine size={24} color={colors.grayIcon} />
						)
					}
					defaultActiveKey={defaultDeduplicationOption}
				/>
			)}
		</Flex>
	);
};
