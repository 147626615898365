import { App } from 'antd';
import {
	CreateConnectionMdmIntegrationsV1ConnectionsPostApiArg,
	useCreateConnectionMdmIntegrationsV1ConnectionsPostMutation,
	useGetSupportedSystemsMdmIntegrationsV1ConnectionsSystemsSupportedSystemsGetQuery,
} from 'shared/api/generatedApi/integrationsApi';
import { errorHelper } from 'shared/helpers';

export const useCreateNewIntegration = () => {
	const { notification } = App.useApp();

	const { data, error, isLoading } =
		useGetSupportedSystemsMdmIntegrationsV1ConnectionsSystemsSupportedSystemsGetQuery({
			page: 1,
			pageSize: 10,
		});

	const [createConnection, { isLoading: createConnectionLoading }] =
		useCreateConnectionMdmIntegrationsV1ConnectionsPostMutation();

	const create = async (
		params: CreateConnectionMdmIntegrationsV1ConnectionsPostApiArg
	): Promise<boolean> => {
		return await createConnection(params)
			.unwrap()
			.then(() => {
				notification.success({ message: 'Подключение успешно создано!' });
				return true;
			})
			.catch((e) => {
				errorHelper('Ошибка при создании подключения!', e, notification);
				return false;
			});
	};

	const connectionList = data ? data?.data : undefined;

	return {
		connectionList,
		isLoading,
		error,
		createConnectionLoading,
		create,
	};
};
