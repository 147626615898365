import React from 'react';

export const Logo = () => {
	return (
		<svg
			width="88"
			height="56"
			viewBox="0 0 88 56"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M8 14.0344H47.4827L44.6896 16.8275H8V14.0344Z" fill="#4760CB" />
			<path d="M11 38.9999H67.7948L67.0344 41.9655H8L11 38.9999Z" fill="#4760CB" />
			<path
				d="M64.2413 40.5689L71.2241 14.7327H74.0172L67.0344 41.9655L64.2413 40.5689Z"
				fill="#4760CB"
			/>
			<path d="M8 16.8275H11V38.9999L8 41.9655V16.8275Z" fill="#4760CB" />
			<path d="M1.39655 7.75H23.7414L20.9483 10.5431H1.39655V7.75Z" fill="#E97B2D" />
			<path d="M0 7.75H2.7931V18.9224L0 21.7155V7.75Z" fill="#E97B2D" />
			<path d="M1.39655 48.2499H23.7414L20.9483 45.4568H1.39655V48.2499Z" fill="#151D34" />
			<path d="M0 48.2499H2.7931V37.0775L0 34.2844V48.2499Z" fill="#151D34" />
			<path
				d="M13.9656 36.4071V20.3188H16.39L21.9539 31.7147L27.5177 20.3188H29.9421V36.4071H27.4283V26.095L22.4901 36.4071H21.4176L16.4905 26.095V36.4071H13.9656Z"
				fill="#151D34"
			/>
			<path
				d="M47.4828 36.4071V20.3188H49.9072L55.4711 31.7147L61.0349 20.3188H63.4593V36.4071H60.9455V26.095L56.0073 36.4071H54.9348L50.0078 26.095V36.4071H47.4828Z"
				fill="#151D34"
			/>
			<path
				d="M32.1207 36.4071V20.3188H37.2489C37.3829 20.3188 37.6473 20.3226 38.0421 20.33C38.4443 20.3375 38.8316 20.3635 39.204 20.4082C40.4777 20.5721 41.554 21.0264 42.4329 21.7713C43.3192 22.5086 43.9896 23.4508 44.4439 24.5979C44.8982 25.7449 45.1254 27 45.1254 28.363C45.1254 29.726 44.8982 30.9811 44.4439 32.1281C43.9896 33.2751 43.3192 34.2211 42.4329 34.9659C41.554 35.7033 40.4777 36.1539 39.204 36.3177C38.8391 36.3624 38.4555 36.3885 38.0533 36.396C37.6511 36.4034 37.3829 36.4071 37.2489 36.4071H32.1207ZM34.858 33.871H37.2489C37.4723 33.871 37.7553 33.8635 38.098 33.8486C38.448 33.8337 38.7571 33.8002 39.0253 33.7481C39.785 33.6066 40.4032 33.2677 40.8799 32.7314C41.3566 32.1951 41.7067 31.5434 41.9301 30.7762C42.161 30.0091 42.2765 29.2046 42.2765 28.363C42.2765 27.4915 42.161 26.6722 41.9301 25.9051C41.6992 25.1379 41.3417 24.4936 40.8576 23.9722C40.3809 23.4508 39.7701 23.1194 39.0253 22.9779C38.7571 22.9183 38.448 22.8848 38.098 22.8773C37.7553 22.8624 37.4723 22.855 37.2489 22.855H34.858V33.871Z"
				fill="#151D34"
			/>
			<path
				d="M80.1509 37.0775C79.1081 37.0775 78.1287 36.895 77.2125 36.53C76.3038 36.1576 75.5031 35.6139 74.8105 34.8988C74.1252 34.1838 73.5889 33.3086 73.2016 32.2733C72.8143 31.2306 72.6207 30.0388 72.6207 28.6982C72.6207 26.9404 72.9484 25.4395 73.6038 24.1957C74.2593 22.9444 75.1568 21.9873 76.2964 21.3244C77.436 20.654 78.7208 20.3188 80.1509 20.3188C82.1321 20.3188 83.7 20.7806 84.8545 21.7042C86.0164 22.6204 86.8022 23.9089 87.2118 25.5699L84.4634 26.0056C84.158 25.0522 83.659 24.2925 82.9663 23.7264C82.2736 23.1529 81.391 22.8662 80.3185 22.8662C79.2385 22.8513 78.3409 23.0859 77.6259 23.57C76.9109 24.0542 76.3709 24.7357 76.0059 25.6146C75.6484 26.4935 75.4696 27.5213 75.4696 28.6982C75.4696 29.875 75.6484 30.8991 76.0059 31.7706C76.3634 32.6346 76.8997 33.3086 77.6147 33.7928C78.3372 34.2769 79.2385 34.5264 80.3185 34.5413C81.1303 34.5488 81.8416 34.4035 82.4524 34.1056C83.0632 33.8002 83.5622 33.3422 83.9495 32.7314C84.3368 32.1132 84.5975 31.3423 84.7316 30.4187H81.8938V28.3071H87.5917C87.6066 28.4263 87.6178 28.6013 87.6252 28.8322C87.6327 29.0631 87.6364 29.2009 87.6364 29.2456C87.6364 30.7651 87.3347 32.1169 86.7314 33.3012C86.1356 34.478 85.279 35.4016 84.1618 36.072C83.0445 36.7423 81.7076 37.0775 80.1509 37.0775Z"
				fill="#151D34"
			/>
		</svg>
	);
};
