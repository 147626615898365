import { App } from 'antd';
import { setConstraintTableInfo } from 'entities/metadata/constraintTables';
import { useTransactions } from 'entities/transactions';
import { RestrictionTableDto } from 'shared/api/generatedApi/newUdmiApi';
import { useUpdateRestrictionTableMutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';

export const useDeleteRow = () => {
	const [patchTableInfo, { isLoading: isPatchTableInfoLoading }] =
		useUpdateRestrictionTableMutation();

	const dispatch = useAppDispatch();

	const { table } = useAppSelector((state) => state.entities.metadata.constraintTableDetails);

	const { notification } = App.useApp();

	const { getTransactions } = useTransactions();

	const deleteRows = async (rowIds: string[]) => {
		patchTableInfo({
			body: [
				{
					...table,
					attributeValues: table.attributeValues.filter(
						(row) => !rowIds.includes(row.id as unknown as string)
					),
				},
			],
		})
			.unwrap()
			.then((res) => {
				if (res.persistedEvents.length > 0) {
					dispatch(
						setConstraintTableInfo(
							res.persistedEvents[0].data as unknown as RestrictionTableDto
						)
					);
					getTransactions();
				}
			})
			.catch((err) =>
				errorHelper('Ошибка удаления строки ограничительной таблицы!', err, notification)
			);
	};

	return {
		deleteRows,
		isDeleteLoading: isPatchTableInfoLoading,
	};
};
