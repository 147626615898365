import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ItemDto } from 'shared/api/generatedApi/newUdmiApi';
import { ItemValuesType } from 'shared/helpers/types';

interface ICatalogRecordsState {
	catalogRecords: ItemDto[] | null;
}

const initialState: ICatalogRecordsState = {
	catalogRecords: null,
};

interface IDeleteRecordAction {
	id: string;
}

export type ItemValues = {
	[key: string]: ItemValuesType;
};

const catalogRecordsSlice = createSlice({
	initialState,
	name: 'catalogRecords',
	reducers: {
		setCatalogRecords(state, action: PayloadAction<ItemDto[]>) {
			state.catalogRecords = action.payload;
		},
		deleteCatalogRecord(state, action: PayloadAction<IDeleteRecordAction>) {
			state.catalogRecords = state.catalogRecords.filter(
				(record) => record.id !== action.payload.id
			);
		},
		addCatalogRecord(state, action: PayloadAction<ItemDto>) {
			state.catalogRecords = [
				action.payload,
				...(state.catalogRecords ? state.catalogRecords : []),
			];
		},
		removeCatalogRecords(state) {
			state.catalogRecords = null;
		},
	},
});

export const { deleteCatalogRecord, setCatalogRecords, addCatalogRecord, removeCatalogRecords } =
	catalogRecordsSlice.actions;

export const catalogRecordsReducer = catalogRecordsSlice.reducer;
