import { Result, Spin } from 'antd';
import React from 'react';
import { WorkTable } from 'shared/ui';
import { useEditCatalogRecord } from './editCatalogRecord.model';

const columns = [
	{
		title: 'Наименование атрибута',
		dataIndex: 'attributeName',
		key: 'attributeName',
	},
	{
		title: 'Значение',
		dataIndex: 'value',
		key: 'value',
	},
];

const EditCatalogRecordUi: React.FC = () => {
	const { recordsList, loading, error } = useEditCatalogRecord();
	if (error) {
		return <Result title="Ошибка!" subTitle={JSON.stringify(error)} />;
	}
	return (
		<>
			{loading && (
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Spin size="large" />
				</div>
			)}
			{!loading && (
				<WorkTable
					pagination={false}
					dataSource={recordsList}
					columns={columns}
					noFilters
				/>
			)}
		</>
	);
};

export const EditCatalogRecord = React.memo(EditCatalogRecordUi);
