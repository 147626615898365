import {
	BaseQueryFn,
	FetchArgs,
	fetchBaseQuery,
	FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import queryString from 'query-string';

export const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_URL,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).entities.session.accessToken;

		if (token) headers.set('Authorization', `Bearer ${token}`);

		headers.set('Accept', 'application/json, text/plain, */*');

		return headers;
	},
});

export const baseQueryWithAuthCheckForOldGeneratedQueries: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	const baseUrl = process.env.REACT_APP_API_URL + 'dmcore/rs';
	const baseQueryWithNewBaseUrl = fetchBaseQuery({
		baseUrl,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).entities.session.accessToken;

			if (token) headers.set('Authorization', `Bearer ${token}`);

			headers.set('Accept', 'application/json, text/plain, */*');
			headers.set('Content-type', 'application/json; charset=UTF-8');

			return headers;
		},
		paramsSerializer: (params: Record<string, unknown>) =>
			queryString.stringify(params, { arrayFormat: 'none' }),
	});
	const result = await baseQueryWithNewBaseUrl(args, api, extraOptions);
	return result;
};

export const baseQueryWithAuthCheckForNewGeneratedQueries: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	const baseUrl = process.env.REACT_APP_NEW_API_URL + 'core-state-service';
	const baseQueryWithNewBaseUrl = fetchBaseQuery({
		baseUrl,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).entities.session.accessToken;

			if (token) headers.set('Authorization', `Bearer ${token}`);

			return headers;
		},
		paramsSerializer: (params: Record<string, unknown>) =>
			queryString.stringify(params, { arrayFormat: 'none' }),
	});
	const result = await baseQueryWithNewBaseUrl(args, api, extraOptions);
	if (result.error) {
		if ('originalStatus' in result.error && result.error.originalStatus === 401) {
			window.location.reload();
		}
	}
	return result;
};

export const baseQueryWithAuthCheckForNewGenerateTransactionsServiceQueries: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	const baseUrl = process.env.REACT_APP_NEW_API_URL + 'transactions-service';
	const baseQueryWithNewBaseUrl = fetchBaseQuery({
		baseUrl,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).entities.session.accessToken;

			if (token) headers.set('Authorization', `Bearer ${token}`);

			return headers;
		},
		paramsSerializer: (params: Record<string, unknown>) =>
			queryString.stringify(params, { arrayFormat: 'none' }),
	});
	const result = await baseQueryWithNewBaseUrl(args, api, extraOptions);
	if (result.error) {
		if ('originalStatus' in result.error && result.error.originalStatus === 401) {
			window.location.reload();
		}
	}
	return result;
};
export const baseQueryWithAuthCheckForMeasurementServiceQueries: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	const baseUrl = process.env.REACT_APP_NEW_API_URL + 'measurements-service';
	const baseQueryWithNewBaseUrl = fetchBaseQuery({
		baseUrl,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).entities.session.accessToken;

			if (token) headers.set('Authorization', `Bearer ${token}`);

			return headers;
		},
		paramsSerializer: (params: Record<string, unknown>) =>
			queryString.stringify(params, { arrayFormat: 'none' }),
	});
	const result = await baseQueryWithNewBaseUrl(args, api, extraOptions);
	if (result.error) {
		if ('originalStatus' in result.error && result.error.originalStatus === 401) {
			window.location.reload();
		}
	}
	return result;
};

export const baseQueryWithAuthCheckForSearchServiceQueries: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	const baseUrl = process.env.REACT_APP_NEW_API_URL + 'search-service/api/v1';
	const baseQueryWithNewBaseUrl = fetchBaseQuery({
		baseUrl,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).entities.session.accessToken;

			if (token) headers.set('Authorization', `Bearer ${token}`);

			return headers;
		},
		paramsSerializer: (params: Record<string, unknown>) =>
			queryString.stringify(params, { arrayFormat: 'none' }),
	});
	const result = await baseQueryWithNewBaseUrl(args, api, extraOptions);
	if (result.error) {
		if ('originalStatus' in result.error && result.error.originalStatus === 401) {
			window.location.reload();
		}
	}
	return result;
};

export const baseQueryWithAuthCheckForDeduplicationServiceQueries: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	const baseUrl = process.env.REACT_APP_NEW_API_URL + 'deduplication-service';
	const baseQueryWithNewBaseUrl = fetchBaseQuery({
		baseUrl,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).entities.session.accessToken;

			if (token) headers.set('Authorization', `Bearer ${token}`);

			return headers;
		},
		paramsSerializer: (params: Record<string, unknown>) =>
			queryString.stringify(params, { arrayFormat: 'none' }),
	});
	const result = await baseQueryWithNewBaseUrl(args, api, extraOptions);
	if (result.error) {
		if ('originalStatus' in result.error && result.error.originalStatus === 401) {
			window.location.reload();
		}
	}
	return result;
};

export const baseQueryWithAuthCheckForIntegrationsServiceQueries: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	const baseUrl = process.env.REACT_APP_INTEGRATIONS_API_URL;
	const baseQueryWithNewBaseUrl = fetchBaseQuery({
		baseUrl,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).entities.session.accessToken;

			if (token) headers.set('Authorization', `Bearer ${token}`);

			return headers;
		},
		paramsSerializer: (params: Record<string, unknown>) =>
			queryString.stringify(params, { arrayFormat: 'none' }),
	});
	const result = await baseQueryWithNewBaseUrl(args, api, extraOptions);
	if (result.error) {
		if ('originalStatus' in result.error && result.error.originalStatus === 401) {
			window.location.reload();
		}
	}
	return result;
};

export const baseTimestampParam: Record<'timestamp', number> = {
	timestamp: Date.now(),
};

export const acceptJson = {
	accept: 'application/json, text/plain, */*',
};
