import { App } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { addCatalogRecord } from 'entities/catalogs/catalogRecords/catalogRecords.store';
import { ItemDto } from 'shared/api/generatedApi/newUdmiApi';
import { useAddCreateAttributeDeclarationGroupEventMutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useAddCatalogRecord = () => {
	const [fetchCreateCatalogRecord] = useAddCreateAttributeDeclarationGroupEventMutation();

	const { notification } = App.useApp();

	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const { catalogGroupId } = useParams();

	const createCatalogRecord = () => {
		fetchCreateCatalogRecord({
			body: [
				{
					catalogId: catalogGroupId,
					values: {},
					status: 'NON_NORMALIZED',
				},
			],
		})
			.unwrap()
			.then((res) => {
				if (res.persistedEvents.length > 0) {
					const newRecordId = res.persistedEvents[0].entityId;
					dispatch(addCatalogRecord(res.persistedEvents[0].data as unknown as ItemDto));

					navigate(`${window.location.pathname}/record/${newRecordId}`);
				}
			})
			.catch((err) => {
				errorHelper(
					'Ошибка при добавлении новой группы атрибутов!',
					err.error,
					notification
				);
			});
	};

	return {
		createCatalogRecord,
	};
};
