import { RiCheckLine } from '@remixicon/react';
import { Flex, Form, Input, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { ClassifierDtoWithAttributeName } from 'shared/api/generatedApi/newUdmiApi';
import { DropdownLink } from 'shared/ui';
import { SelectAttribute } from './components/SelectAttribute';
import { useEditClassifierMainInfo } from './editClassifierMainInfo.model';

interface IEditClassifierMainInfo {
	classifier: ClassifierDtoWithAttributeName;
	isDropdownLink?: boolean;
	displayNameAttributeDeclarationId?: string;
}

const EditClassifierMainInfoUi: React.FC<IEditClassifierMainInfo> = ({
	classifier,
	isDropdownLink,
	displayNameAttributeDeclarationId,
}) => {
	const [form] = Form.useForm();

	const { handleUpdate } = useEditClassifierMainInfo();

	const handleBlur = () => {
		const formVals = form.getFieldsValue();
		handleUpdate(formVals);
	};

	const dropdownLinkHandler = useCallback(async () => {
		const arg = {
			displayName: classifier.displayName,
			displayNameAttributeDeclarationId,
		};
		await handleUpdate(arg as ClassifierDtoWithAttributeName);
	}, [displayNameAttributeDeclarationId]);

	useEffect(() => {
		if (classifier) form.setFieldsValue(classifier);
	}, [classifier]);

	return isDropdownLink ? (
		<DropdownLink
			title="Установить, как название записи"
			icon={<RiCheckLine size={16} />}
			callback={dropdownLinkHandler}
		/>
	) : (
		<Flex vertical gap={12}>
			<Flex vertical gap={12}>
				<Typography.Title level={2}>Общая информация</Typography.Title>
				<Form layout="vertical" form={form}>
					<Form.Item label="Наименование" name="displayName" required>
						<Input onBlur={handleBlur} />
					</Form.Item>
					<Form.Item label="Описание" name="description">
						<Input onBlur={handleBlur} />
					</Form.Item>
				</Form>
			</Flex>
			<Flex vertical gap={12}>
				<Typography.Title level={2}>Настойка атрибутов для классификации</Typography.Title>
				<Form layout="vertical">
					<Form.Item label="Назначить атрибуты для классификации справочных данных">
						<SelectAttribute
							selectedAttributes={classifier?.linkClassifierAttributes}
						/>
					</Form.Item>
				</Form>
			</Flex>
		</Flex>
	);
};

export const EditClassifierMainInfo = React.memo(EditClassifierMainInfoUi);
