import { App } from 'antd';
import { useParams } from 'react-router';
import { useLazyGetSuspectedDuplicatesQuery } from 'shared/api/generatedApi/deduplicationApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';
import { setSuspectedDuplicates } from './catalogDeduplication.store';

export const useCatalogDeduplication = () => {
	const dispatch = useAppDispatch();
	const { notification } = App.useApp();
	const { deduplicationOptionId } = useParams();
	const [fetchGetSuspectedDuplicates, { isFetching: isSuspectedLoading }] =
		useLazyGetSuspectedDuplicatesQuery();

	const getSuspected = () => {
		fetchGetSuspectedDuplicates({
			id: deduplicationOptionId,
		})
			.unwrap()
			.then((res) => dispatch(setSuspectedDuplicates(res)))
			.catch((err) =>
				errorHelper('Ошибка при получении групп дубликатов', err, notification)
			);
	};

	return { getSuspected, isSuspectedLoading };
};
