import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AttributeDto, RestrictionTableDto } from 'shared/api/generatedApi/newUdmiApi';

export interface IConstraintTableDetailsStore {
	table: RestrictionTableDto | null;
	columns: AttributeDto[] | null;
}

const initialState: IConstraintTableDetailsStore = {
	table: null,
	columns: null,
};

export const constraintTableDetailsSlice = createSlice({
	name: 'constraintTableDetails',
	initialState,
	reducers: {
		setConstraintTableInfo(state, { payload }: PayloadAction<RestrictionTableDto>) {
			state.table = payload;
		},
		setConstraintTableColumns(state, { payload }: PayloadAction<AttributeDto[]>) {
			state.columns = payload;
		},
		removeConstraintTableDetails(state) {
			state.columns = null;
			state.table = null;
		},
	},
});

export const constraintTableDetailsReducer = constraintTableDetailsSlice.reducer;
export const { setConstraintTableInfo, setConstraintTableColumns, removeConstraintTableDetails } =
	constraintTableDetailsSlice.actions;
