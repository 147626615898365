const mainRoutes = {
	main: '/udmi/greeting',
};
const catalogsRoutes = {
	main: 'catalogs',
	classifier: 'classifier',
	record: 'record',
	deduplication: 'deduplication',
};
const metadataRoutes = {
	main: 'metadata',
	group: 'attributes',
	attribute: 'attribute',
	measures: 'measures',
	constraintTables: 'constraintTables',
};
const adminRoutes = {
	main: '/udmi/admin',
	users: 'users',
	groups: 'groups',
	journal: 'journal',
};
const settingsRoutes = {
	main: '/udmi/settings',
	basic: 'basic',
	integration: 'integration',
	debug: 'debug',
	plugins: 'plugins',
	db: 'db',
	utilities: 'utilities',
	test: 'test',
	gen: 'gen',
};
const loginRoutes = {
	main: '/login',
};
const integrationRoutes = {
	main: 'integrations',
	create: 'create',
	detail: 'detail',
	exchangeclass: 'exchangeclass',
	importformula: 'importformula',
	exportformula: 'exportformula',
	exportReplicaMessage: 'exportReplicaMessage',
	importReplicaMessage: 'importReplicaMessage',
};

const classifiersRoutes = {
	main: 'classifiers',
	records: 'records',
	edit: 'edit',
};

export const routes = {
	main: mainRoutes,
	catalogs: catalogsRoutes,
	metadata: metadataRoutes,
	classifiers: classifiersRoutes,
	admin: adminRoutes,
	integrations: integrationRoutes,
	settings: settingsRoutes,
	login: loginRoutes,
};
