import { App } from 'antd';
import { createAttributeMapping } from 'entities/catalogs/catalogDeduplication/catalogDeduplication.store';
import {
	CreateDeduplicationAttributeMappingRequest,
	useCreateDeduplicationAttributeMappingMutation,
} from 'shared/api/generatedApi/deduplicationApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useCreateDeduplicationAttributeMapping = () =>
	{
		const [fetchCreateMapping, { isLoading: isCreatingMapping }] =
			useCreateDeduplicationAttributeMappingMutation();

		const { notification } = App.useApp();

		const dispatch = useAppDispatch();

		const createMapping = (
			createDeduplicationAttributeMappingRequest: CreateDeduplicationAttributeMappingRequest
		) => {
			fetchCreateMapping({ createDeduplicationAttributeMappingRequest })
				.unwrap()
				.then((res) => {
					dispatch(createAttributeMapping(res));
					notification.success({ message: 'Маппинг создан' });
				})
				.catch((err) =>
					errorHelper('Ошибка при создании маппинга атрибутов', err, notification)
				);
		};

		return { isCreatingMapping, createMapping };
	};
