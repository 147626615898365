import { App, TreeDataNode } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
	ClassifierItemDto,
	ClassifierItemTreeNode,
	SliceClassifierItemDto,
	useLazyGetClassifierItemsQuery,
	useLazyGetClassifierItemTreeQuery,
} from 'shared/api/generatedApi/newUdmiApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import {
	removeCurrentClassifierItem,
	setCurrentClassifierItem,
	setСlassifierItems,
	updateClassifierChildTreeItem,
} from './classifierItems.store';

export type ClassifierItem = ClassifierItemTreeNode &
	TreeDataNode & {
		key: React.Key;
		title: string;
		children?: Array<ClassifierItem>;
	};

export type DisplayTreeNode = Omit<ClassifierItem, 'title'> & { title: React.ReactNode };

export const useClassifierItems = () => {
	const [selectedClassifier, setSelectedClassifier] = useState<ClassifierItemDto>(null);
	const [classifiersList, setClassifiersList] = useState<Array<ClassifierItemDto>>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const [getItemsList, { error: itemsListError }] = useLazyGetClassifierItemsQuery();
	const [getClassifierWithParentsById, { error: classifierWithParentsError }] =
		useLazyGetClassifierItemTreeQuery();

	const { notification } = App.useApp();

	const { classifierItemId, classifierGroupId } = useParams();

	const dispatch = useAppDispatch();
	const { classifierItemsList, currentClassifierItem } = useAppSelector(
		(state) => state.entities.classifiers.classifierItems
	);

	const addNewItemToList = (
		arr: Array<ClassifierItemDto>,
		group: ClassifierItemDto
	): Array<ClassifierItemDto> =>
		arr.map((item) => {
			return item.id === group.id ? group : item;
		});

	const findChild = (
		arr: Array<ClassifierItemTreeNode>,
		id: string
	): ClassifierItemTreeNode | undefined => {
		if (arr) {
			for (const attr of arr) {
				if (attr.id === id) {
					return attr;
				}
				if (attr.children && attr.children.length) {
					const child = findChild(attr.children, id);
					if (child) {
						return child;
					}
				}
			}
		}

		return undefined;
	};

	const getClassifiers = async (
		page?,
		limit?,
		parentItemId?: string
	): Promise<SliceClassifierItemDto | null> => {
		const data = await getItemsList({
			...(parentItemId
				? { parentItemId, page, limit }
				: { root: true, classifierId: classifierGroupId }),
		});
		if ('error' in data) {
			errorHelper(
				'Ошибка при получении корневой группы справочников!',
				data.error,
				notification
			);
			return null;
		}

		if ('data' in data) {
			if (parentItemId) {
				for (const item of data?.data.data as unknown as Array<ClassifierItemDto>) {
					dispatch(updateClassifierChildTreeItem({ item, parentItemId }));
				}
			} else {
				return data?.data;
			}
		}
	};

	const getGroups = async () => {
		setLoading(true);
		const list = await getItemsList({ root: true, classifierId: classifierGroupId });
		if ('error' in list) {
			errorHelper(
				'Ошибка при получении корневой группы справочников!',
				list.error,
				notification
			);
		}

		if ('data' in list) {
			if (list.data.data.length) {
				if (classifierItemId) {
					const data = await getClassifierWithParentsById({
						id: classifierItemId,
						direction: 'up',
						includeChildren: true,
					});
					if ('error' in data) {
						errorHelper('Ошибка при получении справочников!', data.error, notification);
					}
					if ('data' in data) {
						const completeData = addNewItemToList(list.data.data, data.data);
						dispatch(setСlassifierItems(completeData));
						dispatch(
							setCurrentClassifierItem(findChild([data.data], classifierGroupId))
						);
						setLoading(false);
						return;
					}
				}
				dispatch(setСlassifierItems(list.data.data));
				dispatch(setCurrentClassifierItem(findChild(list.data.data, classifierItemId)));
			}
		}
		dispatch(setСlassifierItems(list.data.data));
		setLoading(false);
	};

	useEffect(() => {
		if (!classifierItemsList) {
			getGroups();
		}
	}, []);

	useEffect(() => {
		if (classifierItemsList) setClassifiersList(classifierItemsList);
	}, [classifierItemsList]);

	useEffect(() => {
		setSelectedClassifier(currentClassifierItem);
	}, [currentClassifierItem]);

	useEffect(() => {
		if (!classifierItemId && currentClassifierItem) dispatch(removeCurrentClassifierItem());
		if (classifierItemId)
			dispatch(setCurrentClassifierItem(findChild(classifierItemsList, classifierItemId)));
	}, [classifierItemId]);

	return {
		selectedClassifier,
		classifiersList,
		getClassifiers,
		error: itemsListError,
		classifierWithParentsError,
		loading,
		setLoading,
		findChild,
	};
};
